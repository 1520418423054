import React from 'react'
import RebuildValueMeterForm from './RebuildValueMeterForm'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'

const RebuildValueMeterScreen = () => (
  <ScreenWrapper>
    <RebuildValueMeterForm />
  </ScreenWrapper>
)

export default RebuildValueMeterScreen
