import { useAppDispatch } from './state'
import {
  getNotifications as getNotificationsAPI,
  deleteNotification as deleteNotificationAPI,
  putNotification
} from '../api/notifications'
import {
  setNotifications,
  deleteNotification as deleteNotificationRedux,
  updateNotification
} from '../state/slices/notifications_slice'
import * as Sentry from '@sentry/react'

export const useNotification = () => {
  const dispatch = useAppDispatch()

  const getNotifications = async (): Promise<boolean> => {
    const result = await getNotificationsAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }

    if (result.data) {
      dispatch(setNotifications(result.data))
    }

    return result.success
  }

  const deleteNotification = async (id: number) => {
    const result = await deleteNotificationAPI(id)

    if (result.success) {
      dispatch(deleteNotificationRedux(id))
    }

    return result.success
  }

  const readNotification = async (id: number) => {
    const result = await putNotification(id)

    if (result.success) {
      dispatch(updateNotification(result.data))
    }

    return result.success
  }

  return {
    getNotifications,
    deleteNotification,
    readNotification
  }
}

export default useNotification
