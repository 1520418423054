import { Images } from '@verzekeringapp-mono/shared/src/assets'
import SETTINGS from '@verzekeringapp-mono/shared/src/constants/settings'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import useSession from '@verzekeringapp-mono/shared/src/hooks/use_session'
import { getShowFirstUse } from '@verzekeringapp-mono/shared/src/state/selectors/user'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../../../components/Button'
import TextInput from '../../../../components/TextInput'
import { useEmergencyModalContext } from '../../../../providers/EmergencyModalProvider'

interface FormData {
  email: string
  password: string
}

type LoginTypes = 'LOGIN' | 'REGISTER' | 'ACTIVATED'

const getDescription = (type: LoginTypes, showFirstUse: boolean) => {
  if (showFirstUse) {
    return 'In Mijn Polissen kunt u snel schademelden en heeft u toegang tot uw dossier, verzekeringsbewijs, polissen en meer. Begin hier met registreren.'
  }
  switch (type) {
  case 'LOGIN':
    return 'In Mijn Polissen kunt u snel schademelden en heeft u toegang tot uw dossier, verzekeringsbewijs, polissen en meer. Begin hier met inloggen.'
  case 'REGISTER':
    return 'In Mijn Polissen kunt u snel schademelden en heeft u toegang tot uw dossier, verzekeringsbewijs, polissen en meer. Begin hier met registreren.'
  case 'ACTIVATED':
    return 'We hebben uw account gevonden. Log in met uw eerder gekozen wachtwoord.'
  }
}

interface Props {
  setCheckEmailStatus: (status: string) => void
  type?: LoginTypes
}
const Login = ({ setCheckEmailStatus, type }: Props) => {
  const { open } = useEmergencyModalContext()
  const { state } = useLocation()
  const locationStateType = state?.type
  const {
    handleSubmit,
    formState: { isValid },
    control
  } = useFormContext<FormData>()
  const { checkEmailAddress, login } = useSession()
  const showFirstUse = useAppSelector(getShowFirstUse)
  const navigate = useNavigate()
  const [loginError, setLoginError] = React.useState<string | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = async (data: { email: string; password: string }) => {
    const { email, password } = data

    setIsLoading(true)
    if (!showFirstUse && type === 'LOGIN') {
      const data = await login(email, password)

      setIsLoading(false)
      const { success, attemptLeft, message } = data

      if (success) {
        setLoginError(null)
        navigate('/overzicht')
      } else {
        if (!attemptLeft) {
          if (message) {
            setLoginError(message)
          }
        } else {
          setLoginError(
            `${message} \n U heeft nog ${attemptLeft ?? 0} pogingen over`
          )
        }
      }
    } else {
      const emailStatus = await checkEmailAddress(email)

      setIsLoading(false)

      setCheckEmailStatus(emailStatus)
    }
  }

  return (
    <>
      <img
        src={Images.Logo}
        width={180}
        height={70}
      />

      <p className='title mt-8'>{'Welkom'}</p>

      <p className='description my-8'>
        {getDescription(locationStateType ?? type, showFirstUse)}
      </p>

      {loginError && (
        <div className='my-4 bg-darkRed p-4 rounded '>
          <span className='text-white'>{loginError}</span>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              label='E-mailadres'
              placeholder='Uw e-mailadres'
              showValidationIcon={false}
              isValid={!fieldState.invalid}
              icon={'envelope'}
            />
          )}
          name='email'
          control={control}
          rules={{
            required: 'Dit veld is verplicht',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Dit is geen geldig e-mailadres'
            }
          }}
        />

        {!showFirstUse && type === 'LOGIN'
          ? (
            <React.Fragment>
              <Controller
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label='Wachtwoord'
                    placeholder='Uw wachtwoord'
                    type='password'
                    isValid={!fieldState.invalid}
                    showValidationIcon={false}
                    icon={'key'}
                  />
                )}
                name='password'
                control={control}
                rules={{
                  required: 'Dit veld is verplicht'
                }}
              />
            </React.Fragment>
          )
          : null}

        <Button
          className={`primary-btn w-full ${isValid ? '' : 'opacity-50'} my-5`}
          type='submit'
          isLoading={isLoading}
          disabled={!isValid}
        >
          {!showFirstUse && type === 'LOGIN' ? 'Inloggen' : 'Verder'}
        </Button>
      </form>

      {!showFirstUse && type === 'LOGIN'
        ? (
          <div className='flex flex-col'>
            <Link
              to='/register'
              className='light mt-3'
            >
            Registreren?
            </Link>

            <Link
              to='/forget-password'
              className='light mt-3'
            >
            Wachtwoord vergeten?
            </Link>
          </div>
        )
        : null}

      {type === 'REGISTER' && (
        <Button
          className='w-full my-2'
          onClick={() => navigate('/home')}
          variant='outline'
          type='button'
        >
          Terug
        </Button>
      )}

      <p className='mt-3'>
        In geval van nood, neem contact op via het noodnummer: <br />

        <a
          onClick={open}
          className='text-darkRed'
        >
          {SETTINGS.emergencyPhoneNumber}
        </a>
      </p>
    </>
  )
}

export default Login
