import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'

const CombiPolicyDetailScreen = () => {
  const relation = useAppSelector(getCurrentRelation)

  return (
    <ScreenWrapper>
      <div className='bg-white p-8 rounded-lg '>
        <div className='flex justify-between items-center mb-8 flex-wrap'>
          <div className='flex-col flex-1'>
            <p className='paragraph-light'>Betaaltermijn:</p>

            <p>{relation?.paymentPeriod}</p>
          </div>

          <div className='flex-col flex-1'>
            <p>Combinummer:</p>

            <p>{relation?.combiNumber}</p>
          </div>

          <div className='flex-col flex-1'>
            <p>Ingangsdatum:</p>

            <p>{relation?.combiStartingDate}</p>
          </div>

          {relation?.combiPackageDiscount && (
            <div className='flex-col flex-1'>
              <p>Combikorting:</p>

              {Intl.NumberFormat('nl-NL', {
                style: 'percent'
              }).format(Number(relation.combiPackageDiscount) / 100)}
            </div>
          )}

          <div className='flex-col flex-1'>
            <p>Status:</p>

            <p>{relation?.combiStatus}</p>
          </div>

          {relation?.combiCollectionAmount && (
            <div className='flex-col flex-1'>
              <p>Totaal bedrag:</p>

              <p>
                {Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(Number(relation.combiCollectionAmount))}
              </p>
            </div>
          )}
        </div>

        <a
          href={relation?.combiPolicyCondition.url}
          target='_blank'
          className='text-brand '
          rel='noreferrer'
        >
          Algemene voorwaarden
        </a>
      </div>
    </ScreenWrapper>
  )
}

export default CombiPolicyDetailScreen
