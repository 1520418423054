import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IClaim } from '../../models/claim'
import { logout, selectRelation } from '../actions/constants'

export interface IClaimsState {
  claims?: IClaim[]
  conceptClaim: IClaim | null
  claimDamageForm: {
    policyId: string
    date: string
    description: string | null
    phoneNumber: string | null
    attachments?: {
      url: string
      title: string
      extension: string
      size: number
    }[]
  }
  currentStep: number
  isFormCompleted: boolean
  latestClaimId?: string
  conceptIsLoading: boolean
  claimIsLoading: boolean
  fileIsLoading: boolean
}

const initialState: IClaimsState = {
  claims: [],
  conceptClaim: null,
  claimDamageForm: {
    policyId: '',
    date: '',
    description: '',
    phoneNumber: '',
    attachments: undefined
  },
  currentStep: 1,
  isFormCompleted: false,
  latestClaimId: undefined,
  conceptIsLoading: false,
  claimIsLoading: false,
  fileIsLoading: false
}

export const claimsSlice = createSlice({
  name: 'claims',
  initialState,
  reducers: {
    setClaims: (state, action: PayloadAction<IClaim[]>) => {
      state.claims = action.payload || []
    },
    deleteClaim: (state, action: PayloadAction<string>) => {
      state.claims = state.claims?.filter(
        claim => claim.temporaryClaimReference !== action.payload
      )
    },
    updateClaim: (state, action: PayloadAction<IClaim>) => {
      if (state.claims) {
        const stateClaimIndex = state.claims.findIndex(
          claim =>
            claim.temporaryClaimReference ===
            action.payload.temporaryClaimReference
        )

        if (stateClaimIndex !== -1) {
          state.claims[stateClaimIndex] = action.payload
        } else {
          state.claims.push(action.payload)
        }
      }
    },
    setClaimDamageForm: (
      state,
      action: PayloadAction<IClaimsState['claimDamageForm']>
    ) => {
      state.claimDamageForm = action.payload
    },
    setConceptClaim: (state, action: PayloadAction<IClaim | null>) => {
      state.conceptClaim = action.payload
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload
    },
    setConceptIsLoading: (state, action: PayloadAction<boolean>) => {
      state.conceptIsLoading = action.payload
    },
    setClaimIsLoading: (state, action: PayloadAction<boolean>) => {
      state.claimIsLoading = action.payload
    },
    setFileIsLoading: (state, action: PayloadAction<boolean>) => {
      state.fileIsLoading = action.payload
    },
    setIsFormCompleted: (state, action: PayloadAction<boolean>) => {
      state.isFormCompleted = action.payload
    },
    resetClaimDamageForm: state => {
      state.claimDamageForm = {
        policyId: '',
        date: '',
        description: '',
        phoneNumber: '',
        attachments: undefined
      }
    },
    setLatestClaimId: (state, action: PayloadAction<string>) => {
      state.latestClaimId = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, () => initialState)
  }
})

export const {
  setClaims,
  deleteClaim,
  updateClaim,
  setClaimDamageForm,
  setConceptClaim,
  setCurrentStep,
  setIsFormCompleted,
  resetClaimDamageForm,
  setConceptIsLoading,
  setClaimIsLoading,
  setFileIsLoading,
  setLatestClaimId
} = claimsSlice.actions

export default claimsSlice.reducer
