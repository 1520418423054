import { CompanyCategory } from '../state/slices/companies_slice'
import Api, { BaseUrl } from './index'
import { IRepairer } from './types/company'

export const getClaimCompanyData = () =>
  Api<{
    categories: CompanyCategory[]
    radius: number[]
  }>('GET', 'claim-company-data', {}, BaseUrl.Claim)

export const searchClaimCompanies = (params: {
  q: string
  radius: number
  category_id: number
  brand_id: number | null
}) =>
  Api<{
    claimCompanies: IRepairer[]
    citySelect: any[]
  }>('POST', 'claim-company-search', params, BaseUrl.Claim)
