import React from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getProducts as getProductsSelector } from '@verzekeringapp-mono/shared/src/state/selectors/products'
import useProducts from '@verzekeringapp-mono/shared/src/hooks/use_product'
import { IProduct } from '@verzekeringapp-mono/shared/src/models/product'
import usePolicies from '@verzekeringapp-mono/shared/src/hooks/use_policies'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import Button from '../../components/Button'
import { useThemeColorContext } from '../../providers/ThemeColorProvider'

const PolicyAdvice = () => {
  const products = useAppSelector(getProductsSelector)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(false)
  const { brandColors } = useThemeColorContext()
  const { getProducts } = useProducts()
  const { requestPolicyAdvise } = usePolicies()
  const currentRelation = useAppSelector(getCurrentRelation)
  const { register, watch, setValue, handleSubmit } = useForm<{
    description: string
    selectedPolicies: string[]
  }>({
    defaultValues: {
      description: '',
      selectedPolicies: ['']
    }
  })
  const description = watch('description')
  const selectedPolicies = watch('selectedPolicies')
  const needExtraRow = selectedPolicies.length
  const showAddButton = needExtraRow && selectedPolicies.length < 10

  const setPolicyForIndex = (index: number, product: string) => {
    const newSelectedPolicies = [...selectedPolicies]

    newSelectedPolicies[index] = product

    setValue('selectedPolicies', newSelectedPolicies)
  }

  const deletePolicyAddIndex = (index: number) => {
    const newSelectedPolicies = selectedPolicies.filter((_, i) => i !== index)

    setValue('selectedPolicies', newSelectedPolicies)
  }

  const addPolicy = () => {
    const newSelectedPolicies = [...selectedPolicies, '']

    setValue('selectedPolicies', newSelectedPolicies)
  }

  React.useEffect(() => {
    getProducts()
  }, [])

  const submitAdviceRequest = async () => {
    setIsLoading(true)
    const result = await requestPolicyAdvise(
      currentRelation?.id ?? '',
      currentRelation?.agencyId ?? '',
      description,
      selectedPolicies
    ).finally(() => setIsLoading(false))

    if (result) {
      navigate('/verzekeringen/advies-aangevraagd')
    }
  }

  return (
    <ScreenWrapper hasPadding={false}>
      <form onSubmit={handleSubmit(submitAdviceRequest)}>
        <div className='flex flex-col items-center my-8 px-8 lg:w-2/3 w-full lg:mx-auto'>
          <p className='title mb-4'>Ik wil graag verzekeringsadvies</p>

          <p className='mb-8'>
            Graag ontvang ik advies over het afsluiten van aanvullende
            verzekeringen en/of dekkingen.
          </p>

          <ProductsSelector
            products={products}
            selectedPolicies={selectedPolicies}
            setPolicyForIndex={setPolicyForIndex}
            deletePolicyAddIndex={deletePolicyAddIndex}
          />

          {showAddButton && (
            <button
              className='mr-auto text-brand'
              onClick={addPolicy}
              type='button'
            >
              <FontAwesomeIcon
                icon='plus'
                className='mr-2 cursor-pointer'
                size='sm'
                color={brandColors.brand}
              />
              Verzekering toevoegen
            </button>
          )}

          <div className='flex flex-row justify-between w-full'>
            <p className='mr-auto mt-8 mb-2'>Mijn adviesvraag:</p>

            <p
              className={`text-right mt-8 mb-2 ${
                description.length >= 2000 ? 'text-red' : ''
              }`}
            >
              {2000 - description.length}
            </p>
          </div>

          <textarea
            className='w-full h-[200px] border border-lightGray rounded-md p-4'
            placeholder='Vul hier zo veel mogelijke relevante informatie in...'
            {...register('description')}
            maxLength={2000}
          />

          <Button
            className={'primary-btn w-full mt-8'}
            disabled={
              description.length === 0 ||
              selectedPolicies.length === 0 ||
              selectedPolicies.includes('')
            }
            isLoading={isLoading}
          >
            Geef mij advies
          </Button>
        </div>
      </form>
    </ScreenWrapper>
  )
}

export default PolicyAdvice

const ProductsSelector: React.FC<{
  products: IProduct[]
  selectedPolicies: (string | undefined)[]
  setPolicyForIndex: (index: number, product: string) => void
  deletePolicyAddIndex: (index: number) => void
}> = ({
  products,
  selectedPolicies,
  setPolicyForIndex,
  deletePolicyAddIndex
}) => {
  const { brandColors } = useThemeColorContext()

  return (
    <>
      {selectedPolicies.map((selectedPolicy, index) => (
        <div
          className='mb-6 flex flex-row w-full flex-1 relative justify-center items-center'
          key={`policy-selector-${index}`}
        >
          <select
            className={
              'flex border rounded-md shadow-sm flex-1 focus:ring-blue-500 focus:border-blue-500 p-4 border-lightGray'
            }
            value={selectedPolicy}
            onChange={e => setPolicyForIndex(index, e.target.value)}
          >
            <option
              value=''
              disabled
              selected
              className='text-gray'
            >
              Selecteer een verzekering
            </option>

            {products.map(product => (
              <option
                key={`product-id-${product.id}`}
                value={product.name}
              >
                {product.name}
              </option>
            ))}
          </select>

          {index !== 0 && (
            <button
              onClick={() => deletePolicyAddIndex(index)}
              className='flex items-center justify-center w-[32px] cursor-pointer absolute -right-8'
            >
              <FontAwesomeIcon
                icon='trash'
                color={brandColors.brand}
              />
            </button>
          )}
        </div>
      ))}
    </>
  )
}
