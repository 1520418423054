import React from 'react'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getAgency } from '@verzekeringapp-mono/shared/src/state/selectors/agency'
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect
} from 'react'

const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const ThemeColorProvider: FC<PropsWithChildren> = ({ children }) => {
  const agency = useAppSelector(getAgency)

  useEffect(() => {
    if (agency) {
      document.documentElement.style.setProperty(
        '--primary-color',
        agency.primaryColor
      )

      document.documentElement.style.setProperty(
        '--primary-color-light',
        hexToRgba(agency.primaryColor, 0.03)
      )
    } else {
      document.documentElement.style.setProperty('--primary-color', '#E95E40')
      document.documentElement.style.setProperty(
        '--primary-color-light',
        'rgba(220, 81, 52, 0.03);'
      )
    }
  }, [agency])

  return (
    <ThemeColorContext.Provider
      value={{
        brandColors: {
          brand: agency?.primaryColor || '#E95E40',
          brandLight: agency?.primaryColor
            ? hexToRgba(agency.primaryColor, 0.03)
            : 'rgba(220, 81, 52, 0.03);'
        }
      }}
    >
      {children}
    </ThemeColorContext.Provider>
  )
}

const ThemeColorContext = createContext<{
  brandColors: {
    brand: string
    brandLight: string
  }
}>({
  brandColors: {
    brand: '#E95E40',
    brandLight: 'rgba(220, 81, 52, 0.03);'
  }
})

export const useThemeColorContext = () => useContext(ThemeColorContext)

export default ThemeColorProvider
