import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ITip, ITipCategory } from '../../models/tips'
import { ITipResponse } from '../../api/types/tips'
import { logout } from '../actions/constants'

export interface ITipsState {
  categories: ITipCategory[]
  tips: ITip[]
}

const initialState: ITipsState = {
  categories: [],
  tips: []
}

export const tipsSlice = createSlice({
  name: 'tips',
  initialState,
  reducers: {
    setTips: (state, action: PayloadAction<ITipResponse>) => {
      state.categories = action.payload.categories
      state.tips = action.payload.tips
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState)
  }
})

export const {
  setTips
} = tipsSlice.actions

export default tipsSlice.reducer
