import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IPolicy } from '@verzekeringapp-mono/shared/src/models/policy'
import { determinePolicyDistinctionLabel } from '@verzekeringapp-mono/shared/src/utils/policy_utils'
import React from 'react'
import { Link } from 'react-router-dom'
import { useThemeColorContext } from '../providers/ThemeColorProvider'

const ValueMeterLink = ({ policy }: { policy: IPolicy }) => {
  const { brandColors } = useThemeColorContext()

  return (
    <Link
      key={policy.id}
      className='bg-white p-6 rounded-md text-center flex flex-col break-words'
      to={`/dossier/waardemeters/${
        policy.productName === 'Woonhuis'
          ? 'herbouwwaardemeter'
          : 'inboedelwaardemeter'
      }/${policy.id}`}
    >
      <div className='mb-4 rounded-full bg-lightBrand p-4 flex items-center justify-center w-[50px] h-[50px] mx-auto'>
        {policy?.productName === 'Inboedel'
          ? (
            <FontAwesomeIcon
              icon={['fas', 'couch']}
              color={brandColors.brand}
            />
          )
          : (
            <FontAwesomeIcon
              icon={['fas', 'home']}
              color={brandColors.brand}
            />
          )}
      </div>

      <h3 className='mb-2'>
        {policy.productName === 'Woonhuis'
          ? 'Herbouwwaardemeter'
          : 'Inboedelwaardemeter'}

        <br />
      </h3>

      <p className='text-sm'>
        (
        {determinePolicyDistinctionLabel({
          details: policy.details,
          productId: policy.productId,
          policyNumber: policy.policyNumber
        })}
        )
      </p>
    </Link>
  )
}

export default ValueMeterLink
