import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getWhatToDoItemByCategoryId } from '@verzekeringapp-mono/shared/src/state/selectors/support'
import React, { FC } from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { Link, useParams } from 'react-router-dom'
import SETTINGS from '@verzekeringapp-mono/shared/src/constants/settings'
import parse, { DOMNode } from 'html-react-parser'
import { useEmergencyModalContext } from '../../providers/EmergencyModalProvider'

const ButtonEmergency = () => {
  const { open } = useEmergencyModalContext()

  return (
    <a
      onClick={open}
      className='text-darkRed'
    >
      {SETTINGS.emergencyPhoneNumber}
    </a>
  )
}

const ButtonLink: FC<{
  domNode: DOMNode
}> = ({ domNode }) => {
  if (!('attribs' in domNode)) return null

  return (
    <Link
      to={domNode.attribs['data-web-link']}
      className='primary-btn'
    >
      {domNode.attribs['data-title']}
    </Link>
  )
}

const renderContent = (content: string) =>
  parse(content, {
    replace: domNode => {
      if ('name' in domNode && domNode.name === 'button-emergency') {
        return <ButtonEmergency />
      }
      if ('name' in domNode && domNode.name === 'button-link') {
        return <ButtonLink domNode={domNode} />
      }
    }
  })

const WhatToDoScreen: React.FC = () => {
  const { id } = useParams()

  const whatToDo = useAppSelector(getWhatToDoItemByCategoryId(id ?? ''))

  return (
    <ScreenWrapper>
      {whatToDo
        ? (
          <div>
            <div>{renderContent(whatToDo.content)}</div>
          </div>
        )
        : (
          <p>Not found</p>
        )}
    </ScreenWrapper>
  )
}

export default WhatToDoScreen
