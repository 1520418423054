import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { productIcons } from '@verzekeringapp-mono/shared/src/utils/product_icons'
import {
  convertDetailsToAttributes,
  headerAttributes as getHeaderAttributes
} from '@verzekeringapp-mono/shared/src/utils/policy_utils'
import { IPolicy } from '@verzekeringapp-mono/shared/src/models/policy'
import { useThemeColorContext } from '../../../../providers/ThemeColorProvider'

interface IProps {
  policy: IPolicy
}

const PolicyDetailHeader: React.FC<IProps> = ({ policy }) => {
  const headerAttributes = convertDetailsToAttributes(
    getHeaderAttributes(policy)
  )

  const { brandColors } = useThemeColorContext()

  return (
    <div className='flex flex-col gap-8 bg-white rounded'>
      <div className='flex flex-col gap-4'>
        <div className='justify-between flex flex-row'>
          <div className='flex flex-row'>
            <div className='w-[50px] h-[50px] bg-lightBrand rounded-[25px] flex justify-center items-center mr-4'>
              <FontAwesomeIcon
                icon={productIcons[policy.productId]}
                size='lg'
                color={brandColors.brand}
              />
            </div>

            <div>
              <p className='text-brand subtitle'>{policy.productName}</p>

              <p className='text-textGray text-sm'>{`Polisnummer: ${policy.policyNumber}`}</p>
            </div>
          </div>

          {policy.premium && (
            <div className='flex flex-col justify-end'>
              <p className='text-brand text-right text-lg font-semibold'>
                {Intl.NumberFormat('nl-NL', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(Number(policy.premium))}
              </p>

              <span className='text-sm text-textGray'> per maand</span>
            </div>
          )}
        </div>
      </div>

      <div className='grid md:grid-cols-4 gap-4 grid-cols-2'>
        {headerAttributes.map((attribute, index) => (
          <div key={index}>
            <p className='text-sm text-textGray'>{attribute.title}</p>

            <p className='text-base text-gray'>{attribute.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PolicyDetailHeader
