import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  useAppSelector,
  useAppDispatch
} from '@verzekeringapp-mono/shared/src/hooks/state'
import { getConceptClaim } from '@verzekeringapp-mono/shared/src/state/selectors/claims'
import {
  setConceptClaim,
  setCurrentStep,
  resetClaimDamageForm
} from '@verzekeringapp-mono/shared/src/state/slices/claims_slice'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { setSelectedPolicyNumber } from '@verzekeringapp-mono/shared/src/state/slices/policies_slice'
import { useThemeColorContext } from '../providers/ThemeColorProvider'

const ClaimDamageButton = ({ policyNumber }: { policyNumber?: string }) => {
  const conceptClaim = useAppSelector(getConceptClaim)
  const { brandColors } = useThemeColorContext()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const onClaimDamageClick = useCallback(() => {
    if (conceptClaim) {
      const confirm = window.confirm(
        'De vorige schademelding is niet afgerond, wilt u hiermee verdergaan?'
      )

      if (confirm) {
        navigate('/schade-melden/schadeformulier')
      } else {
        dispatch(setConceptClaim(null))
        dispatch(setCurrentStep(1))
        dispatch(resetClaimDamageForm())

        navigate('/schade-melden')
      }
    } else {
      dispatch(setConceptClaim(null))
      dispatch(setCurrentStep(1))
      if (policyNumber) {
        dispatch(setSelectedPolicyNumber(policyNumber))
        navigate('/schade-melden/schadeformulier')
      } else {
        dispatch(resetClaimDamageForm())

        navigate('/schade-melden')
      }
    }
  }, [conceptClaim])

  return (
    <button
      onClick={onClaimDamageClick}
      className='flex primary-btn items-center justify-center md:mt-8 md:mb-12 px-4 py-2 w-full'
    >
      <div className='bg-white flex items-center justify-center w-[35px] h-[35px] rounded-full mr-4'>
        <FontAwesomeIcon
          icon='fragile'
          size='lg'
          color={brandColors.brand}
        />
      </div>

      <p>Schade melden</p>
    </button>
  )
}

export default ClaimDamageButton
