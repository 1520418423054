import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { INotification } from '../../models/notification'
import { logout, selectRelation } from '../actions/constants'

export interface ITermsState {
    notifications: INotification[]
}

const initialState: ITermsState = {
  notifications: []
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<INotification[]>) => {
      state.notifications = action.payload
    },
    deleteNotification: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(notification => notification.messageId !== action.payload)
    },
    updateNotification: (state, action: PayloadAction<INotification>) => {
      state.notifications = state.notifications.map(notification => {
        if (notification.messageId === action.payload.messageId) {
          return action.payload
        }

        return notification
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, () => initialState)
  }
})

export const {
  setNotifications,
  deleteNotification,
  updateNotification
} = notificationsSlice.actions

export default notificationsSlice.reducer
