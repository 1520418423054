import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import { useThemeColorContext } from '../../../providers/ThemeColorProvider'

interface ContentSectionProps {
  title: string
  subtitle?: string
  icon: IconProp
  children: ReactNode
  className?: string
  headerRight?: ReactNode
}

const ContentSection: React.FC<ContentSectionProps> = ({
  title,
  subtitle,
  icon,
  className,
  children,
  headerRight
}) => {
  const { brandColors } = useThemeColorContext()

  return (
    <div className={` bg-white p-6 rounded-lg  ${className}`}>
      <div className='flex flex-row items-center pb-8 justify-between'>
        <div className='flex flex-row items-center'>
          <div className='w-[50px] h-[50px] bg-lightBrand rounded-[25px] flex justify-center items-center mr-4'>
            <FontAwesomeIcon
              icon={icon}
              size='lg'
              color={brandColors.brand}
            />
          </div>

          <div>
            <p className='subtitle'>{title}</p>

            <p className='text-textGray'>{subtitle}</p>
          </div>
        </div>

        {headerRight}
      </div>

      {children}
    </div>
  )
}

export default ContentSection
