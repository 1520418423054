import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IPolicy } from '../../models/policy'
import { logout, selectRelation } from '../actions/constants'

export interface IPoliciesState {
  policies: IPolicy[]
  selectedPolicyId?: string
  conceptPolicy?: IPolicy
  isLoadingValueMeter: boolean
  isLoadingInsuranceCard: boolean
}

const initialState: IPoliciesState = {
  policies: [],
  selectedPolicyId: undefined,
  conceptPolicy: undefined,
  isLoadingValueMeter: false,
  isLoadingInsuranceCard: false
}

export const policiesSlice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    setPolicies: (state, action: PayloadAction<IPolicy[]>) => {
      state.policies = action.payload
    },
    updatePolicy: (state, action: PayloadAction<IPolicy>) => {
      state.policies = state.policies.map(policy => {
        if (policy.id === action.payload.id) {
          return action.payload
        }

        return policy
      })
    },
    setValueMeterIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingValueMeter = action.payload
    },
    setIsLoadingInsuranceCard: (state, action: PayloadAction<boolean>) => {
      state.isLoadingInsuranceCard = action.payload
    },
    setSelectedPolicyNumber: (state, action: PayloadAction<string>) => {
      state.selectedPolicyId = action.payload
    },
    setConceptPolicy: (state, action: PayloadAction<IPolicy>) => {
      state.conceptPolicy = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, () => initialState)
  }
})

export const {
  setPolicies,
  updatePolicy,
  setSelectedPolicyNumber,
  setConceptPolicy,
  setValueMeterIsLoading,
  setIsLoadingInsuranceCard
} = policiesSlice.actions

export default policiesSlice.reducer
