export interface IWhatToDoItem {
  category: WhatToDoCategory
  sortNumber: number
  subject: string
  content: string
  publish: boolean
}

export enum WhatToDoCategory {
  Damage = 'damage',
  Diseased = 'diseased',
  Legal = 'legal',
  Loss = 'loss',
  DamageRepair = 'damage-repair'
}

export const getWhatToDoItemIconName = (wtdItem: IWhatToDoItem) => {
  switch (wtdItem.category) {
  case WhatToDoCategory.Damage:
    return 'fragile'
  case WhatToDoCategory.DamageRepair:
    return 'mask'
  case WhatToDoCategory.Diseased:
    return 'cross'
  case WhatToDoCategory.Loss:
    return 'ghost'
  case WhatToDoCategory.Legal:
    return 'legal'
  }
}
