import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IQuestion, ISupportQuestionCategory } from '../../models/question'
import { IQuestionResponse } from '../../api/types/question'
import { logout } from '../actions/constants'

export interface IQuestionsState {
  categories: ISupportQuestionCategory[]
  questions: IQuestion[]
}

const initialState: IQuestionsState = {
  categories: [],
  questions: []
}

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestions: (state, action: PayloadAction<IQuestionResponse>) => {
      state.categories = action.payload.categories
      state.questions = action.payload.questions
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState)
  }
})

export const {
  setQuestions
} = questionsSlice.actions

export default questionsSlice.reducer
