import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../configure_store'
import he from 'he'

const _getState = (state: RootState) => state.questions

export const getQuestions = createSelector(
  _getState,
  (state) => (
    state.questions.map((question) => ({
      ...question,
      text: he.decode(question.text),
      answer: he.decode(question.answer)
    }))
  )
)
