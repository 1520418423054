import React from 'react'
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState
} from 'react'
import Button from '../components/Button'
import Modal from '@verzekeringapp-mono/shared/src/components/Modal'
import SETTINGS from '@verzekeringapp-mono/shared/src/constants/settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'

const EmergencyModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <EmergencyModalContext.Provider value={{ open, close }}>
      {children}

      {isOpen && (
        <Modal
          modalIcon={
            <div className='bg-darkRed  absolute border-4 -top-[40px] border-white rounded-full p-4 h-[80px] w-[80px] flex items-center justify-center'>
              <FontAwesomeIcon
                icon='phone-plus'
                size='xl'
                color={Colors.white}
              />
            </div>
          }
          onClose={close}
        >
          <div className='relative flex-col flex justify-center items-center text-center gap-4'>
            <h2 className='text-xl text-gray font-medium mt-8'>
              Noodnummer bellen
            </h2>

            <p>
              Weet u zeker dat u het noodnummer wilt bellen? U wordt dan direct
              in contact gebracht met de alarmcentrale van Nh1816.
            </p>

            <a
              className='bg-darkRed text-white rounded-md px-6 py-4 mt-4'
              href={`tel:${SETTINGS.emergencyPhoneNumber}`}
            >
              Bel noodnummer
            </a>

            <p>of</p>

            <Button
              variant='outline-brand'
              onClick={close}
            >
              Nee, annuleer
            </Button>
          </div>
        </Modal>
      )}
    </EmergencyModalContext.Provider>
  )
}

const EmergencyModalContext = createContext({
  open: () => {},
  close: () => {}
})

export const useEmergencyModalContext = () => useContext(EmergencyModalContext)

export default EmergencyModalProvider
