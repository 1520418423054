import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { productIcons } from '@verzekeringapp-mono/shared/src/utils/product_icons'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import {
  IPolicy,
  IPolicyDetail
} from '@verzekeringapp-mono/shared/src/models/policy'
import { determinePolicyDistinctionLabel } from '@verzekeringapp-mono/shared/src/utils/policy_utils'
import { Link } from 'react-router-dom'
import { useThemeColorContext } from '../../../../providers/ThemeColorProvider'

interface IProps {
  policy: IPolicy
}

const PolicyRow: React.FC<IProps> = ({ policy }) => {
  const { brandColors } = useThemeColorContext()
  const getDetailsForPolicy = (policy: IPolicy): IPolicyDetail[] =>
    Object.values(policy?.details ?? {}).filter(
      detail => detail?.important && detail?.code !== ''
    )

  return (
    <div className='flex flex-col gap-8 bg-white rounded p-4 md:p-8 justify-center mb-4'>
      <Link to={`/verzekeringen/polis/${policy.id}`}>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-row items-center justify-between flex-1'>
            <div className='flex flex-row items-center gap-4'>
              <div className='w-[50px] h-[50px] bg-lightBrand rounded-[25px] flex justify-center items-center mr-4'>
                <FontAwesomeIcon
                  icon={productIcons[policy.productId]}
                  size='lg'
                  color={brandColors.brand}
                />
              </div>

              <div>
                <p>{policy.productName}</p>

                <p className='md:hidden'>
                  {determinePolicyDistinctionLabel(policy)}
                </p>

                {policy.status === 'Opgeschort'
                  ? (
                    <p className='text-textGray text-sm md:hidden'>Opgeschort</p>
                  )
                  : (
                    <p className='text-textGray text-sm md:hidden'>
                    Premie:{' '}

                      {Intl.NumberFormat('nl-NL', {
                        style: 'currency',
                        currency: 'EUR'
                      }).format(Number(policy.premium))}{' '}
                    per maand
                    </p>
                  )}

                <p className='light hidden md:block'>{`Polisnummer: ${policy.policyNumber}`}</p>
              </div>
            </div>

            <FontAwesomeIcon
              className='md:hidden'
              color={Colors.lightGray}
              icon={['fal', 'chevron-right']}
            />

            <div className=' flex-col justify-end hidden md:flex items-end'>
              {policy.premium && policy.status === 'Lopend' && (
                <p className='text-brand text-xl font-bold'>
                  {Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(Number(policy.premium))}
                </p>
              )}

              <p className='text-textGray'>
                {policy.status === 'Opgeschort' ? 'Opgeschort' : 'per maand'}
              </p>
            </div>
          </div>

          <div className='justify-between flex-row hidden md:flex'>
            <div className='flex flex-row gap-8 flex-1'>
              {getDetailsForPolicy(policy).map(detail => (
                <div
                  key={detail.name}
                  className='flex flex-col'
                >
                  <p className='text-textGray'>{detail.label}</p>

                  <p className='text-gray'>{detail.code}</p>
                </div>
              ))}
            </div>

            <a
              className='text-brand text-sm self-end'
              href={`/verzekeringen/polis/${policy.id}`}
            >
              <FontAwesomeIcon
                className='mr-2'
                color={brandColors.brand}
                icon={['fal', 'arrow-right']}
              />
              Details
            </a>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PolicyRow
