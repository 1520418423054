import useSession from '@verzekeringapp-mono/shared/src/hooks/use_session'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import Button from '../../../../components/Button'
import TextInput from '../../../../components/TextInput'

interface FormData {
  currentPassword: string
  newPassword: string
  repeatNewPassword: string
}

const PasswordForm: React.FC = () => {
  const { updatePassword } = useSession()
  const [isLoading, setIsLoading] = React.useState(false)

  const {
    handleSubmit,
    watch,
    control,
    formState: { isValid }
  } = useForm<FormData>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: ''
    }
  })

  const newPassword = watch('newPassword')
  const repeatNewPassword = watch('repeatNewPassword')
  const [passwordFeedback, setPasswordFeedback] = React.useState<
    string | null
  >()
  const [passwordError, setPasswordError] = React.useState<string | null>()

  return (
    <form
      onSubmit={handleSubmit(data => {
        setIsLoading(true)
        updatePassword(data.currentPassword, data.newPassword)
          .then(res => {
            setPasswordError(null)
            if (res.message) {
              setPasswordFeedback(res.message)
            }
          })
          .catch(e => {
            setPasswordFeedback(null)
            setPasswordError(e.message)
          })
          .finally(() => {
            setIsLoading(false)
          })
      })}
      className='md:w-1/2 w-full'
    >
      <Controller
        render={({ field, fieldState }) => (
          <TextInput
            {...field}
            type='password'
            placeholder='Huidig wachtwoord'
            label='Huidig wachtwoord'
            icon='key'
            isValid={!fieldState.invalid}
          />
        )}
        name='currentPassword'
        control={control}
        rules={{ required: true }}
      />

      <div className='mb-4'>
        <p className='mt-4 mb-2'>
          Bedenk een veilig wachtwoord dat voldoet aan de volgende eisen:
        </p>

        <p className={newPassword.length >= 8 ? 'labelSuccess' : 'label'}>
          • Minimaal 8 karakters
        </p>

        <p className={/[A-Z]/.test(newPassword) ? 'labelSuccess' : 'label'}>
          • Minimaal 1 hoofdletter
        </p>

        <p className={/[a-z]/.test(newPassword) ? 'labelSuccess' : 'label'}>
          • Minimaal 1 kleine letter
        </p>

        <p className={/\d/.test(newPassword) ? 'labelSuccess' : 'label'}>
          • Minimaal 1 cijfer
        </p>

        <p
          className={
            /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(newPassword)
              ? 'labelSuccess'
              : 'label'
          }
        >
          • Minimaal 1 speciaal teken
        </p>

        <p
          className={
            newPassword.length > 0 &&
            repeatNewPassword.length > 0 &&
            newPassword === repeatNewPassword
              ? 'labelSuccess'
              : 'label'
          }
        >
          • Nieuwe wachtwoorden zijn hetzelfde
        </p>
      </div>

      <Controller
        render={({ field, fieldState }) => (
          <TextInput
            {...field}
            type='password'
            placeholder='Nieuw wachtwoord'
            label='Nieuw wachtwoord'
            icon='key'
            isValid={!fieldState.invalid}
          />
        )}
        name='newPassword'
        control={control}
        rules={{
          required: true,
          minLength: 8,
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/
        }}
      />

      <Controller
        render={({ field, fieldState }) => (
          <TextInput
            {...field}
            type='password'
            placeholder='Herhaal nieuw wachtwoord'
            label='Herhaal nieuw wachtwoord'
            icon='key'
            isValid={!fieldState.invalid}
          />
        )}
        name='repeatNewPassword'
        control={control}
        rules={{
          required: true,
          validate: value => value === newPassword
        }}
      />

      {passwordError && <p className='text-red'>{passwordError}</p>}

      {passwordFeedback && <p className='text-success'>{passwordFeedback}</p>}

      <Button
        className={'primary-btn w-full mt-8 mb-3'}
        type='submit'
        disabled={!isValid}
        isLoading={isLoading}
      >
        Opslaan
      </Button>
    </form>
  )
}

export default PasswordForm
