import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { useMemberBenefits } from '@verzekeringapp-mono/shared/src/hooks/use_member_benefits'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getMemeberBenefit } from '@verzekeringapp-mono/shared/src/state/selectors/member_benefits'
import MemberBenefitVoucher from './components/member_benefit_voucher'
import MemberBenefitCampaign from './components/member_benefit_campaign'
import MemberBenefitContent from './components/member_benefit_content'

const MemberBenefitDetailScreen: React.FC = () => {
  const { id } = useParams()
  const memberBenefit = useAppSelector(getMemeberBenefit(id ?? ''))

  useMemberBenefits(id)

  return (
    <ScreenWrapper
      breadCrumbsOverride={[
        { label: 'Ledenvoordelen', path: '/ledenvoordelen' },
        {
          label: 'Ledenvoordeel',
          path: `/ledenvoordelen/${id}`
        }
      ]}
    >
      <div className='md:w-full lg:w-1/2'>
        {memberBenefit?.image
          ? (
            <img
              src={memberBenefit?.image}
              className='w-full h-[300px] object-cover'
            />
          )
          : (
            <div className='w-full h-[300px] bg-gray-200' />
          )}

        <p className='mt-8 text-xl'>{memberBenefit?.title}</p>

        <p className='mb-6 font-light text-gray mt-4'>{memberBenefit?.intro}</p>

        {memberBenefit && (
          <>
            <MemberBenefitVoucher memberBenefit={memberBenefit} />

            <MemberBenefitCampaign memberBenefit={memberBenefit} />

            <MemberBenefitContent memberBenefit={memberBenefit} />
          </>
        )}
      </div>
    </ScreenWrapper>
  )
}

export default MemberBenefitDetailScreen
