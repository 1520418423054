import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'

const _getState = (state: RootState) => state.claims

export const getClaims = createSelector(_getState, state => state.claims)

export const getClaim = (id: string) =>
  createSelector(_getState, state =>
    state.claims?.find(claim => claim?.id === id)
  )

export const getClaimDamageForm = createSelector(
  _getState,
  state => state.claimDamageForm
)

export const getConceptClaim = createSelector(
  _getState,
  state => state.conceptClaim
)

export const getConceptIsLoading = createSelector(
  _getState,
  state => state.conceptIsLoading
)
export const getClaimIsLoading = createSelector(
  _getState,
  state => state.claimIsLoading
)

export const getFileIsLoading = createSelector(
  _getState,
  state => state.fileIsLoading
)
export const getConceptClaimFromClaims = createSelector(_getState, state =>
  state.claims?.find(
    claim => state.conceptClaim?.temporaryClaimReference === claim?.id
  )
)

export const getClaimDamageFormIsDirty = createSelector(_getState, state => {
  const form = state.claimDamageForm

  return (
    form.date !== '' ||
    form.description !== '' ||
    form.phoneNumber !== '' ||
    (!!form.attachments?.length && form.attachments.length > 0)
  )
})

export const getCurrentStep = createSelector(
  _getState,
  state => state.currentStep
)

export const getIsFormCompleted = createSelector(
  _getState,
  state => state.isFormCompleted
)

export const getLatestClaimId = createSelector(
  _getState,
  state => state.latestClaimId
)
