import React from 'react'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useThemeColorContext } from '../providers/ThemeColorProvider'

const MyPoliciesHeader = ({
  variant = 'claim'
}: {
  variant?: 'home' | 'claim'
}) => {
  const relation = useAppSelector(getCurrentRelation)
  const { brandColors } = useThemeColorContext()

  return (
    <div className='p-8'>
      <div className='flex flex-wrap justify-between items-center mb-8'>
        <div className='flex items-center'>
          <div className='w-[50px] h-[50px] bg-lightBrand rounded-[25px] flex justify-center items-center mr-4 md:hidden'>
            <FontAwesomeIcon
              icon='box-alt'
              size='lg'
              color={brandColors.brand}
            />
          </div>

          <div>
            <p className='text-xl md:text-3xl md:text-gray text-brand'>
              Mijn pakket
            </p>

            <p className='paragraph-light'>
              {`Combinummer: ${relation?.combiNumber}`}
            </p>
          </div>
        </div>

        {relation?.combiCollectionAmount && (
          <div className='text-end'>
            <p className='text-xl md:text-3xl  text-brand'>
              {new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR'
              }).format(Number(relation?.combiCollectionAmount))}
            </p>

            <p className='paragraph-light'>
              per {relation?.paymentPeriod.toLowerCase()}
            </p>
          </div>
        )}
      </div>

      <div className='grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 bg-gray-50 hidden md:grid bg-white md:bg-inherit'>
        <div>
          <p className='paragraph-light'>Ingangsdatum:</p>

          <p>{relation?.combiStartingDate}</p>
        </div>

        <div>
          <p className='paragraph-light'>Vervaldatum:</p>

          <p>{relation?.combiContractExpirationDate}</p>
        </div>

        <div className='md:block hidden'></div>

        <div>
          <p className='paragraph-light'>Aantal actieve polissen:</p>

          <p>{relation?.combiNumberOfPolicies}</p>
        </div>

        {relation?.combiPackageDiscount && (
          <div>
            <p className='paragraph-light'>Combikorting:</p>

            <p>
              {new Intl.NumberFormat('nl-NL', {
                style: 'percent'
              }).format(Number(relation.combiPackageDiscount) / 100)}
            </p>
          </div>
        )}

        <div>
          <p className='paragraph-light'>Betaalmethode:</p>

          <p>{relation?.paymentMethod}</p>
        </div>
      </div>

      {/* Claim Variant Links */}
      {variant === 'claim' && (
        <div className='mt-8 flex gap-2 md:gap-4'>
          <a
            href='/verzekeringen/combipolis'
            className='text-brand text-sm'
          >
            <FontAwesomeIcon
              icon={['fal', 'arrow-right']}
              size='sm'
              color='currentColor'
              className='mr-2'
            />

            <span className='hidden md:inline'>Bekijk de combipolis</span>

            <span className='md:hidden'>Combipolis</span>
          </a>

          <a
            href={relation?.policyStatement}
            className='text-brand text-sm'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon
              icon={['fal', 'arrow-down']}
              size='sm'
              color='currentColor'
              className='mr-2'
            />

            <span className='hidden md:inline'>Download het polisblad</span>

            <span className='md:hidden'>Polisblad</span>
          </a>
        </div>
      )}
    </div>
  )
}

export default MyPoliciesHeader
