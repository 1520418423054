import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'

const _getState = (state: RootState) => state.user

export const getUser = createSelector(_getState, state => state.user)

export const getEmail = createSelector(_getState, state => state.user?.email)

export const getShowFirstUse = createSelector(
  _getState,
  state => state.showFirstUse
)

export const getCheckEmailStatus = createSelector(
  _getState,
  state => state.checkEmailStatus
)

export const getFullName = createSelector(
  _getState,
  state =>
    `${state.user?.firstName ?? ''}
    ${state.user?.insertion ?? ''}
    ${state.user?.lastName ?? ''}`
)
