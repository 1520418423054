import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IRelation } from '../../models/relation'
import { logout, selectRelation } from '../actions/constants'
import { IUser } from '../../models/user'
import { updateUser } from '../../api/relation'

export interface ITermsState {
  relations: IRelation[]
  currentRelation?: IRelation
  relationUsers: IUser[]
  isLoading: boolean
}

const initialState: ITermsState = {
  relations: [],
  currentRelation: undefined,
  relationUsers: [],
  isLoading: false
}

export const relationsSlice = createSlice({
  name: 'relations',
  initialState,
  reducers: {
    setRelations: (state, action: PayloadAction<IRelation[]>) => {
      state.relations = action.payload

      if (state.currentRelation === undefined) {
        state.currentRelation = action.payload[0]
      } else {
        state.currentRelation = {
          ...state.currentRelation,
          ...action.payload.find(
            (relation) => relation.id === state.currentRelation?.id
          )
        }
      }
    },
    setRelationIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    updateRelation: (state, action: PayloadAction<IRelation>) => {
      state.relations = state.relations.map((relation) => {
        if (relation.id === action.payload.id) {
          return action.payload
        }

        return relation
      })
      if (state.currentRelation?.id === action.payload.id) {
        state.currentRelation = action.payload
      }
    },
    setCurrentRelation: (state, action: PayloadAction<IRelation>) => {
      state.currentRelation = action.payload
      state.relations = state.relations.map((relation) => {
        if (relation.id === action.payload.id) {
          return action.payload
        }

        return relation
      })
    },
    deleteRelation: (state, action: PayloadAction<string>) => {
      state.relations = state.relations.filter(
        (relation) => relation.id !== action.payload
      )
    },
    setRelationUsers: (state, action: PayloadAction<IUser[]>) => {
      state.relationUsers = action.payload
    },
    setRelationUser: (state, action: PayloadAction<IUser>) => {
      state.relationUsers = state.relationUsers.map((user) => {
        if (user.id === action.payload.id) {
          return action.payload
        }

        return user
      })
    },
    updateUserByProperty: (
      state,
      action: PayloadAction<{
        id: string
        email?: string
        firstName?: string
        initials?: string
        lastName?: string
        dateOfBirth?: string
      }>
    ) => {
      state.relationUsers = state.relationUsers.map((user) => {
        if (user.id === action.payload.id) {
          return {
            ...user,
            ...action.payload
          }
        }

        return user
      })
    },
    deleteRelationUser: (state, action: PayloadAction<string>) => {
      state.relationUsers = state.relationUsers.filter(
        (user) => user.id !== action.payload
      )
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, (state, action: PayloadAction<IRelation>) => {
        state.currentRelation = action.payload
      })
  }
})

export const {
  updateRelation,
  setRelations,
  setCurrentRelation,
  deleteRelation,
  setRelationUsers,
  setRelationUser,
  updateUserByProperty,
  deleteRelationUser,
  setRelationIsLoading
} = relationsSlice.actions

export default relationsSlice.reducer
