import React from 'react'
import { IFlashNews } from '@verzekeringapp-mono/shared/src/models/flash_news'

const FlashNews: React.FC<{ flashNews: IFlashNews}> = ({ flashNews }) => {

  const onPressReadMore = () => {
    if (flashNews?.link && flashNews.link.includes('https://')) {
      window.open(flashNews.link, '_blank')
    }
  }

  return (
    <div className={'flex-1 bg-white p-8 rounded-lg'}>
      <p className='py-3 text-brand'>{flashNews?.tag}</p>

      <p className='pb-3'>{flashNews?.title}</p>

      <p className='paragraph-light'>{flashNews?.content}</p>

      {(flashNews?.linkText && flashNews?.link) && (
        <button
          className='py-3 paragraph-light text-brand'
          onClick={onPressReadMore}
        >
              Lees meer
        </button>
      )}
    </div>
  )
}

export default FlashNews
