import React, { useCallback } from 'react'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useClaims } from '@verzekeringapp-mono/shared/src/hooks/use_claims'
import { Link, useNavigate } from 'react-router-dom'
import { IClaim } from '@verzekeringapp-mono/shared/src/models/claim'
import { format } from 'date-fns'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useThemeColorContext } from '../../../../../providers/ThemeColorProvider'

interface IProps {
  claim: IClaim
}

const icons: { [key: string]: IconProp } = {
  Concept: 'pencil',
  'In behandeling': 'clock',
  Afgehandeld: 'check',
  'Gemeld bij adviseur': 'user-tie'
}

const ClaimIcon = ({ claim }: { claim: IClaim }) => (
  <div
    className={
      'w-[50px] h-[50px] rounded-[25px] flex justify-center items-center bg-lightBrand'
    }
  >
    <FontAwesomeIcon
      icon={icons[claim.status]}
      size='lg'
      color={claim.statusColor}
    />
  </div>
)

const ClaimCard: React.FC<IProps> = ({ claim }) => {
  const { brandColors } = useThemeColorContext()
  const { deleteClaim } = useClaims()
  const navigate = useNavigate()

  const onPressRemove = useCallback(
    async (temporaryClaimReference: string) => {
      const confirmed = window.confirm(
        'Weet u zeker dat u de concept schademelding wilt verwijderen?'
      )

      if (confirmed) {
        const success = await deleteClaim(temporaryClaimReference)

        if (success) {
          navigate('/dossier/mijn-schades')
        }
      }
    },
    [claim, deleteClaim]
  )

  return (
    <div className='flex flex-col gap-8 bg-white rounded p-4 md:p-8 justify-center mb-4'>
      <Link
        to={`/dossier/mijn-schades/${
          claim?.temporaryClaimReference || claim?.hash
        }`}
      >
        <div className='hidden md:flex flex-col gap-4'>
          <div className='flex justify-between'>
            <p className='text-xl'>{claim.productName}</p>

            <div
              className='flex items-center justify-center px-4 rounded-full'
              style={{ backgroundColor: claim.statusColor }}
            >
              <p className='paragraph-light text-white'>{claim.status}</p>
            </div>
          </div>

          <div className='flex gap-4 flex-1'>
            <div className='w-1/4'>
              <p className='paragraph-light'>Schadedatum</p>

              {claim.dateOfLoss && (
                <p>{format(new Date(claim.dateOfLoss), 'dd-MM-yyyy')}</p>
              )}
            </div>

            <div className='w-1/4'>
              <p className='paragraph-light'>Polisnummer</p>

              <p>{claim.policyNumber}</p>
            </div>

            <div className='w-1/4'>
              <p className='paragraph-light'>Verzekering</p>

              <p>{claim.productName}</p>
            </div>
          </div>

          <div>
            <p className='paragraph-light'>Toelichting status</p>

            <p>{claim.statusDescription}</p>
          </div>

          <div className='flex justify-between'>
            <a
              href={`/dossier/mijn-schades/${
                claim?.temporaryClaimReference || claim?.hash
              }`}
              className='text-brand flex items-center gap-2 text-sm'
            >
              <FontAwesomeIcon
                icon={['fal', 'arrow-right']}
                color={brandColors.brand}
                size='sm'
              />
              Bekijk schademelding
            </a>

            {claim?.status === 'Concept' && (
              <button
                onClick={() =>
                  onPressRemove(claim?.temporaryClaimReference || '')
                }
              >
                <FontAwesomeIcon
                  icon='trash'
                  color={brandColors.brand}
                />
              </button>
            )}
          </div>
        </div>

        <div className='flex items-center justify-between flex-1 md:hidden'>
          <div className='flex flex-row items-center'>
            <div className='w-[50px] h-[50px] bg-lightBrand rounded-[25px] flex justify-center items-center mr-4'>
              <ClaimIcon claim={claim} />
            </div>

            <div>
              <p className='text-gray-800 font-bold'>{claim.productName}</p>

              <small className='text-textGray'>{claim.policyNumber}</small>

              <p className='text-textGray text-sm'>{claim.status}</p>
            </div>
          </div>

          <div>
            <FontAwesomeIcon
              icon={['fal', 'chevron-right']}
              size='lg'
              color={Colors.textGray}
            />
          </div>
        </div>
      </Link>
    </div>
  )
}

export default ClaimCard
