import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IQuestion } from '@verzekeringapp-mono/shared/src/models/question'
import { useThemeColorContext } from '../../../../../providers/ThemeColorProvider'

interface IProps {
  question: IQuestion
}

const QuestionToggle: React.FC<IProps> = props => {
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const { question } = props
  const { brandColors } = useThemeColorContext()

  return (
    <div className='bg-white rounded mb-4'>
      <div
        key={props.question.id}
        className='flex flex-row justify-between items-center px-4 cursor-pointer py-4'
        onClick={() => setShowMoreInfo(!showMoreInfo)}
      >
        <p className='font-bold'>{question.text}</p>

        <button className='w-[34px] h-[34px] bg-lightBrand rounded-[17px] flex justify-center items-center'>
          {showMoreInfo
            ? (
              <FontAwesomeIcon
                icon={['far', 'minus']}
                size='lg'
                color={brandColors.brand}
              />
            )
            : (
              <FontAwesomeIcon
                icon='plus'
                size='lg'
                color={brandColors.brand}
              />
            )}
        </button>
      </div>

      {showMoreInfo && (
        <div className='bg-white rounded-b p-4 border-t border-lightGray'>
          <div
            className='px-4 html-content '
            dangerouslySetInnerHTML={{ __html: question.answer }}
          />
        </div>
      )}
    </div>
  )
}

export default QuestionToggle
