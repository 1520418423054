import { createAction } from '@reduxjs/toolkit'
import { LOGOUT, SELECT_RELATION } from '../constants/constants'
import { IRelation } from '../../models/relation'
import { IUser } from '../../models/user'

export const logout = createAction(LOGOUT)

export const selectRelation = createAction(
  SELECT_RELATION,
  (relation: IRelation) => ({
    payload: relation
  })
)

export const addRelationUsers = createAction(
  'relations/setRelationUsers',
  (id: number, users: IUser[]) => ({
    payload: {
      id,
      users
    }
  })
)
