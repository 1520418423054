import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import useAgency from '@verzekeringapp-mono/shared/src/hooks/use_agency'
import { getAgency as getAgencySelector } from '@verzekeringapp-mono/shared/src/state/selectors/agency'
import {
  getClaim,
  getLatestClaimId
} from '@verzekeringapp-mono/shared/src/state/selectors/claims'
import { getAgencyId } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button'

const ClaimDamageConfirmation = () => {
  const agencyId = useAppSelector(getAgencyId)!
  const agency = useAppSelector(getAgencySelector)
  const { getAgency } = useAgency()
  const navigate = useNavigate()

  const latestClaimId = useAppSelector(getLatestClaimId)
  const claim = useAppSelector(getClaim(latestClaimId ?? ''))

  useEffect(() => {
    getAgency(agencyId)
  }, [agencyId])

  return (
    <div className='flex justify-center items-center'>
      <div className='bg-white p-8 rounded-lg text-center w-5/6 lg:w-5/6'>
        <div className='flex justify-center mb-6'>
          <div
            className={`bg-brand text-white rounded-full h-12 w-12 flex items-center justify-center text-2xl ${'bg-success'}`}
          >
            <FontAwesomeIcon icon='check' />
          </div>
        </div>

        <h2 className='text-gray text-xl font-normal mb-2'>
          Schademelding ingediend bij
        </h2>

        <h3 className='text-4xl font-medium text-gray mb-8'>{agency?.name}</h3>

        <p className='text-gray mb-8'>{claim?.statusDescription}</p>

        <div className='flex justify-center space-x-4'>
          <Button
            onClick={() => {
              navigate(`/dossier/mijn-schades/${latestClaimId}`)
            }}
          >
            Bekijk schademelding
          </Button>

          <Button
            onClick={() => {
              navigate('/dossier/mijn-schades')
            }}
            variant='secondary'
          >
            Terug naar het overzicht
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ClaimDamageConfirmation
