import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useIdin } from '@verzekeringapp-mono/shared/src/hooks/use_idin'
import LoginScreenWrapper from '../../components/screen_wrappers/login_screen_wrapper'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import {
  getIdin,
  getIdinVerifyStatus
} from '@verzekeringapp-mono/shared/src/state/selectors/idin'
import NotFound from './components/idin_register/NotFound'
import Register from './components/idin_register/Register'

const IdinRegisterScreen: React.FC = () => {
  const location = useLocation()
  const idinVerifyStatus = useAppSelector(getIdinVerifyStatus)

  const idin = useAppSelector(getIdin)
  const { verifyIdin } = useIdin()
  const email = new URLSearchParams(location.search).get('email') ?? ''
  const entranceCode =
    new URLSearchParams(location.search).get('entranceCode') ?? ''

  useEffect(() => {
    if (
      entranceCode !== '' &&
      email !== '' &&
      idin.entranceCode &&
      idin.transactionID
    ) {
      if (idin.entranceCode === entranceCode) {
        verifyIdin(
          entranceCode,
          idin.transactionID,
          `${process.env.REACT_APP_BASE_URL}/idin-register`,
          email
        )
      } else {
        console.log('entrance code unequal.')
      }
    }
  }, [])

  return (
    <LoginScreenWrapper
      showHelpButton={idinVerifyStatus === 'ERROR'}
      helpButtonText='Wat is iDIN?'
    >
      {idinVerifyStatus === 'ERROR' ? <NotFound /> : <Register />}
    </LoginScreenWrapper>
  )
}

export default IdinRegisterScreen
