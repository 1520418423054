import { add, parse } from 'date-fns'
import { IPolicy } from '../models/policy'

const showValueMeter = (policy: IPolicy): boolean => {
  const { productName, details } = policy
  const underinsuranceGuaranteDate = details.find(
    (detail) => detail.name === 'underinsurance_guarantee_date'
  )

  if (
    !underinsuranceGuaranteDate ||
    typeof underinsuranceGuaranteDate.value !== 'string'
  ) {
    return false
  }

  const isOlderThan57Months =
    new Date() >=
    add(parse(underinsuranceGuaranteDate.value, 'dd-MM-yyyy', new Date()), {
      months: 57
    })

  return (
    (productName === 'Inboedel' || productName === 'Woonhuis') &&
    isOlderThan57Months
  )
}

export default showValueMeter
