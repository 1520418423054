import React, { useEffect } from 'react'
import NavBarItem from './nav_bar_item'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAgency from '@verzekeringapp-mono/shared/src/hooks/use_agency'
import { IAgency } from '@verzekeringapp-mono/shared/src/models/agency'
import ClaimDamageButton from '../ClaimDamageButton'
import { Link } from 'react-router-dom'
import { parse } from 'date-fns'
import { useThemeColorContext } from '../../providers/ThemeColorProvider'

interface NavBarButtonItem {
  href: string
  icon: IconProp
  title: string
}

export const getButtons = (showMemberBenefits: boolean): NavBarButtonItem[] => {
  const buttons: NavBarButtonItem[] = showMemberBenefits
    ? [
      {
        href: '/overzicht',
        icon: 'home',
        title: 'Overzicht'
      },
      {
        href: '/dossier',
        icon: 'folder',
        title: 'Mijn dossier'
      },
      {
        href: '/verzekeringen',
        icon: 'copy',
        title: 'Mijn verzekeringen'
      },
      {
        href: '/adviseur',
        icon: 'user-tie',
        title: 'Adviseur'
      },
      {
        href: '/ledenvoordelen',
        icon: 'tag',
        title: 'Ledenvoordelen'
      },
      {
        href: '/meer',
        icon: 'ellipsis-v',
        title: 'Meer'
      }
    ]
    : [
      {
        href: '/overzicht',
        icon: 'home',
        title: 'Overzicht'
      },
      {
        href: '/dossier',
        icon: 'folder',
        title: 'Mijn dossier'
      },
      {
        href: '/verzekeringen',
        icon: 'copy',
        title: 'Mijn verzekeringen'
      },
      {
        href: '/adviseur',
        icon: 'user-tie',
        title: 'Adviseur'
      },
      {
        href: '/meer',
        icon: 'ellipsis-v',
        title: 'Meer'
      }
    ]

  return buttons
}

const getTodaysOpeningHours = (openingHours: IAgency['openingHours']) => {
  const today = new Date().getDay()
  const currentHour = new Date().getHours()

  return openingHours.find(
    item =>
      item.dayNumber === today &&
      parse(item.opening, 'HH:mm', new Date()).getHours() <= currentHour &&
      parse(item.closing, 'HH:mm', new Date()).getHours() > currentHour
  )
}

export const renderOpeningHoursText = (
  openingHours: IAgency['openingHours']
) => {
  if (!openingHours) {
    return 'Gesloten'
  }

  const todayOpeningHours = getTodaysOpeningHours(openingHours)

  if (!todayOpeningHours) {
    if (findNextOpeningHour(openingHours)) {
      return `Gesloten, opent ${findNextOpeningHour(
        openingHours
      )?.day.toLowerCase()} om ${findNextOpeningHour(openingHours)?.opening}`
    } else {
      return 'Gesloten'
    }
  }

  return `Geopend, sluit om ${todayOpeningHours.closing}`
}

const findNextOpeningHour = (
  openingHours: IAgency['openingHours']
): IAgency['openingHours'][0] | undefined => {
  if (!openingHours) {
    return undefined
  }

  const todayOpeningHours = getTodaysOpeningHours(openingHours)
  const today = new Date().getDay()
  const currentHour = new Date().getHours()

  if (todayOpeningHours) {
    return todayOpeningHours
  }

  const nextOpeningHour = openingHours.find(
    item =>
      item.dayNumber === today &&
      new Date(item.opening).getHours() > currentHour
  )

  if (nextOpeningHour) {
    return nextOpeningHour
  }

  return openingHours.find(item => item.dayNumber > today)
}

const agencyOpenState = (
  openingHours: IAgency['openingHours']
): 'closed' | 'open' => {
  if (!openingHours) {
    return 'closed'
  }

  const today = new Date().getDay()

  const todayOpeningHours = openingHours.find(item => item.dayNumber === today)

  if (!todayOpeningHours) {
    return 'closed'
  }

  return 'open'
}

const NavBar = () => {
  const { brandColors } = useThemeColorContext()

  const currentRelation = useAppSelector(getCurrentRelation)!

  const agency = useAppSelector(state => state.agency.agency)
  const { getAgency } = useAgency()

  useEffect(() => {
    if (!agency) {
      getAgency(currentRelation.agencyId)
    }
  }, [])

  return (
    <div className='bg-white w-[330px] overflow-y-auto h-full'>
      {getButtons(currentRelation?.cooperationMembership).map((item, index) => (
        <NavBarItem
          key={index}
          href={item.href}
          icon={item.icon}
          title={item.title}
        />
      ))}

      <div className='flex justify-center px-8'>
        <ClaimDamageButton />
      </div>

      <div className='flex flex-col border-b-[0.5px] border-t-[0.5px] border-background gap-8 py-8'>
        <p className='px-8 text-textGray'>
          {agency?.name ?? 'NH1816 Verzekeringen'}
        </p>

        {agency && (
          <div className='flex flex-col gap-4 px-8'>
            <div className='flex items-center gap-2'>
              <div
                className={`w-[8px] h-[8px] mr-2 rounded-full ${
                  agencyOpenState(agency?.openingHours) === 'open'
                    ? 'bg-green-500'
                    : 'bg-red-500'
                }`}
              />

              {agency?.openingHours && (
                <p className='text-sm'>
                  {renderOpeningHoursText(agency.openingHours)}
                </p>
              )}
            </div>

            <div className='flex'>
              <FontAwesomeIcon
                icon='phone'
                size='lg'
                color={brandColors.brand}
              />

              {agency?.phoneNumber && (
                <p className='ml-2 text-sm'>{agency.phoneNumber}</p>
              )}
            </div>

            <div className='flex'>
              <FontAwesomeIcon
                icon='envelope'
                size='lg'
                color={brandColors.brand}
              />

              {agency?.email && <p className='ml-2 text-sm'>{agency.email}</p>}
            </div>

            <Link
              className='link text-sm'
              to='/adviseur'
            >
              <FontAwesomeIcon
                icon={['fal', 'arrow-right']}
                color={brandColors.brand}
              />{' '}
              Bekijk het volledige profiel
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default NavBar
