import { INotification } from '../models/notification'
import Api from './index'

export const getNotifications = () => Api<INotification[]>('GET', 'support/inbox-messages')

export const getNotification = (id: string) => Api<INotification>('GET', `support/inbox-messages/${id}`)

export const deleteNotification = (id: number) => Api<INotification>('DELETE', `support/inbox-messages/${id}`)

export const putNotification = (id: number) => Api<INotification>('PUT', `support/inbox-messages/${id}`)
