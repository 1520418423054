import useRelation from '@verzekeringapp-mono/shared/src/hooks/use_relation'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import Button from '../../../../components/Button'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import TextInput from '../../../../components/TextInput'
import CheckboxInput from '../../../../components/CheckboxInput'

const EmailForm: React.FC = () => {
  const { updateEmailAddress } = useRelation()

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      newEmail: '',
      repeatNewEmail: '',
      password: '',
      isRelationMutation: false
    }
  })

  const isLoading = useAppSelector(state => state.relations.isLoading)

  return (
    <form
      onSubmit={handleSubmit(async data => {
        await updateEmailAddress(
          data.newEmail,
          data.password,
          data.isRelationMutation
        )
      })}
      className='md:w-1/2 w-full'
    >
      <Controller
        name='newEmail'
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            label='Nieuw e-mailadres'
            type='email'
            placeholder='E-mail'
            icon='envelope'
            isValid={!fieldState.invalid}
            {...field}
          />
        )}
      />

      <Controller
        name='repeatNewEmail'
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            label='Herhaal nieuw e-mailadres'
            type='email'
            placeholder='E-mail'
            icon='envelope'
            isValid={!fieldState.invalid}
            {...field}
          />
        )}
      />

      <Controller
        name='password'
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            label='Wachtwoord ter bevestiging'
            type='password'
            placeholder='Wachtwoord'
            icon='key'
            isValid={!fieldState.invalid}
            {...field}
          />
        )}
      />

      <Controller
        name='isRelationMutation'
        control={control}
        render={({ field }) => (
          <CheckboxInput
            {...field}
            label='Wijzig mijn e-mailadres ook voor alle communicatie over mijn combipolis.'
            checked={!!field.value}
            value={field.name}
          />
        )}
      />

      <Button
        className={`primary-btn w-full ${
          isValid ? '' : 'opacity-50'
        } mt-8 mb-3`}
        type='submit'
        isLoading={isLoading}
        disabled={!isValid}
      >
        Opslaan
      </Button>
    </form>
  )
}

export default EmailForm
