import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { logout, selectRelation } from '../actions/constants'
import { ICarWindowRepairer, IRepairer } from '../../api/types/company'
import { CompanyType } from '../constants/constants'

export type CompanyCategory = {
  id: number
  name: string
  brands: {
    id: number
    name: string
  }[]
}
export interface ICompanyFilter {
  companyType: number | null
  brand: number | null
  location: string | null
  radius: number | null
}
export interface ICompanySlice {
  repairers: IRepairer[]
  filters: ICompanyFilter

  categories: CompanyCategory[]
  radius: number[]
}

const initialState: ICompanySlice = {
  repairers: [],

  filters: {
    companyType: null,
    brand: null,
    location: null,
    radius: null
  },
  categories: [],
  radius: []
}

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setRepairers: (state, action: PayloadAction<IRepairer[]>) => {
      state.repairers = action.payload
    },
    resetFilters: () => initialState,
    removeFilterLocation: state => {
      state.filters.location = null
    },
    updateFilterLocation: (
      state,
      action: PayloadAction<ICompanyFilter['location']>
    ) => {
      state.filters.location = action.payload
    },
    updateFilterRadius: (state, action: PayloadAction<number>) => {
      state.filters.radius = action.payload
    },
    updateFilterCompanyType: (state, action: PayloadAction<number>) => {
      state.filters.companyType = action.payload
    },
    updateFilters: (state, action: PayloadAction<ICompanyFilter>) => {
      state.filters = action.payload
    },
    setCategories: (state, action: PayloadAction<CompanyCategory[]>) => {
      state.categories = action.payload
    },
    setRadius: (state, action: PayloadAction<number[]>) => {
      state.radius = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, () => initialState)
  }
})

export const {
  setRepairers,
  resetFilters,
  removeFilterLocation,
  updateFilterLocation,
  updateFilterRadius,
  updateFilterCompanyType,
  updateFilters,
  setCategories,
  setRadius
} = companiesSlice.actions

export default companiesSlice.reducer
