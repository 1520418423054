import { ITerms } from '../models/terms'
import { IWhatToDoItem } from '../models/what_to_do'
import Api from './index'
import { GetFlashNewsPayload } from './types/flash_news'

export const getAboutMembership = () => Api<string>('GET', 'support/about-membership')

export const getAboutNh = () => Api<string>('GET', 'support/about-nh')

export const getTermsApp = () => Api<ITerms>('GET', 'terms/app')

export const getWhatToDo = () => Api<IWhatToDoItem[]>('GET', 'support/wtd')

export const getFlashNews = () => Api<GetFlashNewsPayload>('GET', 'support/flash-messages')
