import React from 'react'
import { Images } from '@verzekeringapp-mono/shared/src/assets'
import { useAppDispatch } from '@verzekeringapp-mono/shared/src/hooks/state'
import useIdin from '@verzekeringapp-mono/shared/src/hooks/use_idin'
import { setIdin } from '@verzekeringapp-mono/shared/src/state/slices/idin_slice'
import { useNavigate } from 'react-router-dom'
import Modal from '@verzekeringapp-mono/shared/src/components/Modal'
import IdinLogo from '@verzekeringapp-mono/shared/src/assets/icons/IdinLogo'
import HelpQuestionToggle from '../../../../components/help_question_toggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Register = () => {
  const [showModal, setShowModal] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { identifyIdin } = useIdin()
  const email = new URLSearchParams(location.search).get('email') ?? ''

  const identifyIdinUser = async () => {
    const result = await identifyIdin(
      `${process.env.REACT_APP_BASE_URL}/idin-register`,
      email
    )

    if (result.transactionURL !== '' && result?.entranceCode !== '') {
      dispatch(setIdin(result))
      window.open(result.transactionURL, '_self')
    }
  }

  return (
    <>
      <img
        src={Images.Logo}
        width={180}
        height={70}
      />

      <p className='title  mt-8'>Registreren</p>

      <p className='description mt-4'>{`We hebben geen account gegevens gevonden op basis van uw e-mailadres: ${email}`}</p>

      <p className='description mt-4'>
        Neem contact op met uw verzekeringsadviseur om te verifiëren via welk
        e-mailadres u bij ons bekend bent.
      </p>

      <p className='description mt-4'>
        U kunt zich in dit geval ook eenvoudig identificeren via iDIN.
      </p>

      <button
        className='primary-btn w-full mt-8'
        onClick={identifyIdinUser}
      >
        <div className='flex w-full items-center text-white justify-between'>
          <div className='w-[40px] h-[40px] rounded-full aspect-square bg-white flex items-center justify-center'>
            <IdinLogo />
          </div>
          Identificeren met iDIN

          <div></div>
        </div>
      </button>

      <button
        className='secondary-btn w-full mt-4'
        onClick={() => navigate('/home')}
      >
        Terug
      </button>

      <button
        onClick={() => setShowModal(true)}
        className='description mt-4'
      >
        Wat is IDIN?
      </button>

      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <>
            <h2 className='title mt-8 text-center'>Help</h2>

            <button
              className='absolute top-4 right-4'
              onClick={() => setShowModal(false)}
            >
              <FontAwesomeIcon icon={['fal', 'times']} />
            </button>

            <HelpQuestionToggle
              question={{
                text: 'Ik ben het e-mailadres vergeten waarop ik inlog. Wat moet ik doen?',
                answer:
                  'Neem contact op met uw verzekeringsadviseur. Hij kan achterhalen via welk e-mailadres u geregistreerd bent.',
                id: '1'
              }}
            />

            <HelpQuestionToggle
              question={{
                text: 'Kan iedereen een account aanmaken?',
                answer:
                  'Nee. U kunt alleen een Mijn Polissen account aanvragen als er Nh1816 Verzekeringen op uw naam staan.',
                id: '2'
              }}
            />

            <HelpQuestionToggle
              question={{
                text: 'Mijn verzekeringen lopen via een ander gezinslid. Hoe kan ik deze verzekeringen zelf inzien?',
                answer:
                  'De hoofdgebruiker kan binnen de VerzekeringApp en Mijn Polissen omgeving een sub-gebruiker aanmaken. Dit doet hij/zij bij de instellingen. Sub-gebruikers kunnen middels een eigen inlog verzekeringen inzien en bijvoorbeeld het Verzekeringsbewijs raadplegen.',
                id: '3'
              }}
            />

            <HelpQuestionToggle
              question={{
                text: 'Ik heb een nieuw e-mailadres. Hoe kan ik dit wijzigen?',
                answer:
                  'Neem contact op met uw verzekeringsadviseur. Hij kan uw e-mailadres wijzigen.',
                id: '4'
              }}
            />
          </>
        </Modal>
      )}
    </>
  )
}

export default Register
