import React, { useEffect } from 'react'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getUser } from '@verzekeringapp-mono/shared/src/state/selectors/user'
import {
  getWhatToDos as getWhatToDosSelector,
  getFlashNews as getFlashNewsSelector
} from '@verzekeringapp-mono/shared/src/state/selectors/support'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import UserInfo from './components/user_info'
import NavigationButton from './components/navigation_button'
import ContentSection from './components/content_section'
import { useSupport } from '@verzekeringapp-mono/shared/src/hooks/use_support'
import { getWhatToDoItemIconName } from '@verzekeringapp-mono/shared/src/models/what_to_do'
import FlashNews from './components/flash_news'
import ClaimDamageButton from '../../components/ClaimDamageButton'
import SETTINGS from '@verzekeringapp-mono/shared/src/constants/settings'
import IllustrationClaims from '../../components/illustrations/IllustrationClaims'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import { getLastThreeUnexpelledPolicies } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { productIcons } from '@verzekeringapp-mono/shared/src/utils/product_icons'
import { useEmergencyModalContext } from '../../providers/EmergencyModalProvider'
import { useThemeColorContext } from '../../providers/ThemeColorProvider'

const HomeScreen: React.FC = () => {
  const { brandColors } = useThemeColorContext()
  const { open } = useEmergencyModalContext()
  const user = useAppSelector(getUser)!

  const whatToDos = useAppSelector(getWhatToDosSelector)
  const flashNews = useAppSelector(getFlashNewsSelector)
  const { getWhatToDos, getFlashNews } = useSupport()

  const currentRelation = useAppSelector(getCurrentRelation)
  const policies = useAppSelector(getLastThreeUnexpelledPolicies)

  useEffect(() => {
    getWhatToDos()
    getFlashNews()
  }, [])

  return (
    <ScreenWrapper
      hasPadding={false}
      breadCrumbsOverride={[]}
    >
      <div>
        <div className='gap-4 mb-8 flex justify-between'>
          <div className='w-4/6'>
            <UserInfo firstName={user?.firstName ?? ''} />
          </div>

          <div className='hidden md:block w-2/6 text-brand text-center'>
            <IllustrationClaims />
          </div>

          <div className='md:hidden w-fit h-fit rounded-full border border-textGray p-4'>
            <img
              src={`${currentRelation?.logo}?${Date.now()}`}
              alt='Logo'
              className='w-20 h-20 object-contain'
            />
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8'>
          <ContentSection
            icon='box-alt'
            title='Mijn pakket'
            subtitle={`Combinummer: ${currentRelation?.combiNumber}`}
            className='lg:col-span-2 flex flex-col flex-wrap justify-between gap-4'
            headerRight={
              <div className='flex flex-col justify-center'>
                <p className='text-brand text-xl font-semibold'>
                  {Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR'
                  }).format(Number(currentRelation?.combiCollectionAmount))}

                  <span className='text-base text-gray'> per maand</span>
                </p>

                <p className='text-textGray'>
                  incl.{' '}

                  {Intl.NumberFormat('nl-NL', {
                    style: 'percent'
                  }).format(
                    Number(currentRelation?.combiPackageDiscount) / 100
                  )}{' '}
                  korting
                </p>
              </div>
            }
          >
            <>
              <div className='flex justify-between flex-wrap gap-4'>
                <div className='flex-col flex-1'>
                  <p className='text-textGray'>Ingangsdatum:</p>

                  <p>{currentRelation?.combiStartingDate}</p>
                </div>

                <div className='flex-col flex-1'>
                  <p className='text-textGray'>Vervaldatum</p>

                  <p>{currentRelation?.combiContractExpirationDate}</p>
                </div>

                <div className='flex-col flex-1'>
                  <p className='text-textGray'>Betaalmethode:</p>

                  <p>{currentRelation?.paymentMethod}</p>
                </div>
              </div>

              <div className='flex-row flex justify-between mt-4 gap-4 flex-wrap'>
                {policies.map(policy => (
                  <div
                    key={policy.id}
                    className='flex-row flex'
                  >
                    <div className='w-[50px] h-[50px] bg-lightBrand rounded-[25px] flex justify-center items-center mr-4'>
                      <FontAwesomeIcon
                        icon={productIcons[policy.productId]}
                        size='lg'
                        color={brandColors.brand}
                      />
                    </div>

                    <div>
                      <p>{policy.productName}</p>

                      <p className='text-textGray'>
                        {Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(Number(policy.premium))}{' '}
                        per maand
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <a
                className='text-brand self-end justify-self-end flex text-sm items-center'
                href='/verzekeringen'
              >
                <FontAwesomeIcon
                  icon={['fal', 'arrow-right']}
                  className='mr-2'
                />
                Bekijk uw pakket
              </a>
            </>
          </ContentSection>

          <ContentSection
            icon='fragile'
            title='Schade melden'
            className='lg:col-span-1'
          >
            <>
              <p>
                Heeft u schade? Vul het schadeformulier in via onderstaande
                button. Uw adviseur neemt de schade vervolgens z.s.m. in
                behandeling!
              </p>

              <ClaimDamageButton />

              <p>In geval van nood, neem contact op via het noodnummer: </p>

              <a
                onClick={open}
                className='text-darkRed'
              >
                {SETTINGS.emergencyPhoneNumber}
              </a>
            </>
          </ContentSection>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8'>
          <ContentSection
            title='Schadehersteller'
            icon='tools'
          >
            <NavigationButton
              text='Voertuigschade'
              icon='car'
              href='/schadehersteller/voertuigschade'
            />
          </ContentSection>

          <ContentSection
            title='Mijn dossier'
            icon='folder'
          >
            <NavigationButton
              text='Mijn schades'
              icon='fragile'
              href='/dossier/mijn-schades'
            />

            <NavigationButton
              text='Mijn betaaloverzicht'
              icon='euro-sign'
              href='/dossier/betalingsoverzicht'
            />
          </ContentSection>

          <ContentSection
            title='Wat te doen bij'
            icon='question'
          >
            {!!whatToDos &&
              !!(whatToDos.length > 0) &&
              whatToDos.map(whatToDo => (
                <NavigationButton
                  key={whatToDo.sortNumber}
                  text={whatToDo.subject}
                  icon={getWhatToDoItemIconName(whatToDo)}
                  href={`/wat-te-doen-bij/${whatToDo.category}`}
                />
              ))}
          </ContentSection>
        </div>

        {flashNews && (
          <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8'>
            <FlashNews flashNews={flashNews} />
          </div>
        )}
      </div>
    </ScreenWrapper>
  )
}

export default HomeScreen
