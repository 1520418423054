import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IPayment } from '../../models/payment'
import { logout, selectRelation } from '../actions/constants'

export interface IPaymentsState {
  payments: IPayment[]
}

const initialState: IPaymentsState = {
  payments: []
}

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPayments: (state, action: PayloadAction<IPayment[]>) => {
      state.payments = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, () => initialState)
  }
})

export const {
  setPayments
} = paymentsSlice.actions

export default paymentsSlice.reducer
