import * as React from 'react'
import {SVGProps} from 'react'
const PolicySvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={228}
    height={128}
    {...props}
  >
    <title>{'polissen'}</title>

    <defs>
      <filter
        id='a'
        width='100%'
        height='100%'
        x='0%'
        y='0%'
        filterUnits='objectBoundingBox'
      >
        <feGaussianBlur in='SourceGraphic' />
      </filter>
    </defs>

    <g
      fill='none'
      fillRule='evenodd'
      transform='translate(0 .75)'
    >
      <ellipse
        cx={114}
        cy={106.25}
        fill='#FBFAFA'
        filter='url(#a)'
        rx={114}
        ry={21}
      />

      <path
        fill='#EFEBE9'
        fillRule='nonzero'
        d='M85.667 36.422V12.25H50.25A4.248 4.248 0 0 0 46 16.515v82.47a4.248 4.248 0 0 0 4.25 4.265h59.5a4.248 4.248 0 0 0 4.25-4.266V40.687H89.917c-2.338 0-4.25-1.92-4.25-4.265ZM97 78c0 1.237-.956 2.25-2.125 2.25h-29.75C63.956 80.25 63 79.237 63 78v-1.5c0-1.238.956-2.25 2.125-2.25h29.75c1.169 0 2.125 1.012 2.125 2.25V78Zm0-11c0 1.237-.956 2.25-2.125 2.25h-29.75C63.956 69.25 63 68.237 63 67v-1.5c0-1.238.956-2.25 2.125-2.25h29.75c1.169 0 2.125 1.012 2.125 2.25V67Zm0-13.5V55c0 1.237-.956 2.25-2.125 2.25h-29.75C63.956 57.25 63 56.237 63 55v-1.5c0-1.238.956-2.25 2.125-2.25h29.75c1.169 0 2.125 1.012 2.125 2.25Zm17-19.346v1.096H91v-23h1.096c1.15 0 2.246.449 3.055 1.258l17.591 17.609A4.299 4.299 0 0 1 114 34.154Z'
      />

      <path
        fill='#84726F'
        fillRule='nonzero'
        d='M152.125 29.219V0h-42.969A5.144 5.144 0 0 0 104 5.156v99.688a5.144 5.144 0 0 0 5.156 5.156h72.188a5.144 5.144 0 0 0 5.156-5.156v-70.47H157.28c-2.836 0-5.156-2.32-5.156-5.155Zm13.75 50.703a2.586 2.586 0 0 1-2.578 2.578h-36.094a2.586 2.586 0 0 1-2.578-2.578v-1.72a2.586 2.586 0 0 1 2.578-2.577h36.094a2.586 2.586 0 0 1 2.578 2.578v1.719Zm0-13.75a2.586 2.586 0 0 1-2.578 2.578h-36.094a2.586 2.586 0 0 1-2.578-2.578v-1.719a2.586 2.586 0 0 1 2.578-2.578h36.094a2.586 2.586 0 0 1 2.578 2.578v1.719Zm0-15.469v1.719A2.586 2.586 0 0 1 163.297 55h-36.094a2.586 2.586 0 0 1-2.578-2.578v-1.719a2.586 2.586 0 0 1 2.578-2.578h36.094a2.586 2.586 0 0 1 2.578 2.578ZM186.5 26.189v1.31H159V0h1.31c1.375 0 2.686.537 3.653 1.504l21.033 21.055a5.14 5.14 0 0 1 1.504 3.63Z'
      />
    </g>
  </svg>
)

export default PolicySvg
