import { IPolicy, IPolicyDetail, PolicyDetailType } from '../models/policy'

const vehicleProductIds = [
  '12', // Bromfiets / Overig
  '15', // Oldtimer
  '3', // Personenauto
  '39', // Caravan / Vouwwagen
  '42', // Stacaravan
  '64', // Camper
  '67', // Bestelauto
  '68', // Aanhangwagen
  '71', // Hobbytractor
  '74', // Quad / Trike
  '86', // Vracht(bestel)auto
  '9' // Motor
]

export const getLicensePlate = (details: IPolicy['details']) => {
  const licensePlateObject = Object.values(details).find(
    (detail) => detail.name === 'license_plate'
  )

  return licensePlateObject?.code || ''
}

export const headerAttributes = (policy: IPolicy): IPolicyDetail[] =>
  Object.values(policy?.details || []).filter(
    (detail) => detail.important && detail.value
  )

export const detailAttributes = (policy: IPolicy): IPolicyDetail[] =>
  Object.values(policy?.details || []).filter(
    (detail) =>
      !detail.important &&
      detail.value &&
      (detail.type === PolicyDetailType.Attribute ||
        detail.type === PolicyDetailType.AcceptanceQuestion)
  )

export const coverageAttributes = (policy: IPolicy): IPolicyDetail[] =>
  Object.values(policy?.details || []).filter(
    (detail) => !detail.important && detail.type === PolicyDetailType.Coverage
  )

const convertDetailToAttribute = (detail: IPolicyDetail) => ({
  description: detail?.code,
  followNumber: detail?.followNumber,
  id: detail?.id,
  title: detail?.label
})

export const convertDetailsToAttributes = (details: IPolicyDetail[]) =>
  (details || []).map(convertDetailToAttribute)

export const determinePolicyDistinctionLabel = ({
  details,
  productId,
  policyNumber
}: Pick<IPolicy, 'details' | 'productId' | 'policyNumber'>) => {
  if (details && productId) {
    if (isPolicyVehicle(productId)) {
      return getLicensePlate(details).length === 0
        ? policyNumber
        : getLicensePlate(details)
    }

    if (isPolicyHouse(details)) {
      return (
        `${details.find((detail) => detail.name === 'zip_code')?.code}, ${
          details.find((detail) => detail.name === 'house_number')?.code
        }` || policyNumber
      )
    }
  }

  return policyNumber
}

export const isPolicyVehicle = (productId: IPolicy['productId']) =>
  !!(productId && vehicleProductIds.includes(String(productId)))

export const isPolicyHouse = (details: IPolicy['details']) =>
  !!(details && details.find((detail) => detail.name === 'zip_code'))
