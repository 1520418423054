import React from 'react'
import { Images } from '@verzekeringapp-mono/shared/src/assets'
import { useNavigate } from 'react-router-dom'
import useSession from '@verzekeringapp-mono/shared/src/hooks/use_session'
import LoginScreenWrapper from '../../components/screen_wrappers/login_screen_wrapper'
import ErrorToast from '../../components/error_toast'
import TextInput from '../../components/TextInput'

interface FormData {
  email: string
}

const ForgotPasswordScreen: React.FC = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = React.useState<FormData>({ email: '' })
  const [showSuccessScreen, setShowSuccessScreen] = React.useState(false)
  const [showErrorPopup, setShowErrorPopup] = React.useState(false)

  const { forgotPassword } = useSession()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const success = await forgotPassword(formData.email, 'resetURL')

    if (success) {
      setShowSuccessScreen(true)
      setShowErrorPopup(false)
    } else {
      setShowSuccessScreen(false)
      setShowErrorPopup(true)
    }
  }

  const isFormValid = (): boolean => formData.email.trim() !== ''

  const forgotPasswordSuccess = () => (
    <>
      <img
        src={Images.Logo}
        width={180}
        height={70}
      />

      <p className='title  mt-8'>E-mail verzonden!</p>

      <p className='description mt-4'>
        We hebben een e-mail verzonden met daarin de instructies om uw
        wachtwoord te resetten.
      </p>

      <button
        className='secondary-btn w-full mt-4'
        onClick={goBack}
      >
        Terug
      </button>
    </>
  )

  const forgotPasswordUI = () => (
    <>
      <img
        src={Images.Logo}
        width={180}
        height={70}
      />

      <p className='title  mt-8'>Wachtwoord vergeten?</p>

      <p className='description mt-4'>
        Om uw wachtwoord te resetten dient u uw email in te vullen. Vervolgens
        ontvangt u een e-mail met alle informatie om uw wachtwoord te resetten.
      </p>

      <form
        onSubmit={handleSubmit}
        className='w-full mt-8'
      >
        {showErrorPopup
          ? (
            <ErrorToast errorText='Er is iets fout gegaan, probeer het opnieuw.' />
          )
          : null}

        <TextInput
          name='email'
          value={formData.email}
          onChange={handleInputChange}
          placeholder='Uw e-mailadres'
          type='email'
          label='E-mailadres'
          icon='envelope'
          shouldValidate={false}
          showValidationIcon={false}
        />

        <button
          className={`primary-btn w-full ${
            isFormValid() ? '' : 'opacity-50'
          } mt-8`}
        >
          Opsturen
        </button>

        <button
          className='secondary-btn w-full mt-4'
          onClick={goBack}
        >
          Terug
        </button>
      </form>
    </>
  )

  return (
    <LoginScreenWrapper>
      {!showSuccessScreen ? forgotPasswordUI() : forgotPasswordSuccess()}
    </LoginScreenWrapper>
  )

  function goBack () {
    navigate('/home')
  }
}

export default ForgotPasswordScreen
