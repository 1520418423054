import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ITermsAndCondition } from '../../models/terms_and_condition'
import { logout, selectRelation } from '../actions/constants'

export interface ITermsState {
  terms: ITermsAndCondition | null
}

const initialState: ITermsState = {
  terms: null
}

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setTerms: (state, action: PayloadAction<ITermsAndCondition>) => {
      state.terms = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, () => initialState)
  }
})

export const {
  setTerms
} = termsSlice.actions

export default termsSlice.reducer
