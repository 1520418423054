import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'

const _getState = (state: RootState) => state.support

export const getAboutMembership = createSelector(
  _getState,
  state => state.aboutMembership
)

export const getAboutNH = createSelector(_getState, state => state.aboutNH)

export const getTermsApp = createSelector(_getState, state => state.termsApp)

export const getWhatToDos = createSelector(_getState, state => state.whatToDos)

export const getWhatToDoItemByCategoryId = (category: string) =>
  createSelector(_getState, state =>
    state.whatToDos?.find(whatToDo => whatToDo.category === category)
  )

export const getFlashNews = createSelector(_getState, state => state.flashNews)
