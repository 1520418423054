import { useCallback } from 'react'

const useGeocode = () => {
  const geocodeAsyncGoogleMaps = useCallback(async (location: string) => {
    const baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json'
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

    try {
      const response = await fetch(
        `${baseUrl}?address=${location}&key=${apiKey}`
      )
      const data = await response.json()

      const firstResult = data.results[0]

      if (firstResult) {
        const latitude = firstResult.geometry.location.lat as number
        const longitude = firstResult.geometry.location.lng as number

        return { latitude, longitude }
      } else {
        return null
      }
    } catch (error) {
      console.error('Google maps api error')

      return null
    }
  }, [])

  const reverseGeocodeAsyncGoogleMaps = useCallback(
    async (coordinates: { latitude: number; longitude: number }) => {
      const baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json'
      const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

      try {
        const response = await fetch(
          `${baseUrl}?latlng=${coordinates.latitude},${coordinates.longitude}&key=${apiKey}`
        )
        const data = await response.json()

        if (response?.status === 200) {
          const results = data.results

          if (results.length > 0) {
            const addressComponents = results[0].address_components
            let postalCode = ''
            let city = ''

            for (const component of addressComponents) {
              if (component.types.includes('postal_code')) {
                postalCode = component.short_name
              } else if (component.types.includes('locality')) {
                city = component.long_name
              }
            }

            if (postalCode !== '' && city !== '') {
              return { postalCode, city }
            }
          }
        }

        return null
      } catch (error) {
        console.error('Google maps api error')

        return null
      }
    },
    []
  )

  return {
    geocodeAsyncGoogleMaps,
    reverseGeocodeAsyncGoogleMaps
  }
}

export default useGeocode
