import { IRelation } from '../models/relation'
import { IUser } from '../models/user'
import Api from './index'
import { IAddUserParams, IUpdateEmailParams } from './types/relation'
import { IChangeEmailResponse } from './types/sessions'

export const getRelation = (id: string) =>
  Api<IRelation>('GET', `relations/${id}`)
export const updateRelation = (
  id: string,
  relation: IRelation,
  updates: Partial<IRelation>
) =>
  Api<IRelation>('PUT', `relations/${id}`, {
    agencyId: relation?.agencyId,
    data: { ...relation, ...updates }
  })
export const updateRelationPreferences = (
  id: string,
  updates: Partial<IRelation>
) => Api<IRelation>('PUT', `relations/preferences/${id}`, updates)

export const deleteRelation = (id: string) =>
  Api<IRelation>('DELETE', `relations/${id}`)

export const updateEmailAddress = (id: string, params: IUpdateEmailParams) =>
  Api<IChangeEmailResponse>('PUT', `relations/${id}/change-email`, {
    ...params
  })

export const getRelationUsers = (id: string) =>
  Api<IUser[]>('GET', `relations/${id}/users`)

export const addRelationUser = (relationId: string, data: IAddUserParams) => {
  const url = new URL('/home', window.location.origin)

  url.searchParams.append('action', 'register')
  url.searchParams.append('email', data.email)

  return Api<IUser>('POST', `relations/${relationId}/users`, {
    data,
    registerUrl: url.toString()
  })
}

export const updateUser = (
  relationId: string,
  userId: string,
  updates: Partial<IUser>
) =>
  Api<IUser>('PUT', `relations/${relationId}/users/${userId}`, {
    data: updates
  })

export const deleteUser = (relationId: string, userId: string) =>
  Api<IUser>('DELETE', `relations/${relationId}/users/${userId}`)

export const addRelationInvite = (addCombiNumber: string, email: string) =>
  Api('POST', 'relations/add-relation-invite', {
    addCombiNumber,
    email
  })
