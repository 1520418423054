import {
  deleteRelation as deleteRelationApi,
  updateEmailAddress as updateEmailAddressAPI,
  getRelation as getRelationApi,
  updateRelation as updateRelationApi,
  addRelationUser as addRelationUserAPI,
  addRelationInvite as addRelationInviteApi,
  deleteUser as deleteRelationUserAPI,
  updateUser as updateRelationUserAPI,
  updateRelationPreferences as updateRelationPreferencesAPI
} from '../api/relation'

import {
  deleteRelation as deleteRelationRedux,
  setCurrentRelation,
  setRelationUser,
  updateRelation as updateRelationRedux,
  setRelationUsers,
  deleteRelationUser as deleteRelationUserFromState,
  setRelationIsLoading,
  updateUserByProperty
} from '../state/slices/relations_slice'
import { getRelationUsers as getRelationUsersApi } from '../api/relation'
import { IRelation } from '../models/relation'
import { selectRelation } from '../state/actions/constants'
import { useAppDispatch, useAppSelector } from './state'
import useAgency from './use_agency'
import usePolicies from './use_policies'
import { getAgencyId, getCurrentRelation } from '../state/selectors/relation'
import { setToken } from '../state/slices/user_slice'
import { IUser } from '../models/user'
import { useNavigate } from 'react-router-dom'
import useUser from './use_user'

export const useRelation = () => {
  const dispatch = useAppDispatch()
  const { getPolicies } = usePolicies()
  const { getAgency } = useAgency()
  const { getUser } = useUser()
  const relation = useAppSelector(getCurrentRelation)

  const agencyId = useAppSelector(getAgencyId)
  const currentRelation = useAppSelector(getCurrentRelation)
  const navigate = useNavigate()
  const getRelation = async (id: string) => {
    const result = await getRelationApi(id)

    if (result.success) {
      dispatch(updateRelationRedux(result.data))
    }

    return result.success
  }

  const deleteRelation = async (relation: IRelation) => {
    const result = await deleteRelationApi(relation.id)

    if (result.success) {
      dispatch(deleteRelationRedux(relation.id))
    }

    return result.success
  }

  const switchRelation = async (relation: IRelation) => {
    dispatch(selectRelation(relation))

    const promises = [getPolicies(), getAgency(relation.agencyId)]

    await Promise.all(promises)
  }

  const updateEmailAddress = async (
    email: string,
    password: string,
    isRelationMutation: boolean
  ) => {
    dispatch(setRelationIsLoading(true))
    try {
      const result = await updateEmailAddressAPI(currentRelation!.id, {
        agencyId: agencyId!,
        isRelationMutation: isRelationMutation,
        newEmail: email,
        newEmailRepeat: email,
        password: password
      })

      if (!result.success) {
        console.warn(result.data)
      } else {
        dispatch(setToken(result.data.token))
        navigate('/mijn-profiel')
      }

      return result.success
    } finally {
      dispatch(setRelationIsLoading(false))
    }
  }

  const updateRelation = async (
    id: string,
    relation: IRelation,
    updates: Partial<IRelation>
  ) => {
    dispatch(setRelationIsLoading(true))
    try {
      const result = await updateRelationApi(id, relation, updates)

      if (result.success) {
        dispatch(setCurrentRelation(result.data))
      }

      return result
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      dispatch(setRelationIsLoading(false))
    }
  }

  const updateRelationPreferences = async (
    id: string,
    updates: Partial<IRelation>
  ) => {
    const result = await updateRelationPreferencesAPI(id, updates)

    if (result.success) {
      dispatch(setCurrentRelation(result.data))
    }

    return result
  }

  const getRelationUsers = async (id: string) => {
    const result = await getRelationUsersApi(id)

    if (result.success) {
      dispatch(setRelationUsers(result.data))
    }

    return result
  }

  const addRelationUser = async (
    id: string,
    user: Pick<
      IUser,
      'email' | 'firstName' | 'initials' | 'dateOfBirth' | 'lastName'
    >
  ) => {
    try {
      const result = await addRelationUserAPI(id, user)

      if (result.success) {
        dispatch(setRelationUser(result.data))
      }

      return result
    } catch (error) {
      throw error
    }
  }

  const addRelationInvite = async (addCombiNumber: string, email: string) => {
    const result = await addRelationInviteApi(addCombiNumber, email)

    return result.success
  }

  const updateRelationUser = async (
    userId: IUser['id'],
    updates: Partial<IUser>
  ) => {
    try {
      const result = await updateRelationUserAPI(
        currentRelation!.id,
        userId,
        updates
      )

      if (result.success) {
        dispatch(
          updateUserByProperty({
            id: userId,
            ...updates
          })
        )

        getUser(relation!.id, userId)
      }

      return result
    } catch (error) {
      throw error
    }
  }

  const deleteRelationUser = async (userId: IUser['id']) => {
    const result = await deleteRelationUserAPI(currentRelation!.id, userId)

    if (result.success) {
      dispatch(deleteRelationUserFromState(userId))
    }

    return result
  }

  return {
    getRelation,
    updateRelation,
    deleteRelation,
    switchRelation,
    updateEmailAddress,
    getRelationUsers,
    addRelationUser,
    addRelationInvite,
    updateRelationUser,
    deleteRelationUser,
    updateRelationPreferences
  }
}

export default useRelation
