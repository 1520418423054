import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IUser } from '../../models/user'
import { logout } from '../actions/constants'

export interface IUserState {
  user: IUser | null
  showFirstUse: boolean
  checkEmailStatus?:
    | 'activated'
    | 'multi_relations'
    | 'not_activated'
    | 'not_activated_old'
    | 'not_found'
}

const initialState: IUserState = {
  user: null,
  showFirstUse: true,
  checkEmailStatus: undefined
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
    },
    setShowFirstUse: (state, action: PayloadAction<boolean>) => {
      state.showFirstUse = action.payload
    },
    setToken: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.token = action.payload
      }
    },
    setCheckEmailStatus: (
      state,
      action: PayloadAction<IUserState['checkEmailStatus']>
    ) => {
      state.checkEmailStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => ({
      ...initialState,
      showFirstUse: state.showFirstUse
    }))
  }
})

export const { setUser, setShowFirstUse, setToken, setCheckEmailStatus } =
  userSlice.actions

export default userSlice.reducer
