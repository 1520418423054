import React, { FC, PropsWithChildren, useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'
import RegisterScreen from '../screens/login/register'
import ForgotPasswordScreen from '../screens/login/forgot_password'
import NotFound from '../screens/not_found/not_found'
import IdinRegisterScreen from '../screens/login/idin_register'
import CaseScreen from '../screens/case/case_screen'
import PoliciesScreen from '../screens/policies/policies_screen/policies_screen'
import MemberBenefitScreen from '../screens/member_benefit/member_benefit_screen'
import AdvisorScreen from '../screens/advisor/advisor_screen'
import MoreScreen from '../screens/more/more_screen'
import ClaimDamageScreen from '../screens/claim_damage/ClaimDamageScreen'
import NotificationsScreen from '../screens/notifications/notifications_screen'
import ProfileScreen from '../screens/profile/profile_screen/profile_screen'
import NotificationDetailScreen from '../screens/notifications/notification_detail_screen'
import { useSelector } from 'react-redux'
import { getUser } from '@verzekeringapp-mono/shared/src/state/selectors/user'
import PaymentsScreen from '../screens/case/payments/payments_screen'
import GreenCardsScreen from '../screens/case/green_cards/green_cards_screen'
import ClaimOverviewScreen from '../screens/case/claims/claim_overview_screen/claim_overview_screen'
import MemberBenefitDetailScreen from '../screens/member_benefit/member_benefit_detail_screen/member_benefit_detail_screen'
import AboutNH1816Screen from '../screens/more/detail_screens/about_nh1816_screen'
import AboutAppScreen from '../screens/more/detail_screens/about_app_screen'
import QuestionsScreen from '../screens/more/detail_screens/questions_screen/questions_screen'
import PreventionTipCategoriesScreen from '../screens/more/detail_screens/prevention_tips/prevention_tip_categories_screen'
import PreventionTipScreen from '../screens/more/detail_screens/prevention_tips/prevention_tip_screen'
import TermsOfUseScreen from '../screens/more/detail_screens/terms_of_use_screen'
import ResetPasswordScreen from '../screens/login/reset_password'
import ClaimDetailScreen from '../screens/case/claims/claim_detail_screen'
import SelectCombiPolicyScreen from '../screens/profile/select_combi_policy_screen/select_combi_policy_screen'
import HomeScreen from '../screens/home/home_screen'
import PolicyDetailScreen from '../screens/policies/policy_detail_screen/policy_detail_screen'
import PolicyAdvice from '../screens/policies/policy_advice'
import PolicyAdviceRequested from '../screens/policies/policy_advice_requested'
import ManageLoginScreen from '../screens/profile/manage_login/manage_login_screen'
import PreferencesScreen from '../screens/profile/preferences_screen'
import ManageRelationScreen from '../screens/profile/manage_relation/manage_relation_screen'
import ManageMembersScreen from '../screens/profile/manage_members/manage_members_screen'
import EditMemberScreen from '../screens/profile/manage_members/edit_member_screen'
import InviteMemberScreen from '../screens/profile/manage_members/invite_member_screen'
import DamageRepairVehicleScreen from '../screens/damagerepair/DamageRepairVehicleScreen'
import AddCombiPolicyScreen from '../screens/profile/add_combi_policy_screen/add_combi_policy_screen'
import WhatToDoScreen from '../screens/what_to_do/what_to_do_screen'
import LoginScreen from '../screens/login/login'
import ClaimDamageFormScreen from '../screens/claim_damage/claim_damage_form_screen'
import ValueMetersScreen from '../screens/case/valuemeters/ValueMetersScreen'
import RebuildValueMeterScreen from '../screens/case/valuemeters/RebuildValueMeterScreen'
import HouseHoldEffectsScreen from '../screens/case/valuemeters/HouseHoldEffectsScreen'
import CombiPolicyDetailScreen from '../screens/profile/combi_policy_details_screen/CombiPolicyDetailsScreen'
import GenerateGreenCard from '../screens/policies/GenerateGreenCard'
import {
  useAppDispatch,
  useAppSelector
} from '@verzekeringapp-mono/shared/src/hooks/state'
import { logout } from '@verzekeringapp-mono/shared/src/state/actions/constants'
import { Helmet } from 'react-helmet'
import RegisterWithToken from '../screens/login/RegisterWithToken'
import Setup from '../screens/login/Setup'

export const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const user = useSelector(getUser)
  const dispatch = useAppDispatch()
  const currentRelation = useAppSelector(
    state => state.relations.currentRelation
  )
  const location = useLocation()

  if (!user || new Date(user.tokenExpiresAt).getTime() < new Date().getTime()) {
    dispatch(logout())

    return <Navigate to='/home' />
  }

  if (
    (!user.termsRead || !currentRelation) &&
    location.pathname !== '/instellen'
  ) {
    return <Navigate to='/instellen' />
  }

  return children
}

function Navigator () {
  const navigate = useNavigate()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const action = urlParams.get('action')
    const resetPasswordToken = urlParams.get('resetPasswordToken')

    if (action === 'reset-password' && resetPasswordToken) {
      navigate(
        `/reset-password?resetPasswordToken=${resetPasswordToken}&action=reset-password`
      )
    }

    if (
      action === 'register' &&
      urlParams.get('registerToken') &&
      urlParams.get('email')
    ) {
      navigate(
        `/registration?action=register&registerToken=${urlParams.get(
          'registerToken'
        )}&email=${urlParams.get('email')}`
      )
    }
  }, [])

  return (
    <Routes>
      <Route
        path='/home'
        element={
          <>
            <Helmet>
              <title>Inloggen</title>
            </Helmet>

            <LoginScreen />
          </>
        }
      />

      <Route
        path='/register'
        element={
          <>
            <Helmet>
              <title>Registreren</title>
            </Helmet>

            <RegisterScreen />
          </>
        }
      />

      <Route
        path='/idin-register'
        element={
          <>
            <Helmet>
              <title>Login</title>
            </Helmet>

            <IdinRegisterScreen />
          </>
        }
      />

      <Route
        path='/forget-password'
        element={
          <>
            <Helmet>
              <title>Login</title>
            </Helmet>

            <ForgotPasswordScreen />
          </>
        }
      />

      <Route
        path='/reset-password'
        element={
          <>
            <Helmet>
              <title>Wachtwoord Resetten</title>
            </Helmet>

            <ResetPasswordScreen />
          </>
        }
      />

      <Route
        path='/registration'
        element={
          <>
            <Helmet>
              <title>Registreren</title>
            </Helmet>

            <RegisterWithToken />
          </>
        }
      />

      <Route
        path='/instellen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Instellen</title>
            </Helmet>

            <Setup />
          </ProtectedRoute>
        }
      />

      <Route
        path='/overzicht'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Overzicht</title>
            </Helmet>

            <HomeScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/dossier'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Dossier</title>
            </Helmet>

            <CaseScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/dossier/betalingsoverzicht'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Betalingsoverzicht</title>
            </Helmet>

            <PaymentsScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/dossier/verzekeringsbewijzen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Verzekeringsbewijzen</title>
            </Helmet>

            <GreenCardsScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/dossier/waardemeters'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Waardemeters</title>
            </Helmet>

            <ValueMetersScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/dossier/waardemeters/herbouwwaardemeter/:id'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Herbouwwaardemeter</title>
            </Helmet>

            <RebuildValueMeterScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/dossier/waardemeters/inboedelwaardemeter/:id'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Inboedelwaardemeter</title>
            </Helmet>

            <HouseHoldEffectsScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/dossier/mijn-schades'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Mijn schades</title>
            </Helmet>

            <ClaimOverviewScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/dossier/mijn-schades/:id'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Schade</title>
            </Helmet>

            <ClaimDetailScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/verzekeringen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Verzekeringen</title>
            </Helmet>

            <PoliciesScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/verzekeringsbewijzen/genereren/:id'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Verzekeringsbewijs genereren</title>
            </Helmet>

            <GenerateGreenCard />
          </ProtectedRoute>
        }
      />

      <Route
        path='/verzekeringen/combipolis'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Combipolis</title>
            </Helmet>

            <CombiPolicyDetailScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/verzekeringen/polis/:id'
        element={
          <ProtectedRoute>
            <PolicyDetailScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/verzekeringen/verzekeringsadvies'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Verzekeringsadvies toevoegen</title>
            </Helmet>

            <PolicyAdvice />
          </ProtectedRoute>
        }
      />

      <Route
        path='/verzekeringen/advies-aangevraagd'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Verzekeringsadvies aangevraagd</title>
            </Helmet>

            <PolicyAdviceRequested />
          </ProtectedRoute>
        }
      />

      <Route
        path='/ledenvoordelen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Ledenvoordelen</title>
            </Helmet>

            <MemberBenefitScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/ledenvoordelen/:id'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Ledenvoordeel</title>
            </Helmet>

            <MemberBenefitDetailScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/adviseur'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Adviseur</title>
            </Helmet>

            <AdvisorScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/wat-te-doen-bij/:id'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Wat te doen</title>
            </Helmet>

            <WhatToDoScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/meer'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Meer</title>
            </Helmet>

            <MoreScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/meer/nh1816-verzekeringen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Over Nh1816</title>
            </Helmet>

            <AboutNH1816Screen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/meer/over-de-app'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Over de app</title>
            </Helmet>

            <AboutAppScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/meer/preventietips'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Preventietips</title>
            </Helmet>

            <PreventionTipCategoriesScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/meer/preventietips/:id'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Preventietip</title>
            </Helmet>

            <PreventionTipScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/meer/vragen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Veelgestelde vragen</title>
            </Helmet>

            <QuestionsScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/meer/ledenvoordelen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Ledenvoordelen</title>
            </Helmet>

            <MemberBenefitScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/meer/gebruiksvoorwaarden'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Gebruiksvoorwaarden</title>
            </Helmet>

            <TermsOfUseScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/verzekeringsbewijzen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Verzekeringsbewijzen</title>
            </Helmet>

            <GreenCardsScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/schade-melden'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Schade melden</title>
            </Helmet>

            <ClaimDamageScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/schade-melden/schadeformulier'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Schadeformulier</title>
            </Helmet>

            <ClaimDamageFormScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/notificaties'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Notificaties</title>
            </Helmet>

            <NotificationsScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/notificaties/:id'
        element={
          <ProtectedRoute>
            <NotificationDetailScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mijn-profiel'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Profiel</title>
            </Helmet>

            <ProfileScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mijn-profiel/combipolis-selecteren'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Combipolis selecteren</title>
            </Helmet>

            <SelectCombiPolicyScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mijn-profiel/combipolis-toevoegen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Combipolis toevoegen</title>
            </Helmet>

            <AddCombiPolicyScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mijn-profiel/inloggegevens-wijzigen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Inloggegevens wijzigen</title>
            </Helmet>

            <ManageLoginScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mijn-profiel/voorkeuren'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Voorkeuren</title>
            </Helmet>

            <PreferencesScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mijn-profiel/gebruikers-beheren'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Gebruikers beheren</title>
            </Helmet>

            <ManageMembersScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mijn-profiel/gebruikers-beheren/:id/wijzigen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Gebruiker wijzigen</title>
            </Helmet>

            <EditMemberScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mijn-profiel/gebruikers-beheren/uitnodigen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Gebruiker toevoegen</title>
            </Helmet>

            <InviteMemberScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/mijn-profiel/gegevens-wijzigen'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Relatiegegevens wijzigen</title>
            </Helmet>

            <ManageRelationScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/schadehersteller/voertuigschade'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Voertuig schadeherstellers</title>
            </Helmet>

            <DamageRepairVehicleScreen />
          </ProtectedRoute>
        }
      />

      <Route
        path='/'
        element={
          <ProtectedRoute>
            <Helmet>
              <title>Overzicht</title>
            </Helmet>

            <Navigate to='/overzicht' />
          </ProtectedRoute>
        }
      />

      <Route
        path='*'
        element={<NotFound />}
      />
    </Routes>
  )
}

export default Navigator
