import React, { useEffect } from 'react'
import ScreenWrapper from '../../../../components/screen_wrappers/screen_wrapper'
import useTips from '@verzekeringapp-mono/shared/src/hooks/use_tips'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getTipCategories } from '@verzekeringapp-mono/shared/src/state/selectors/tips'
import NavigationTileGrid from '../../../../components/navigation_tile/navigation_tile_grid'

const PreventionTipCategoriesScreen: React.FC = () => {

  const { getTips } = useTips()

  const tipCategoriesTilesData = useAppSelector(getTipCategories)

  useEffect(() => {
    getTips()
  }, [])

  return (
    <ScreenWrapper>
      <NavigationTileGrid tiles={tipCategoriesTilesData} />
    </ScreenWrapper>
  )
}

export default PreventionTipCategoriesScreen
