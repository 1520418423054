import React, { useEffect } from 'react'
import ScreenWrapper from '../../../../components/screen_wrappers/screen_wrapper'
import useQuestions from '@verzekeringapp-mono/shared/src/hooks/use_questions'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getQuestions as getQuestionsSelector } from '@verzekeringapp-mono/shared/src/state/selectors/questions'
import QuestionToggle from './components/question_toggle'

const QuestionsScreen: React.FC = () => {

  const { getQuestions } = useQuestions()

  const questions = useAppSelector(getQuestionsSelector)

  useEffect(() => {
    getQuestions()
  }, [])

  return (
    <ScreenWrapper>
      <div className='mx-auto lg:w-8/12 md:w-10/12'>
        {questions.map((question) => (
          <QuestionToggle
            key={question.id}
            question={question}
          />
        ))}
      </div>
    </ScreenWrapper>
  )

}

export default QuestionsScreen
