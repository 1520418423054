import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'

const _getState = (state: RootState) => state.agency

export const getAgency = createSelector(
  _getState,
  (state) => (
    state.agency
  )
)

export const getMainLocation = createSelector(
  _getState,
  (state) => (
    state.agency?.locations.find((location) => location.mainLocation)
  )
)
