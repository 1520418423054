import React from 'react'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getGreencardPolicies } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import { productIcons } from '@verzekeringapp-mono/shared/src/utils/product_icons'
import { getLicensePlate } from '@verzekeringapp-mono/shared/src/utils/policy_utils'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import { useThemeColorContext } from '../../../providers/ThemeColorProvider'

const GreenCardsScreen: React.FC = () => {
  const policies = useAppSelector(getGreencardPolicies)!
  const { brandColors } = useThemeColorContext()

  return (
    <ScreenWrapper>
      <div>
        {policies.map((policy, index) => (
          <a
            key={index}
            className='bg-white rounded flex p-4 mb-4 justify-between items-center'
            href={policy.greenCard}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='flex items-center'>
              <div className='w-[50px] h-[50px] bg-lightBrand rounded-[25px] flex justify-center items-center mr-4'>
                <FontAwesomeIcon
                  icon={productIcons[policy.productId]}
                  size='lg'
                  color={brandColors.brand}
                />
              </div>

              <div>
                <p>{policy.productName}</p>

                <p className='text-textGray text-sm'>
                  Kenteken: {getLicensePlate(policy.details)}
                </p>

                <p className='text-textGray text-sm'>
                  Polisnummer: {policy.policyNumber}
                </p>
              </div>
            </div>

            <FontAwesomeIcon
              color={Colors.lightGray}
              icon={['fal', 'chevron-right']}
            />
          </a>
        ))}
      </div>
    </ScreenWrapper>
  )
}

export default GreenCardsScreen
