import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputHTMLAttributes } from 'react'

const TextInput = ({
  label,
  type,
  placeholder,
  shouldValidate = true,
  icon,
  isValid,
  showValidationIcon = true,
  className = '',
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  label?: string | null
  icon?: IconProp
  isValid?: boolean
  showValidationIcon?: boolean
  shouldValidate?: boolean
}) => (
  <>
    {label && <label className='label'>{label}</label>}

    <div className='relative w-full'>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className='text-textGray absolute top-[50%] transform -translate-y-1/2 left-4'
        />
      )}

      <input
        className={`textfield focus:border-gray border
          outline-none
          mt-4 mb-4 ${icon ? 'pl-10' : ''} ${
    !isValid && shouldValidate ? 'border-red' : 'border-lightGray '
  }
          ${className}
  
  `}
        type={type}
        placeholder={placeholder}
        {...props}
      />

      {!isValid && showValidationIcon && (
        <FontAwesomeIcon
          icon='exclamation-circle'
          className='text-red absolute top-[50%] transform -translate-y-1/2 right-4'
        />
      )}
    </div>
  </>
)

export default TextInput
