import React from 'react'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { IUser } from '@verzekeringapp-mono/shared/src/models/user'
import Button from '../../../../components/Button'

type Props = Partial<
  Pick<IUser, 'email' | 'firstName' | 'initials' | 'lastName' | 'dateOfBirth'>
> & {
  onSubmit: (data: {
    email: string
    firstName: string
    initials: string
    lastName: string
    dateOfBirth: string
  }) => void
  formType: 'create' | 'edit'
  isLoading?: boolean
  error?: string | null
}

const MemberForm = ({
  email,
  firstName,
  initials,
  lastName,
  dateOfBirth,
  onSubmit,
  formType,
  isLoading,
  error
}: Props) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email,
      firstName,
      initials,
      lastName,
      dateOfBirth: dateOfBirth
        ? format(new Date(dateOfBirth), 'yyyy-MM-dd')
        : ''
    }
  })

  return (
    <div className='bg-white p-8 rounded h-full flex flex-col gap-4'>
      <div className='flex flex-col lg:w-1/2 w-full'>
        <form
          className='flex flex-col gap-8'
          onSubmit={handleSubmit(data =>
            onSubmit({
              email: data.email || '',
              firstName: data.firstName || '',
              initials: data.initials || '',
              lastName: data.lastName || '',
              dateOfBirth: data.dateOfBirth
            })
          )}
        >
          {formType === 'create' && (
            <div>
              <label
                htmlFor='email'
                className='label mt-4'
              >
                E-mailadres
              </label>

              <input
                className='textfield mt-2'
                type='email'
                id='email'
                placeholder='E-mailadres'
                {...register('email', { required: false })}
              />
            </div>
          )}

          <div>
            <label
              htmlFor='firstName'
              className='label mt-4'
            >
              Voornaam
            </label>

            <input
              className='textfield mt-2'
              type='text'
              id='firstName'
              placeholder='Voornaam'
              {...register('firstName', { required: false })}
            />
          </div>

          <div>
            <label
              htmlFor='initials'
              className='label mt-4'
            >
              Voorletter(s)
            </label>

            <input
              className='textfield mt-2'
              type='text'
              id='initials'
              placeholder='Voorletter(s)'
              {...register('initials')}
            />
          </div>

          <div>
            <label
              htmlFor='tussenvoegsel'
              className='label mt-4'
            >
              Achternaam
            </label>

            <input
              className='textfield mt-2'
              type='text'
              id='lastName'
              placeholder='Achternaam'
              {...register('lastName', { required: false })}
            />
          </div>

          <div>
            <label
              htmlFor='birthdate'
              className='label mt-4'
            >
              Geboortedatum
            </label>

            <input
              className='textfield mt-2'
              type='date'
              id='birthdate'
              placeholder='Geboortedatum'
              {...register('dateOfBirth', { required: false })}
            />
          </div>

          {error && <p className='text-red'>{error}</p>}

          <Button
            type='submit'
            isLoading={isLoading}
            className={
              'rounded-md bg-brand text-white w-full hover:bg-opacity-80'
            }
          >
            {formType === 'create'
              ? (
                <p className=' text-white'>Gebruiker uitnodigen</p>
              )
              : (
                <p className=' text-white'>Wijzigingen opslaan</p>
              )}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default MemberForm
