import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'

interface IProps {
  errorText: string
}

const ErrorToast: React.FC<IProps> = (props) => (
  <div className='flex flex-row gap-4 items-center bg-red p-4 rounded-md mb-4'>
    <FontAwesomeIcon
      size='lg'
      icon='times-circle'
      color={Colors.white}
    />

    <p className='text-white'>{props.errorText}</p>
  </div>
)

export default ErrorToast
