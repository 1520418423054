import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { INavigationTileItem } from '@verzekeringapp-mono/shared/src/models/navigation_tile_item'
import React from 'react'
import { useThemeColorContext } from '../../../../providers/ThemeColorProvider'

interface IProps {
  data: INavigationTileItem
  onPress?: () => void
}

const ProfileNavigationButton: React.FC<IProps> = props => {
  const { onPress } = props
  const { brandColors } = useThemeColorContext()

  return (
    <>
      {onPress
        ? (
          <button
            className='flex flex-row items-center h-[60px] w-full bg-white mb-4 gap-4 rounded-md'
            onClick={onPress}
          >
            {props.data.icon !== 'nh' && (
              <div className='w-[34px] h-[34px] bg-lightBrand rounded-[17px] flex justify-center items-center ml-4'>
                <FontAwesomeIcon
                  icon={props.data.icon}
                  color={brandColors.brand}
                />
              </div>
            )}

            <p className='text-base text-gray'>{props.data.title}</p>
          </button>
        )
        : (
          <a
            className='flex flex-row items-center h-[60px] w-full bg-white mb-4 gap-4 rounded-md'
            href={props.data.href}
          >
            {props.data.icon !== 'nh' && (
              <div className='w-[34px] h-[34px] bg-lightBrand rounded-[17px] flex justify-center items-center ml-4'>
                <FontAwesomeIcon
                  icon={props.data.icon}
                  color={brandColors.brand}
                />
              </div>
            )}

            <p className='text-base text-gray'>{props.data.title}</p>
          </a>
        )}
    </>
  )
}

export default ProfileNavigationButton
