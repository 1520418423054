import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getValueMeterPolicies } from '@verzekeringapp-mono/shared/src/state/selectors/valuemeters'
import ValueMeterLink from '../../../components/ValueMeterLink'

const ValueMetersScreen = () => {
  const valueMeterPolicies = useAppSelector(getValueMeterPolicies)

  return (
    <ScreenWrapper>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
        {valueMeterPolicies.map(policy => (
          <ValueMeterLink
            key={policy.id}
            policy={policy}
          />
        ))}
      </div>
    </ScreenWrapper>
  )
}

export default ValueMetersScreen
