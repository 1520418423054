import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import { productIcons } from '@verzekeringapp-mono/shared/src/utils/product_icons'
import { IPolicy } from '@verzekeringapp-mono/shared/src/models/policy'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '@verzekeringapp-mono/shared/src/hooks/state'
import { setSelectedPolicyNumber } from '@verzekeringapp-mono/shared/src/state/slices/policies_slice'
import { determinePolicyDistinctionLabel } from '@verzekeringapp-mono/shared/src/utils/policy_utils'
import { useThemeColorContext } from '../../../providers/ThemeColorProvider'

interface IProps
  extends Pick<
    IPolicy,
    'productId' | 'productName' | 'policyNumber' | 'details' | 'id'
  > {}

const PolicyCard = ({
  productName,
  details,
  productId,
  id,
  policyNumber
}: IProps) => {
  const dispatch = useAppDispatch()
  const { brandColors } = useThemeColorContext()

  return (
    <Link
      key={id}
      className='rounded-md p-4 mb-4 cursor-pointer border flex-1 flex border-lightGray'
      to={'/schade-melden/schadeformulier'}
      onClick={() => {
        dispatch(setSelectedPolicyNumber(policyNumber))
      }}
    >
      <div className='flex items-center justify-between flex-1'>
        <div className='flex flex-row gap-4 items-center'>
          <div className='w-[50px] h-[50px] bg-lightBrand rounded-full flex justify-center items-center'>
            <FontAwesomeIcon
              icon={productIcons[productId]}
              size='lg'
              color={brandColors.brand}
            />
          </div>

          <div>
            <p className='text-gray-800 font-medium'>{productName}</p>

            <small className='text-textGray'>
              {determinePolicyDistinctionLabel({
                details,
                productId,
                policyNumber
              })}
            </small>
          </div>
        </div>

        <div>
          <FontAwesomeIcon
            icon={['fal', 'chevron-right']}
            size='lg'
            color={Colors.gray}
          />
        </div>
      </div>
    </Link>
  )
}

export default PolicyCard
