import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import {
  getPoliciesExpelled,
  getPoliciesUnexpelled
} from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import PolicyRow from './components/policy_row'
import MyPoliciesHeader from '../../../components/MyPoliciesHeader'
import usePolicies from '@verzekeringapp-mono/shared/src/hooks/use_policies'
import IllustrationPolicies from '../../../components/illustrations/IllustrationPolicies'
import { useThemeColorContext } from '../../../providers/ThemeColorProvider'

const PoliciesScreen: React.FC = () => {
  const policiesUnexpelled = useAppSelector(getPoliciesUnexpelled)
  const policiesExpelled = useAppSelector(getPoliciesExpelled)
  const [showExpelled, setShowExpelled] = React.useState(false)
  const { getPolicies: getPoliciesFromApi } = usePolicies()
  const { brandColors } = useThemeColorContext()

  React.useEffect(() => {
    getPoliciesFromApi()
  }, [])

  return (
    <ScreenWrapper hasPadding={false}>
      <div className='flex flex-row justify-between items-center'>
        <div className='w-full md:w-3/6 md:mb-8 bg-white md:bg-inherit'>
          <MyPoliciesHeader />
        </div>

        <IllustrationPolicies className='text-brand hidden md:flex' />
      </div>

      <div className='md:pb-8 pr-8 md:pr-0 pb-0 relative flex justify-end md:justify-center'>
        <a
          className='h-[50px] w-[50px] rounded-full bg-brand  flex justify-center items-center -mt-[25px] md:mt-0'
          href='/verzekeringen/verzekeringsadvies'
        >
          <FontAwesomeIcon
            icon='plus'
            size='lg'
            color={Colors.white}
          />
        </a>
      </div>

      <div className='p-8 md:p-0'>
        {policiesUnexpelled.map(policy => (
          <PolicyRow
            policy={policy}
            key={policy.id}
          />
        ))}

        {policiesExpelled.length > 0 && (
          <div className='flex flex-row justify-center items-center my-8'>
            <button
              onClick={() => setShowExpelled(!showExpelled)}
              className='font-medium text-sm bg-white flex items-center justify-center px-4 py-2 rounded-md'
            >
              Toon de beëindigde polissen
              <div className='w-[40px] h-[40px] bg-lightBrand rounded-[25px] flex justify-center items-center ml-4'>
                <FontAwesomeIcon
                  icon={['fal', 'arrow-down']}
                  size='sm'
                  color={brandColors.brand}
                />
              </div>
            </button>
          </div>
        )}
      </div>

      {showExpelled &&
        policiesExpelled.map(policy => (
          <PolicyRow
            policy={policy}
            key={policy.id}
          />
        ))}

      <a
        className='flex flex-row gap-4 align-center bg-white rounded p-4'
        href='/verzekeringen/verzekeringsadvies'
      >
        <div className='w-[50px] h-[50px] bg-lightBrand rounded-[25px] flex justify-center items-center mr-4'>
          <FontAwesomeIcon
            icon='plus'
            size='lg'
            color={brandColors.brand}
          />
        </div>

        <div className='flex items-center'>
          <p>Verzekering(en) toevoegen</p>
        </div>
      </a>
    </ScreenWrapper>
  )
}

export default PoliciesScreen
