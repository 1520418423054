export interface IHelpQuestion {
  answer: string
  id: string
  text: string
}

export const getHelpQuestions = (): IHelpQuestion[] => [
  {
    answer:
      'Neem contact op met uw verzekeringsadviseur. Hij kan achterhalen via welk e-mailadres u geregistreerd bent.',
    id: '1',
    text: 'Ik ben het e-mailadres vergeten waarop ik inlog. Wat moet ik doen?'
  },
  {
    answer:
      'iDIN is dé veilige online identificatie- en inlogmethode ontwikkeld door banken. Deze methode wordt ook gebruikt door allerlei overheidsinstanties en webwinkels. Met iDIN gebruikt u de veilige en vertrouwde inlogmethode van uw bank. Zo hoeft u veel minder gebruikersnamen en wachtwoorden te onthouden.',
    id: '2',
    text: 'Wat is iDIN?'
  },
  {
    answer:
      'Nee. U kunt alleen een Mijn Polissen account aanvragen als er Nh1816 Verzekeringen op uw naam staan.',
    id: '3',
    text: 'Kan iedereen een account aanmaken?'
  },
  {
    answer:
      'De hoofdgebruiker kan binnen de VerzekeringApp en Mijn Polissen omgeving een sub-gebruiker aanmaken. Dit doet hij/zij bij de instellingen. Sub-gebruikers kunnen middels een eigen inlog verzekeringen inzien en bijvoorbeeld het Verzekeringsbewijs raadplegen.',
    id: '4',
    text: 'Mijn verzekeringen lopen via een ander gezinslid. Hoe kan ik deze verzekeringen zelf inzien?'
  }
]
