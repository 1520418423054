import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

const breadcrumbMap = {
  polis: 'Verzekering'
}

const Breadcrumbs: FC<{
  breadCrumbsOverride?: {
    path: string
    label: string
  }[]
  hasPadding?: boolean
}> = ({ breadCrumbsOverride, hasPadding }) => {
  const location = useLocation()

  const pathSegments = location.pathname
    .split('/')
    .filter(Boolean)
    .filter(segment => !/^\d+$/.test(segment))

  const breadcrumbs =
    breadCrumbsOverride ??
    useMemo(
      () =>
        pathSegments.map((segment, index) => {
          const path = `/${pathSegments.slice(0, index + 1).join('/')}`

          return {
            path,
            label: (segment.charAt(0).toUpperCase() + segment.slice(1))
              .replaceAll('-', ' ')
              .replaceAll(/\d+/g, '')
              .replace(
                new RegExp(
                  `\\b(${Object.keys(breadcrumbMap).join('|')})\\b`,
                  'gi'
                ),
                matched =>
                  breadcrumbMap[
                    matched.toLowerCase() as keyof typeof breadcrumbMap
                  ]
              )
          }
        }),
      [pathSegments, document.title]
    )

  if (breadcrumbs.length === 0) {
    return null
  }

  return (
    <div
      className={`py-4 ${hasPadding ? '' : 'px-4'}
    ${hasPadding ? '' : 'bg-white md:bg-inherit'}
    `}
    >
      <nav aria-label='breadcrumb'>
        <ol className='flex text-sm flex-wrap gap-2 items-center'>
          <li>
            <FontAwesomeIcon icon={['fal', 'home']} />
          </li>

          <FontAwesomeIcon
            size='sm'
            icon={['fal', 'chevron-right']}
          />

          {breadcrumbs.map((crumb, index) => (
            <>
              <li
                key={index}
                className={`breadcrumb-item ${
                  index === breadcrumbs.length - 1 ? 'active' : ''
                }`}
                aria-current={
                  index === breadcrumbs.length - 1 ? 'page' : undefined
                }
              >
                {index === breadcrumbs.length - 1
                  ? (
                    <span className='text-brand'>{crumb.label}</span>
                  )
                  : (
                    <Link to={crumb.path}>{crumb.label}</Link>
                  )}
              </li>

              {index !== breadcrumbs.length - 1 && (
                <FontAwesomeIcon
                  size='sm'
                  icon={['fal', 'chevron-right']}
                />
              )}
            </>
          ))}
        </ol>
      </nav>
    </div>
  )
}

export default Breadcrumbs
