import React from 'react'
import useOutsideClick from '../hooks/useOutsideClick'

interface IProps extends React.PropsWithChildren {
  onClose: () => void
  modalIcon?: React.ReactNode
}

const Modal = ({ onClose, modalIcon, children }: IProps) => {
  const ref = useOutsideClick(() => onClose())

  return (
    <div className='modal z-10'>
      <div
        className='relative max-h-3/4 overflow-y-scroll flex flex-col justify-center items-center md:w-2/3 w-full'
        ref={ref}
      >
        {modalIcon}

        <div className='modal-content '>{children}</div>
      </div>
    </div>
  )
}

export default Modal
