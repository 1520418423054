import { add } from 'date-fns'
import { IUser } from '../models/user'

const getUserStatus = (user: IUser): 'active' | 'pending' | 'expired' => {
  if (user.active) return 'active'
  if (
    user.passwordResetTokens?.filter((item) => {
      const datePlusDay = add(new Date(item.createdAt), { days: 1 })
      const now = new Date()

      return item.valid && datePlusDay >= now
    }).length > 0
  )
    return 'pending'

  return 'expired'
}

export default getUserStatus
