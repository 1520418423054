import React, { useEffect } from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getNotification } from '@verzekeringapp-mono/shared/src/state/selectors/notifications'
import { useParams } from 'react-router-dom'
import useNotification from '@verzekeringapp-mono/shared/src/hooks/use_notification'

const NotificationDetailScreen: React.FC = () => {
  const { id } = useParams()
  const notification = useAppSelector(getNotification(id ?? ''))!
  const { readNotification } = useNotification()

  useEffect(() => {
    setRead()
  }, [])

  const setRead = () => {
    if (notification?.read === false) {
      readNotification(notification?.messageId)
    }
  }

  return (
    <ScreenWrapper
      breadCrumbsOverride={[
        {
          path: '/notificaties',
          label: 'Notificaties'
        },
        {
          path: `/notificaties/${notification.messageId}`,
          label: 'Notificatie'
        }
      ]}
    >
      <div className='bg-white p-8 rounded h-full'>
        <p className='descriptionBold'>{notification.title}</p>

        <div
          className='mt-2 html-content'
          dangerouslySetInnerHTML={{ __html: notification.message }}
        />

        <p className='mt-2 paragraph-light'>{notification.createdAt}</p>
      </div>
    </ScreenWrapper>
  )
}

export default NotificationDetailScreen
