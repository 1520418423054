import humps from 'humps'
import { store } from '../state/configure_store'
import { logout } from '../state/actions/constants'
import * as Sentry from '@sentry/react'
type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'

export interface IApiResponse<T> {
  success: boolean
  status: number
  data: T
  error: string
  message?: string
}

export enum BaseUrl {
  Betty,
  Claim,
  File,
}

const dispatch = store.dispatch

const Api = async <T, B = Record<string, unknown>>(
  method: Method,
  url: string,
  params?: B,
  api: BaseUrl = BaseUrl.Betty
): Promise<IApiResponse<T>> => {
  const baseUrl = (() => {
    switch (api) {
    case BaseUrl.Betty:
      return process.env.REACT_APP_BETTY_API_URL
    case BaseUrl.Claim:
      return process.env.REACT_APP_CLAIM_API_URL
    case BaseUrl.File:
      return ''
    default:
      throw new Error('Invalid BaseUrl')
    }
  })()

  const apiUrl = `${baseUrl}${url}`

  const fullParams = {
    ...params,
    token: store.getState().user.user?.token,
    userId: store.getState().user.user?.id,
    environment: 'web',
    appVersion: '2.0.0',
    relationId: store.getState().relations.currentRelation?.id,
    relationNumber: store.getState().relations.currentRelation?.relationNumber
  }

  const snakeCaseParams = humps.decamelizeKeys(fullParams)
  const queryParams =
    method === 'GET' && snakeCaseParams
      ? `?${new URLSearchParams(
          snakeCaseParams as Record<string, string>
      ).toString()}`
      : ''

  try {
    const response = await fetch(`${apiUrl}${queryParams}`, {
      method,
      body: method !== 'GET' ? JSON.stringify(snakeCaseParams) : undefined,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    const jsonResponse = await response.json()

    if (jsonResponse.status_code === 401) {
      dispatch(logout())
    }
    const data = jsonResponse.data
    const camelCaseData = humps.camelizeKeys(data) as T

    return {
      success: response.ok,
      status: response.status,
      data: camelCaseData,
      error: '',
      message: jsonResponse.message
    }
  } catch (error) {
    let errorMessage = 'Er is iets misgegaan. Probeer het later opnieuw.'

    if (error instanceof Error) {
      errorMessage = error.message
    } else if (typeof error === 'string') {
      errorMessage = error
    }

    Sentry.captureException(error, {
      extra: {
        method,
        url: apiUrl,
        params: fullParams
      }
    })

    return {
      success: false,
      status: 500,
      data: {} as T,
      error: errorMessage,
      message: ''
    }
  }
}

export default Api
