import { IPolicy } from '@verzekeringapp-mono/shared/src/models/policy'
import React from 'react'

interface IProps {
  policy: IPolicy
}

const PolicyConditions: React.FC<IProps> = ({ policy }) => (
  <>
    {policy.conditions && policy.conditions.length > 0 && (
      <div className='flex flex-col gap-4'>
        <p className='subtitle'>Polisvoorwaarden</p>

        {policy.conditions.map((condition, index) => (
          <button
            key={index}
            className='primary-btn md:w-[500px]'
            onClick={() => window.open(condition.url, '_blank')}
          >
            {condition.description}
          </button>
        ))}
      </div>
    )}
  </>
)

export default PolicyConditions
