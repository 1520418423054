import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'

const _getState = (state: RootState) => state.notifications

export const getNotifications = createSelector(_getState, (state) => [...state.notifications].sort((a, b) =>
  new Date(b.createdAt).getTime() > new Date(a.createdAt).getTime() ? 1 : -1
))

export const getNotification = (messageId: string) =>
  createSelector(_getState, (state) =>
    state.notifications.find(
      (notification) => `${notification.messageId}` === messageId
    )
  )
