import { IClaim } from '../models/claim'
import convertToBase64 from '../utils/convertToBase64'
import Api, { BaseUrl } from './index'

export const getClaims = () => Api<IClaim[]>('GET', 'claims', {}, BaseUrl.Claim)

export const getClaim = (claimHash: string) =>
  Api<IClaim>('GET', `claims/${claimHash}`, {}, BaseUrl.Claim)

export const deleteClaimConcept = (temporaryClaimReference: string) =>
  Api<IClaim>('DELETE', `claims/${temporaryClaimReference}`, {}, BaseUrl.Claim)

export const createClaimConcept = (
  claim: Pick<
    IClaim,
    'agent_number' | 'policyNumber' | 'relation_number' | 'branche'
  >
) =>
  Api<IClaim>(
    'POST',
    'claims/create',
    {
      ...claim
    },
    BaseUrl.Claim
  )

export const storeClaim = (
  claim: Pick<
    IClaim,
    'id' | 'policyNumber' | 'dateOfLoss' | 'text' | 'phoneNumber'
  >
) =>
  Api<IClaim>(
    'POST',
    `claims/${claim.id}`,
    {
      ...claim,
      telephoneNumber: claim.phoneNumber
    },
    BaseUrl.Claim
  )

export const updateClaimConcept = (claim: IClaim) =>
  Api<IClaim>(
    'PUT',
    `claims/${claim.temporaryClaimReference}`,
    {
      ...claim,
      telephoneNumber: claim.phoneNumber
    },
    BaseUrl.Claim
  )

export const uploadAdditionalClaimAttachment = async (id: string, file: File) =>
  Api<IClaim>(
    'POST',
    `claims/${id}/upload`,
    {
      file: await convertToBase64(file),
      name: file.name
    },
    BaseUrl.Claim
  )

export const removeAdditionalClaimAttachment = async (fileUrl: string) =>
  Api<IClaim>('DELETE', fileUrl, {}, BaseUrl.File)
