import { useAppDispatch } from './state'
import {
  getClaims as getClaimsAPI,
  getClaim as getClaimAPI,
  deleteClaimConcept,
  createClaimConcept as createClaimAPI,
  updateClaimConcept as updateClaimAPI,
  uploadAdditionalClaimAttachment as uploadAdditionalClaimAttachmentAPI,
  removeAdditionalClaimAttachment as removeAdditionalClaimAttachment,
  storeClaim as storeClaimAPI
} from '../api/claims'
import {
  setClaims,
  updateClaim,
  deleteClaim as deleteClaimRedux,
  setConceptClaim,
  setCurrentStep,
  resetClaimDamageForm,
  setLatestClaimId,
  setConceptIsLoading,
  setFileIsLoading
} from '../state/slices/claims_slice'
import { IClaim } from '../models/claim'
import * as Sentry from '@sentry/react'

export const useClaims = () => {
  const dispatch = useAppDispatch()

  const getClaims = async (): Promise<boolean> => {
    const result = await getClaimsAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }

    if (result.data) {
      dispatch(setClaims(result.data))
    }

    return result.success
  }

  const createClaimConcept = async (
    claim: Pick<
      IClaim,
      'agent_number' | 'relation_number' | 'branche' | 'policyNumber'
    >
  ) => {
    dispatch(setConceptIsLoading(true))
    const result = await createClaimAPI(claim)

    if (result.success) {
      dispatch(setConceptClaim(result.data))
    }

    dispatch(setConceptIsLoading(false))

    return result.data
  }

  const updateClaimConcept = async (claim: IClaim) => {
    dispatch(setConceptIsLoading(true))
    const result = await updateClaimAPI(claim)

    if (result.success) {
      dispatch(setConceptClaim(result.data))
    }

    dispatch(setConceptIsLoading(false))

    return result.data
  }

  const storeClaim = async (
    claim: Pick<
      IClaim,
      'id' | 'policyNumber' | 'dateOfLoss' | 'text' | 'phoneNumber'
    >
  ) => {
    const result = await storeClaimAPI(claim)

    if (result.success) {
      dispatch(updateClaim(result.data))
      dispatch(setConceptClaim(null))
      dispatch(setCurrentStep(3))
      dispatch(resetClaimDamageForm())
      dispatch(setLatestClaimId(result.data.id))
    }

    return result.data
  }

  const getClaim = async (id: string) => {
    const result = await getClaimAPI(id)

    if (result.success) {
      dispatch(updateClaim(result.data))
    }

    return result
  }

  const deleteClaim = async (id: string) => {
    const result = await deleteClaimConcept(id)

    if (result.success) {
      dispatch(deleteClaimRedux(id))
    }

    return result.success
  }

  const uploadClaimAttachment = async (claimId: string, file: File) => {
    dispatch(setFileIsLoading(true))
    const result = await uploadAdditionalClaimAttachmentAPI(claimId, file)

    dispatch(setFileIsLoading(false))
    if (result.success) {
      const claim = await getClaim(claimId)

      if (claim.data.status === 'Concept') {
        dispatch(setConceptClaim(claim.data))
      }
    }

    dispatch(setFileIsLoading(false))

    return result.data
  }

  const deleteClaimAttachment = async (fileUrl: string, claimId: string) => {
    const result = await removeAdditionalClaimAttachment(fileUrl)

    if (result.success) {
      const claim = await getClaim(claimId)

      if (claim.data.status === 'Concept') {
        dispatch(setConceptClaim(claim.data))
      }
    }

    return result.success
  }

  return {
    getClaims,
    getClaim,
    deleteClaim,
    createClaimConcept,
    uploadClaimAttachment,
    updateClaimConcept,
    deleteClaimAttachment,
    storeClaim
  }
}

export default useClaims
