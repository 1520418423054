import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import usePolicies from '@verzekeringapp-mono/shared/src/hooks/use_policies'
import { getPolicyById } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import { addYears, format } from 'date-fns'
import React, { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Button from '../../../components/Button'
import TextInput from '../../../components/TextInput'
import { RadioInput } from './HouseHoldEffectsForm'

const RebuildValueMeterForm = () => {
  const { id } = useParams<{
    id: string
  }>()

  const { postRebuildValueMeter } = usePolicies()
  const policy = useAppSelector(getPolicyById(id!))
  const valueMeterIsLoading = useAppSelector(
    state => state.policies.isLoadingValueMeter
  )

  const underInsuranceGuaranteeDate = policy?.details?.find(
    detail => detail.name === 'underinsurance_guarantee_date'
  )?.code

  const expireDate = underInsuranceGuaranteeDate
    ? addYears(new Date(underInsuranceGuaranteeDate), 5)
    : null

  const rebuildValue = policy?.details?.find(
    detail => detail.name === 'rebuilding_value'
  )?.value

  const form = useForm({
    defaultValues: {
      rebuildValue: rebuildValue,
      isRenovationCostsAbove5k: undefined,
      renovationCosts: 0,
      newRebuildValue: Number(rebuildValue)
    }
  })

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isValid }
  } = form
  const isRenovationCostsAbove5k = watch('isRenovationCostsAbove5k')

  useEffect(() => {
    setValue(
      'newRebuildValue',
      Number(watch('renovationCosts')) + Number(watch('rebuildValue'))
    )
  }, [watch('renovationCosts'), watch('rebuildValue')])

  return (
    <div className='bg-white p-8 rounded-lg w-full'>
      <div className='w-full lg:w-2/3'>
        <div>
          <p>
            {expireDate &&
              `Uw garantie tegen onderverzekering verloopt op ${format(
                expireDate,
                'dd-MM-yyyy'
              )}. Heeft u uw huis verbouwd?`}{' '}
            Vul onderstaand formulier in als de verbouwingskosten hoger waren
            dan € 5.000,- en de inhoud (m3) van uw woning groter is geworden
            door de verbouwing. Is dit niet het geval, dan hoeft u het formulier
            niet in te vullen.
          </p>
        </div>

        <FormProvider {...form}>
          <form
            className='flex flex-col gap-4 my-8'
            onSubmit={handleSubmit(data => {
              postRebuildValueMeter(id!, {
                renovationCost: data.renovationCosts.toString(),
                renovationCostAbove: data.isRenovationCostsAbove5k
                  ? 'Ja'
                  : 'Nee',
                policy_id: id!,
                rebuilding_value_new: data.newRebuildValue.toString()
              })
            })}
          >
            <div className='mb-4 flex flex-col'>
              <Controller
                name='rebuildValue'
                control={form.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label='Herbouwwaarde'
                    showValidationIcon={false}
                    icon='euro'
                    shouldValidate={false}
                    disabled
                    type='number'
                    placeholder='Herbouwwaarde'
                    id='rebuildValue'
                    isValid={!fieldState.invalid}
                    value={field.value?.toString()}
                  />
                )}
              />
            </div>

            <div className='mb-4 flex flex-col gap-4'>
              <label
                htmlFor='isRenovationCostsAbove5k'
                className='label'
              >
                Had u verbouwingskosten boven € 5.000,- en heeft uw huis nu een
                grotere inhoud (m3)?
              </label>

              <div className='flex flex-row gap-4'>
                <RadioInput
                  name='isRenovationCostsAbove5k'
                  options={[
                    { label: 'Ja', value: 'true' },
                    { label: 'Nee', value: 'false' }
                  ]}
                />
              </div>

              {isRenovationCostsAbove5k === 'false' && (
                <p className='my-2'>
                  U geeft aan geen verbouwingskosten boven de € 5000,- te hebben
                  gemaakt, waarbij de inhoud van uw woning is vergroot. Het is
                  daarom niet nodig om de Herbouwwaardemeter in te vullen.
                </p>
              )}
            </div>

            {watch('isRenovationCostsAbove5k') === 'true' && (
              <div className='mb-4 flex flex-col'>
                <Controller
                  name='renovationCosts'
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      label='Verbouwingskosten'
                      showValidationIcon={false}
                      icon='euro'
                      shouldValidate={false}
                      type='number'
                      placeholder='0,00'
                      id='renovationCosts'
                      isValid={!fieldState.invalid}
                      value={field.value?.toString()}
                    />
                  )}
                  rules={{
                    required: true,
                    min: 5000
                  }}
                />
              </div>
            )}

            <div className='mb-4 flex flex-col'>
              <Controller
                name='newRebuildValue'
                control={form.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label='Nieuwe herbouwwaarde'
                    showValidationIcon={false}
                    icon='euro'
                    disabled
                    shouldValidate={false}
                    type='number'
                    placeholder='Nieuwe herbouwwaarde'
                    id='newRebuildValue'
                    isValid={!fieldState.invalid}
                    value={field.value?.toString()}
                  />
                )}
              />
            </div>

            <Button
              type='submit'
              className='primary-btn w-full'
              disabled={!isValid}
              isLoading={valueMeterIsLoading}
            >
              Waardemeter indienen
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

export default RebuildValueMeterForm
