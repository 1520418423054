import React, { useEffect } from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { getAgencyId } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import {
  getAgency as getAgencySelector,
  getMainLocation
} from '@verzekeringapp-mono/shared/src/state/selectors/agency'
import { useAgency } from '@verzekeringapp-mono/shared/src/hooks/use_agency'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import ContactInfoButton from './components/contact_info_button'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import useGeocode from '@verzekeringapp-mono/shared/src/hooks/use_geocode'
import { NH_LAT_LNG } from '@verzekeringapp-mono/shared/src/state/constants/constants'
import SETTINGS from '@verzekeringapp-mono/shared/src/constants/settings'
import { renderOpeningHoursText } from '../../components/nav_bar/nav_bar'
import { useEmergencyModalContext } from '../../providers/EmergencyModalProvider'
import { parse } from 'date-fns'
import { useThemeColorContext } from '../../providers/ThemeColorProvider'

const AdvisorScreen: React.FC = () => {
  const { brandColors } = useThemeColorContext()
  const { open } = useEmergencyModalContext()
  const agencyId = useAppSelector(getAgencyId)!
  const agency = useAppSelector(getAgencySelector)
  const mainLocation = useAppSelector(getMainLocation)

  const [center, setCenter] = React.useState(NH_LAT_LNG)

  const { geocodeAsyncGoogleMaps } = useGeocode()

  useEffect(() => {
    if (mainLocation) {
      geocodeAsyncGoogleMaps(
        [mainLocation.street, mainLocation.houseNumber, mainLocation.city]
          .filter(Boolean)
          .join(' ')
      ).then(result => {
        if (result) {
          setCenter({
            lat: result.latitude,
            lng: result.longitude
          })
        }
      })
    }
  }, [agency])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
  })

  const { getAgency } = useAgency()

  useEffect(() => {
    getAgency(agencyId)
  }, [agencyId])

  if (!agency) {
    return (
      <ScreenWrapper hasPadding={false}>
        <div className='bg-white rounded p-8'>
          <p className='text-center text-textGray text-sm'>
            Er is iets misgegaan bij het ophalen van de gegevens van de
            adviseur. Probeer het later opnieuw.
          </p>
        </div>
      </ScreenWrapper>
    )
  }

  const currentDay = new Date().getDay()
  const currentDayIsOpen = agency.openingHours.find(
    openingHour => new Date().getDay() === openingHour.dayNumber
  )

  const currentHour = new Date().getHours()
  const currentHourIsOpen = agency.openingHours.find(
    openingHour =>
      new Date().getDay() === openingHour.dayNumber &&
      parse(openingHour.opening, 'HH:mm', new Date()).getHours() <=
        currentHour &&
      parse(openingHour.closing, 'HH:mm', new Date()).getHours
  )

  return (
    <ScreenWrapper hasPadding={false}>
      <div className='bg-white rounded p-8'>
        <p className='text-center text-textGray text-sm md:hidden'>
          {renderOpeningHoursText(agency.openingHours)}
        </p>

        <div className='flex items-center'>
          <div className='grid md:grid-cols-2 gap-8 mt-8 grid-cols-1'>
            <div className='flex flex-col-reverse md:flex-col'>
              <div>
                <p className='text-xl mb-4'>{agency.name}</p>

                <p className='mb-2 font-semibold'>Adres</p>

                <p>{`${agency?.street} ${agency?.houseNumber}${agency?.houseNumberAddition}`}</p>

                <p className='mb-6'>{`${agency?.zipCode} ${agency?.city}`}</p>
              </div>

              <div className='md:block flex justify-between md:justify-start'>
                <p className='mb-2 hidden md:block font-semibold'>Contact</p>

                <ContactInfoButton
                  circleColor={brandColors.brand}
                  description={mainLocation?.phoneNumber}
                  icon='phone'
                  title={'Bellen'}
                  href={`tel:${mainLocation?.phoneNumber}`}
                />

                <ContactInfoButton
                  circleColor={brandColors.brand}
                  description={mainLocation?.email}
                  icon='envelope'
                  title={'Bericht'}
                  href={`mailto:${mainLocation?.email}`}
                />

                <ContactInfoButton
                  circleColor={Colors.whatsapp}
                  description={mainLocation?.whatsapp}
                  icon={['fab', 'whatsapp']}
                  title={'WhatsApp'}
                  href={`https://wa.me/${mainLocation?.whatsapp}`}
                />

                <ContactInfoButton
                  circleColor={brandColors.brand}
                  icon={['fas', 'globe']}
                  title={'Website'}
                  description={agency.website}
                  href={
                    agency.website.startsWith('http')
                      ? agency.website
                      : `https://${agency.website}`
                  }
                />

                <ContactInfoButton
                  circleColor={Colors.darkRed}
                  description={SETTINGS.emergencyPhoneNumber}
                  descriptionColor={Colors.darkRed}
                  icon='phone-plus'
                  title={'Nood'}
                  onClick={() => {
                    open()
                  }}
                />
              </div>
            </div>

            <div>
              <p className='mb-2 font-semibold'>Openingstijden</p>

              {agency?.openingHours.map(openingHour => (
                <div
                  className='flex justify-between mb-2'
                  key={openingHour.day}
                >
                  <p
                    className={`${
                      currentDay === openingHour.dayNumber
                        ? currentDayIsOpen && currentHourIsOpen
                          ? 'text-green-700'
                          : 'text-red'
                        : ''
                    }`}
                  >
                    {openingHour.day}
                  </p>

                  <p
                    className={`${
                      currentDay === openingHour.dayNumber
                        ? currentDayIsOpen && currentHourIsOpen
                          ? 'text-green-700'
                          : 'text-red'
                        : ''
                    }`}
                  >
                    {openingHour.closed === 'true'
                      ? 'gesloten'
                      : `${openingHour.opening} tot ${openingHour.closing}`}
                  </p>
                </div>
              ))}

              <p className='paragraph-light'>
                Buiten kantooruren kunt u in gevallen van nood bellen met de
                alarmcentrale van Nh1816 op <br />

                <a
                  onClick={open}
                  className='text-darkRed'
                >
                  {SETTINGS.emergencyPhoneNumber}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className='w-full h-[300px] flex items-center justify-center bg-green-50 mt-8'>
          {isLoaded
            ? (
              <GoogleMap
                id='google-map-advisory-screen'
                mapContainerClassName='w-full h-full rounded-lg'
                options={{
                  fullscreenControl: false,
                  mapTypeControl: false
                }}
                center={center}
                zoom={10}
              >
                <MarkerF position={center} />
              </GoogleMap>
            )
            : (
              <p>Loading...</p>
            )}
        </div>
      </div>
    </ScreenWrapper>
  )
}

export default AdvisorScreen
