import React from 'react'
import { IPolicy } from '@verzekeringapp-mono/shared/src/models/policy'
import {
  convertDetailsToAttributes,
  detailAttributes
} from '@verzekeringapp-mono/shared/src/utils/policy_utils'

interface IProps {
  policy: IPolicy
}
const PolicyDetails: React.FC<IProps> = ({ policy }) => {
  const attributes = convertDetailsToAttributes(detailAttributes(policy))

  return (
    <div>
      <p className='subtitle mb-4'>Details</p>

      <div className='grid md:grid-cols-4 gap-4 grid-cols-2'>
        {attributes
          .sort((attributeA, attributeB) => {
            if (attributeA.followNumber === attributeB.followNumber) return 0

            return attributeA.followNumber < attributeB.followNumber ? -1 : 1
          })
          .map((attribute, index) => (
            <div key={index}>
              <p className='text-sm text-textGray'>{attribute.title}</p>

              <p>{attribute.description}</p>
            </div>
          ))}
      </div>
    </div>
  )
}

export default PolicyDetails
