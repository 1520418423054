import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useJsApiLoader, GoogleMap } from '@react-google-maps/api'
import { ICompany } from '@verzekeringapp-mono/shared/src/api/types/company'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getRepairersSelector } from '@verzekeringapp-mono/shared/src/state/selectors/companies'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import React from 'react'
import LocationCardModal from './LocationCardModal'
import LocationMarker from './LocationMarker'
import { NH_LAT_LNG } from '@verzekeringapp-mono/shared/src/state/constants/constants'

interface IMapViewProps {
  setIsFilterModalOpen: (isOpen: boolean) => void
  setMapRef: (map: google.maps.Map) => void
}

const MapView = ({ setIsFilterModalOpen, setMapRef }: IMapViewProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [center, setCenter] = React.useState(NH_LAT_LNG)
  const [selectedLocation, setSelectedLocation] =
    React.useState<ICompany | null>(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
  })

  const locations = useAppSelector(getRepairersSelector)

  return (
    <div className='w-full h-5/6 relative'>
      <div className='absolute top-4 left-4 p-4 rounded-md bg-white z-10'>
        {locations.length} resultaten
      </div>

      <button
        onClick={() => setIsFilterModalOpen(true)}
        className='absolute top-4 right-4 p-4 rounded-md bg-brand text-white z-10'
      >
        <FontAwesomeIcon
          icon='sliders'
          className='mr-2'
          color={Colors.white}
        />
        Filter
      </button>

      {isLoaded
        ? (
          <GoogleMap
            id='google-map-damage-repair-screen'
            mapContainerClassName='w-full h-full'
            onLoad={map => setMapRef(map)}
            options={{
              fullscreenControl: false,
              mapTypeControl: false
            }}
            center={center}
            zoom={9}
          >
            <>
              {locations.map(location => (
                <LocationMarker
                  openModal={location => {
                    setIsModalOpen(true)
                    setSelectedLocation(location)
                    setCenter({
                      lat: parseFloat(location.latitude),
                      lng: parseFloat(location.longitude)
                    })
                  }}
                  key={location.id}
                  location={location}
                />
              ))}

              {isModalOpen && !!selectedLocation && (
                <LocationCardModal
                  onClose={() => {
                    setIsModalOpen(false)
                    setSelectedLocation(null)
                  }}
                  location={selectedLocation}
                />
              )}
            </>
          </GoogleMap>
        )
        : (
          <p>Loading...</p>
        )}
    </div>
  )
}

export default MapView
