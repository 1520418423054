import React, { useEffect } from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { useMemberBenefits } from '@verzekeringapp-mono/shared/src/hooks/use_member_benefits'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getMemeberBenefits as getMemeberBenefitsFromSelector } from '@verzekeringapp-mono/shared/src/state/selectors/member_benefits'

const MemberBenefitScreen: React.FC = () => {
  const memberBenefits = useAppSelector(getMemeberBenefitsFromSelector)!
  const { getMemberBenefits } = useMemberBenefits()

  // TODO: - Implement screen if the member is no cooperation member
  useEffect(() => {
    getMemberBenefits()
  }, [])

  const memberBenefitLayout = () => (
    <div className='grid md:grid-cols-2 gap-4 lg:grid-cols-3'>
      {memberBenefits.map(memberBenefit => (
        <a
          key={memberBenefit.id}
          className='bg-white rounded p-4 flex flex-col justify-between'
          href={`/ledenvoordelen/${memberBenefit.id}`}
        >
          <div>
            {memberBenefit.image
              ? (
                <img
                  src={memberBenefit.image}
                  className='w-full h-[200px] object-cover mb-4'
                />
              )
              : (
                <div className='w-full h-[200px] bg-gray-200 mb-4' />
              )}

            <p>{memberBenefit.title}</p>

            <p className='mb-6 paragraph-light'>{memberBenefit.intro}</p>
          </div>

          <div className='mt-2 '>
            <button className={'primary-btn w-full'}>
              <p>Bekijken</p>
            </button>
          </div>
        </a>
      ))}
    </div>
  )

  return (
    <ScreenWrapper>
      {memberBenefits.length === 0
        ? (
          <p className='html-content'>
          Er zijn momenteel geen ledenvoordelen beschikbaar in de Mijn Omgeving.
          Klik op{' '}

            <a
              target='_blank'
              href='https://www.nh1816.nl/ledenvoordeel'
              rel='noreferrer'
            >
            nh1816.nl/ledenvoordeel
            </a>{' '}
          voor actuele ledenvoordelen.
          </p>
        )
        : (
          memberBenefitLayout()
        )}
    </ScreenWrapper>
  )
}

export default MemberBenefitScreen
