import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IRelation } from '@verzekeringapp-mono/shared/src/models/relation'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import React from 'react'
import { useThemeColorContext } from '../../../../providers/ThemeColorProvider'

interface IProps {
  relation: IRelation
  current?: boolean
  onPressSelect?: (relation: IRelation) => void
  onPressDelete?: (relation: IRelation) => void
}

const CombilPolicyListItem: React.FC<IProps> = ({
  relation,
  current,
  onPressSelect,
  onPressDelete
}) => {
  const { brandColors } = useThemeColorContext()

  return (
    <div
      className={`p-4 rounded-lg border ${
        current
          ? 'bg-lightSuccess border-success'
          : 'bg-lighterBrown border-lighterBrown'
      } flex flex-row items-center justify-between gap-1 flex-1 flex-`}
    >
      <button
        className='flex flex-1 items-center gap-1'
        onClick={() => onPressSelect?.(relation)}
        type='button'
      >
        <div
          className='h-[34px] w-[34px] rounded-full flex items-center justify-center mr-2 aspect-square'
          style={{ backgroundColor: `${current ? Colors.success : 'white'}` }}
        >
          <FontAwesomeIcon
            icon='user'
            size='sm'
            color={current ? 'white' : Colors.brown}
          />
        </div>

        <div className='flex flex-col items-start text-start'>
          <p>{`${relation.initials} ${relation.insertion} ${relation.lastName}`}</p>

          <p className='text-sm text-textGray'>{`Combipolisnummer: ${relation.combiNumber}`}</p>
        </div>
      </button>

      {current
        ? (
          <FontAwesomeIcon
            icon='check-circle'
            size='sm'
            color={Colors.success}
          />
        )
        : (
          onPressDelete && (
            <button onClick={() => onPressDelete?.(relation)}>
              <FontAwesomeIcon
                icon='trash'
                size='sm'
                color={brandColors.brand}
              />
            </button>
          )
        )}
    </div>
  )
}

export default CombilPolicyListItem
