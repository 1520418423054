import * as React from 'react'
import { SVGProps } from 'react'
const LogoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={120}
    height={98}
    viewBox='0 0 120 98'
    {...props}
  >
    <path
      fill='currentColor'
      fillRule='nonzero'
      d='M97.14 14.67c-9.41.54-15.54 5.21-19.83 10.34v-25H55v41.58L24.72 0H0v97.89l25.92-7.42V40.73l32.75 40.32 18.64-5.35V42.64c.47-5.77 4.47-9.3 9.76-10 5.64-.7 9.45 2 9.45 8V70.2l23.19-6.65V31.47c0-11.18-8.46-17.61-22.57-16.8Z'
    />
  </svg>
)

export default LogoSVG
