import React from 'react'
import { getHelpQuestions } from '@verzekeringapp-mono/shared/src/models/help_question'
import HelpQuestionToggle from '../../../components/help_question_toggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IProps {
  onClose: () => void
}

const HelpModal: React.FC<IProps> = (props) => (
  <div
    className='modal'
  >
    <div className='modal-content relative'>
      <p className='title text-center'>Help</p>

      <button
        className='absolute top-4 right-4'
        onClick={props.onClose}
      >
        <FontAwesomeIcon icon={['fal', 'times']}/>
      </button>

      {getHelpQuestions().map((question) => (
        <HelpQuestionToggle
          key={question.id}
          question={question}
        />
      ))}
    </div>
  </div>
)

export default HelpModal
