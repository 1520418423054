import { useAppDispatch } from './state'
import {
  getClaimCompanyData as getCategoriesApi,
  searchClaimCompanies as searchRepairersApi
} from '../api/companies'
import {
  setCategories,
  setRadius,
  setRepairers
} from '../state/slices/companies_slice'

export const useCompanies = () => {
  const dispatch = useAppDispatch()

  const getCategories = async () => {
    const response = await getCategoriesApi()

    if (!response.success) {
      console.warn(response.error)
    }

    if (response.data) {
      dispatch(setCategories(response.data.categories))
      dispatch(setRadius(response.data.radius))
    }
  }

  const searchRepairers = async (params: {
    q: string
    radius: number
    category_id: number
    brand_id: number | null
  }) => {
    const response = await searchRepairersApi(params)

    if (!response.success) {
      console.warn(response.error)
    }

    if (response.data) {
      dispatch(setRepairers(response.data.claimCompanies))
    }

    return response
  }

  return {
    getCategories,
    searchRepairers
  }
}

export default useCompanies
