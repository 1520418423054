import React, { ButtonHTMLAttributes, FC } from 'react'
import LoadingIndicator from './LoadingIndicator'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary' | 'outline' | 'outline-brand'
  isLoading?: boolean
  replaceChildrenWhileLoading?: boolean
}
const Button: FC<Props> = ({
  children,
  variant = 'primary',
  isLoading,
  disabled,
  className,
  replaceChildrenWhileLoading = false,
  ...rest
}) => (
  <button
    className={`py-4 px-6 rounded-md 
    ${
  disabled || isLoading
    ? 'cursor-not-allowed opacity-50'
    : 'hover:bg-opacity-90'
  }
    ${styles[variant]}
    ${className}`}
    disabled={disabled || isLoading}
    {...rest}
  >
    {isLoading
      ? (
        <div className='flex items-center justify-center flex-1 text-white relative'>
          {replaceChildrenWhileLoading
            ? (
              <div
                className={`${textStyles[variant]} w-20 flex items-center justify-center`}
              >
                <LoadingIndicator />
              </div>
            )
            : (
              <>
                <div className={`${textStyles[variant]} absolute left-4`}>
                  <LoadingIndicator />
                </div>

                <div className={`${textStyles[variant]}`}>{children}</div>

                <div></div>
              </>
            )}
        </div>
      )
      : (
        <>{children}</>
      )}
  </button>
)

const styles = {
  primary: 'bg-brand text-white',
  secondary: 'bg-gray-200 text-gray border border-gray secondary-btn',
  outline: 'border-gray text-gray border font-medium',
  'outline-brand': 'border-brand text-brand border font-medium'
}

const textStyles = {
  primary: 'text-white',
  secondary: 'text-gray',
  outline: 'text-gray',
  'outline-brand': 'text-brand'
}

export default Button
