import React from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { moreTilesData } from '@verzekeringapp-mono/shared/src/utils/tiles_data/more_tiles_data'
import NavigationTileGrid from '../../components/navigation_tile/navigation_tile_grid'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'

const MoreScreen: React.FC = () => {
  const currentRelation = useAppSelector(getCurrentRelation)

  return (
    <ScreenWrapper>
      <NavigationTileGrid
        tiles={moreTilesData(currentRelation!.cooperationMembership)}
      />
    </ScreenWrapper>
  )
}

export default MoreScreen
