import React from 'react'
import ClaimDamageForm from './components/ClaimDamageForm'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import {
  useAppDispatch,
  useAppSelector
} from '@verzekeringapp-mono/shared/src/hooks/state'
import { getClaimMethod } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import ClaimDamageExternal from './components/ClaimDamageExternal'
import {
  getCurrentStep,
  getIsFormCompleted
} from '@verzekeringapp-mono/shared/src/state/selectors/claims'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IClaimsState,
  setCurrentStep
} from '@verzekeringapp-mono/shared/src/state/slices/claims_slice'
import ClaimDamageOverview from './components/ClaimDamageOverview'
import ClaimDamageConfirmation from './components/ClaimDamageConfirmation'
import Modal from '@verzekeringapp-mono/shared/src/components/Modal'
import LoadingIndicator from '../../components/LoadingIndicator'

const ClaimDamageFormScreen = () => {
  const claimMethod = useAppSelector(getClaimMethod)
  const currentStep = useAppSelector(getCurrentStep)
  const isFormCompleted = useAppSelector(getIsFormCompleted)
  const isLoading = useAppSelector(state => state.products.isLoading)
  const dispatch = useAppDispatch()

  const renderLoadingModal = () => (
    <Modal onClose={() => {}}>
      <div className='flex justify-center items-center'>
        <LoadingIndicator />
      </div>
    </Modal>
  )

  const renderSteps = () => (
    <div className='text-center'>
      <div className='hidden md:block'>
        <h1 className='text-4xl font-medium text-gray mb-4 font-montserrat'>
          Schade melden
        </h1>

        <p className='text-gray-600 mb-10'>
          Doorloop het formulier om de opgelopen schade te melden.
        </p>

        <div className='flex justify-between w-3/6 m-auto md:my-8'>
          {renderStepButton(1, 'Algemeen')}

          {renderStepButton(2, 'Overzicht')}
        </div>
      </div>
    </div>
  )

  const renderStepButton = (step: number, label: string) => (
    <div className='flex flex-col items-center'>
      <button
        className={`bg-brand text-white rounded-full h-12 w-12 flex items-center justify-center text-2xl 
          ${currentStep === 3 ? 'cursor-not-allowed' : ''}
          ${
    step < currentStep && isFormCompleted
      ? 'bg-success'
      : step > currentStep && !isFormCompleted
        ? 'bg-lightGray cursor-not-allowed'
        : 'bg-brand'
    }`}
        onClick={() => handleStepClick(step)}
        disabled={step > currentStep || (step === 3 && currentStep === 3)}
      >
        {step < currentStep && isFormCompleted
          ? (
            <FontAwesomeIcon icon='check' />
          )
          : (
            step
          )}
      </button>

      <p className='mt-4 text-lg text-gray-700'>{label}</p>
    </div>
  )

  const handleStepClick = (step: number) => {
    if (step <= currentStep || isFormCompleted) {
      dispatch(setCurrentStep(step))
    }
  }

  return (
    <ScreenWrapper hasPadding={false}>
      {isLoading
        ? (
          renderLoadingModal()
        )
        : claimMethod.extern
          ? (
            <ClaimDamageExternal claimMethod={claimMethod} />
          )
          : (
            renderSteps()
          )}

      {claimMethod.extern || (
        <RenderClaimDamageStep currentStep={currentStep} />
      )}
    </ScreenWrapper>
  )
}

const RenderClaimDamageStep = ({
  currentStep
}: {
  currentStep: IClaimsState['currentStep']
}) => {
  const steps = {
    1: <ClaimDamageForm />,
    2: <ClaimDamageOverview />,
    3: <ClaimDamageConfirmation />
  }

  return steps[currentStep as keyof typeof steps] || <ClaimDamageForm />
}

export default ClaimDamageFormScreen
