import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  useAppDispatch,
  useAppSelector
} from '@verzekeringapp-mono/shared/src/hooks/state'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

import useProducts from '@verzekeringapp-mono/shared/src/hooks/use_product'
import { getSelectedPolicy } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import PolicySelector from './PolicySelector'
import useClaims from '@verzekeringapp-mono/shared/src/hooks/use_claims'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import {
  setClaimDamageForm,
  setCurrentStep,
  setIsFormCompleted
} from '@verzekeringapp-mono/shared/src/state/slices/claims_slice'
import {
  getClaimDamageForm,
  getClaimIsLoading,
  getConceptClaim,
  getConceptIsLoading,
  getFileIsLoading
} from '@verzekeringapp-mono/shared/src/state/selectors/claims'
import { format } from 'date-fns'
import formatPhoneNumber from '@verzekeringapp-mono/shared/src/utils/formatPhoneNumber'
import formatToApiDate from '../../../utils/formatToApiDate'
import Button from '../../../components/Button'
import LoadingIndicator from '../../../components/LoadingIndicator'

const ClaimDamageForm = () => {
  const selectedPolicy = useAppSelector(getSelectedPolicy)

  const currentRelation = useAppSelector(getCurrentRelation)
  const claimDamageForm = useAppSelector(getClaimDamageForm)
  const conceptIsLoading = useAppSelector(getConceptIsLoading)
  const claimIsLoading = useAppSelector(getClaimIsLoading)
  const fileIsLoading = useAppSelector(getFileIsLoading)

  const [isAttachmentInfoOpen, setIsAttachmentInfoOpen] = React.useState(false)
  const [isConceptSaved, setIsConceptSaved] = React.useState(false)
  const conceptClaim = useAppSelector(getConceptClaim)

  const { getProduct } = useProducts()

  const {
    createClaimConcept,
    uploadClaimAttachment,
    deleteClaimAttachment,
    updateClaimConcept
  } = useClaims()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
    watch
  } = useForm({
    defaultValues: {
      date: claimDamageForm.date,
      description: claimDamageForm.description,
      phone: claimDamageForm.phoneNumber?.startsWith('06')
        ? claimDamageForm.phoneNumber?.split('06')[1]
        : claimDamageForm.phoneNumber,
      attachments: conceptClaim?.files
    }
  })

  const description = watch('description')

  const dispatch = useAppDispatch()

  const onSubmit = () => {
    reset()
    dispatch(setIsFormCompleted(true))
    dispatch(setCurrentStep(2))
  }

  const onFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        if (conceptClaim) {
          const promises = Array.from(e.target.files).map(file =>
            uploadClaimAttachment(conceptClaim.id, file)
          )

          await Promise.all(promises)
        } else {
          const concept = await handleSaveConcept()

          const promises = Array.from(e.target.files).map(file =>
            uploadClaimAttachment(concept!.id, file)
          )

          await Promise.all(promises)
        }
      }
    },
    [conceptClaim]
  )

  useEffect(() => {
    if (selectedPolicy) {
      getProduct(selectedPolicy!.productId.toString())
    }
  }, [selectedPolicy])

  useEffect(() => {
    if (errors.date || errors.description || errors.phone || isDirty) {
      dispatch(setIsFormCompleted(false))
    }
  }, [errors])

  const handleSaveConcept = async () => {
    const formData = getValues()

    if (conceptClaim) {
      await updateClaimConcept({
        ...conceptClaim,
        dateOfLoss: formData.date ? formatToApiDate(formData.date) : '',
        text: claimDamageForm.description || '',
        phoneNumber: claimDamageForm.phoneNumber
          ? formatPhoneNumber(claimDamageForm.phoneNumber)
          : ''
      })
    } else {
      return await createClaimConcept({
        policyNumber: selectedPolicy!.policyNumber,
        agent_number: currentRelation!.agentNumber,
        relation_number: Number(currentRelation!.relationNumber),
        branche: selectedPolicy!.branche
      }).then(
        async claim =>
          await updateClaimConcept({
            ...claim,
            dateOfLoss: formData.date ? formatToApiDate(formData.date) : '',
            text: claimDamageForm.description || '',
            phoneNumber: claimDamageForm.phoneNumber
              ? formatPhoneNumber(claimDamageForm.phoneNumber)
              : ''
          })
      )
    }

    setIsConceptSaved(true)
    reset(formData)
  }

  const values = getValues()
  const onChangeForm = useCallback(() => {
    dispatch(
      setClaimDamageForm({
        policyId: selectedPolicy!.policyNumber,
        date: values.date ? format(new Date(values.date), 'dd-MM-yyyy') : '',
        description: values.description,
        phoneNumber: values.phone || '',
        attachments: values.attachments
      })
    )
  }, [selectedPolicy, values])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={onChangeForm}
      className='mx-auto py-4 px-8 md:p-8 bg-white rounded-lg gap-2 lg:w-5/6 w-full'
    >
      <div className='mb-8'>
        <h2 className='text-3xl font-medium mb-4'>Algemeen</h2>

        <p className='text-gray'>
          Om te bepalen wat voor soort schade u heeft opgelopen, zijn er enkele
          vragen noodzakelijk.
        </p>
      </div>

      <div className='flex flex-1 gap-4 flex-col md:flex-row'>
        <PolicySelector />

        <div className='mb-6 flex flex-col flex-1'>
          <label
            htmlFor='date'
            className='w-full text-gray font-medium mb-2'
          >
            Wat is de datum waarop de schade is ontstaan?
          </label>

          <div className='w-full '>
            <input
              id='date'
              type='date'
              min={selectedPolicy?.minimumDateOfLoss}
              max={format(new Date(), 'yyyy-MM-dd')}
              {...register('date', {
                required: true,
                validate: value => {
                  const date = new Date(value)
                  const today = new Date()

                  if (date > today) {
                    return 'De datum kan niet in de toekomst liggen.'
                  }
                  if (
                    selectedPolicy?.minimumDateOfLoss &&
                    date < new Date(selectedPolicy.minimumDateOfLoss)
                  ) {
                    return `De datum kan niet voor ${format(
                      new Date(selectedPolicy.minimumDateOfLoss),
                      'dd-MM-yyyy'
                    )} liggen.`
                  }

                  return true
                }
              })}
              className={`w-full border ${
                errors.date ? 'border-red' : 'border-lightGray'
              } rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-4 text-gray`}
            />

            {errors.date && (
              <span className='text-red text-sm mt-1 block'>
                Dit veld is verplicht.
              </span>
            )}
          </div>
        </div>
      </div>

      <div className='mb-6 flex flex-col '>
        <div className='flex items-center justify-between w-full'>
          <label
            htmlFor='description'
            className='w-full text-gray font-medium mb-2'
          >
            Geef een gedetailleerde omschrijving van de schade
          </label>

          {description && (
            <span className='md:block text-gray'>
              {600 - description.length}
            </span>
          )}
        </div>

        <textarea
          id='description'
          {...register('description', { required: true, maxLength: 600 })}
          rows={4}
          placeholder='Hoe is de schade ontstaan, waar is de schade ontstaan, waaraan is schade, door wie is de schade ontstaan etc...'
          maxLength={600}
          className={`w-full border ${
            errors.description ? 'border-red' : 'border-lightGray'
          } rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-4 text-gray`}
        />

        {errors.description && (
          <span className='text-red text-sm mt-1 block'>
            Dit veld is verplicht.
          </span>
        )}
      </div>

      <div className='mb-6 flex flex-col '>
        <label
          htmlFor='phone'
          className='w-full  text-gray font-medium mb-2'
        >
          Wat is uw telefoonnummer?
        </label>

        <div className='w-full flex'>
          <span className='border border-lightGray rounded-tl-md rounded-bl-md shadow-sm p-4 text-gray bg-lighterBrown'>
            06
          </span>

          <input
            id='phone'
            type='tel'
            maxLength={8}
            {...register('phone', {
              required: 'Dit veld is verplicht.',
              pattern: {
                value: /^[0-9]{8}$/,
                message: 'Voer een geldig telefoonnummer in.'
              }
            })}
            className={`w-full border rounded-tr-md rounded-br-md ${
              errors.phone ? 'border-red' : 'border-lightGray'
            } shadow-sm focus:ring-blue-500 focus:border-blue-500 p-4 text-ede'
            `}
            placeholder='12345678'
          />
        </div>

        {errors.phone && (
          <span className='text-red text-sm mt-1 block'>
            {errors.phone.message}
          </span>
        )}
      </div>

      <div className='mb-6 flex flex-col '>
        <div>
          <label
            htmlFor='attachments'
            className='w-full text-gray font-medium mb-2'
          >
            Voeg bijlage(n) toe
          </label>

          <FontAwesomeIcon
            icon={faInfoCircle}
            size='lg'
            className='text-lightBrown ml-2 cursor-pointer'
            onClick={() => setIsAttachmentInfoOpen(!isAttachmentInfoOpen)}
          />
        </div>

        {isAttachmentInfoOpen && (
          <div className='bg-lighterBrown p-4 rounded-lg my-4'>
            <p className='text-gray'>
              Bestandstypen die worden ondersteund: jpg, jpeg, png, txt, pdf,
              doc, docx, avi, mov, mpg, mpeg, mp4, msg, xls, xlsx, heic
            </p>
          </div>
        )}

        <p className='my-2 text-gray'>
          Voeg relevante bijlage(n) toe. Denk aan kleurenfoto&apos; van de
          schade, offertes, eventuele politierapporten en aankoopnota&apos;s.
        </p>

        <div className='flex flex-col gap-4'>
          {conceptClaim?.files?.map(file => (
            <div
              key={file.url}
              className='flex items-center bg-gray-200 text-gray-700 font-medium justify-between px-4 rounded-lg  border border-brand w-full py-4'
            >
              <span className='text-brand'>{file.title}</span>

              <button
                onClick={() => {
                  deleteClaimAttachment(file.url, conceptClaim.id)
                }}
                type='button'
              >
                <FontAwesomeIcon
                  icon={'trash'}
                  className='text-brand mr-4'
                />
              </button>
            </div>
          ))}
        </div>

        <div className='w-full flex items-center mt-8'>
          {fileIsLoading
            ? (
              <div className='text-brand items-center'>
                <LoadingIndicator />
              </div>
            )
            : (
              <>
                <label
                  htmlFor='attachments'
                  className='flex items-center cursor-pointer bg-gray-200 text-gray font-medium  px-6 rounded-lg  border border-brand w-full py-4'
                >
                  <FontAwesomeIcon
                    icon={'plus'}
                    className='text-brand mr-4'
                  />

                  <span className='text-brand'>Selecteer bestand(en)</span>
                </label>

                <input
                  id='attachments'
                  type='file'
                  onChange={onFileChange}
                  accept='image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, video/*, text/*'
                  className='hidden'
                  multiple
                />
              </>
            )}
        </div>
      </div>

      <div className='flex justify-between'>
        <Button
          onClick={() => {
            handleSaveConcept()
          }}
          variant='outline-brand'
          isLoading={conceptIsLoading}
          disabled={conceptIsLoading || fileIsLoading}
          replaceChildrenWhileLoading={true}
        >
          {isConceptSaved && !isDirty
            ? 'Concept opgeslagen'
            : 'Concept opslaan'}
        </Button>

        <Button
          type='submit'
          disabled={claimIsLoading || conceptIsLoading || fileIsLoading}
        >
          Volgende
          <FontAwesomeIcon
            icon={['fas', 'arrow-right']}
            className='ml-2'
          />
        </Button>
      </div>
    </form>
  )
}

export default ClaimDamageForm
