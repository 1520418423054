import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ITerms } from '../../models/terms'
import { logout } from '../actions/constants'
import { IWhatToDoItem } from '../../models/what_to_do'
import { IFlashNews } from '../../models/flash_news'

export interface ISupportState {
  aboutMembership: string
  aboutNH: string
  termsApp?: ITerms
  whatToDos?: IWhatToDoItem[]
  flashNews?: IFlashNews
}

const initialState: ISupportState = {
  aboutMembership: '',
  aboutNH: '',
  termsApp: undefined,
  whatToDos: []
}

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setAboutMembership: (state, action: PayloadAction<string>) => {
      state.aboutMembership = action.payload
    },
    setAboutNH: (state, action: PayloadAction<string>) => {
      state.aboutNH = action.payload
    },
    setTermsApp: (state, action: PayloadAction<ITerms>) => {
      state.termsApp = action.payload
    },
    setWhatToDos: (state, action: PayloadAction<IWhatToDoItem[]>) => {
      state.whatToDos = action.payload
    },
    setFlashNews: (state, action: PayloadAction<IFlashNews | undefined>) => {
      state.flashNews = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState)
  }
})

export const {
  setAboutMembership,
  setAboutNH,
  setTermsApp,
  setWhatToDos,
  setFlashNews
} = supportSlice.actions

export default supportSlice.reducer
