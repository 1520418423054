import {
  useAppDispatch,
  useAppSelector
} from '@verzekeringapp-mono/shared/src/hooks/state'
import { setShowFirstUse } from '@verzekeringapp-mono/shared/src/state/slices/user_slice'
import React, { useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import LoginScreenWrapper from '../../../../components/screen_wrappers/login_screen_wrapper'
import Login from './Login'
import NotActivated from './NotActivated'
import NotActivatedOld from './NotActivatedOld'
import { getShowFirstUse } from '@verzekeringapp-mono/shared/src/state/selectors/user'

// TODO: refactor this logic and separate the concerns into different components
const LoginAndRegisterContent = ({
  type = 'LOGIN'
}: {
  type: 'LOGIN' | 'REGISTER'
}) => {
  const { state } = useLocation()
  const showFirstUse = useAppSelector(getShowFirstUse)
  const form = useForm({
    defaultValues: {
      email: state?.email || '',
      password: ''
    }
  })
  const { watch } = form
  const email = watch('email')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [checkEmailStatus, setCheckEmailStatus] = React.useState<string>('')

  const renderContent = useCallback(() => {
    switch (checkEmailStatus) {
    case 'activated':
      if (type === 'LOGIN') {
        dispatch(setShowFirstUse(false))
      }

      if (showFirstUse || type === 'REGISTER') {
        return (
          <Navigate
            to='/home'
            state={{
              email,
              type: 'ACTIVATED'
            }}
          />
        )
      } else {
        return <Login
          type={type}
          setCheckEmailStatus={setCheckEmailStatus}
        />
      }
    case 'multi_relations':
    case 'not_activated':
      return <NotActivated setCheckEmailStatus={setCheckEmailStatus} />
    case 'not_activated_old':
      return <NotActivatedOld setCheckEmailStatus={setCheckEmailStatus} />
    case 'not_found':
      navigate(`/idin-register?email=${encodeURIComponent(email)}`)

      return null
    default:
      return <Login
        type={type}
        setCheckEmailStatus={setCheckEmailStatus}
      />
    }
  }, [checkEmailStatus])

  return (
    <LoginScreenWrapper>
      <FormProvider {...form}>{renderContent()}</FormProvider>
    </LoginScreenWrapper>
  )
}

export default LoginAndRegisterContent
