import { INavigationTileItem } from '../../models/navigation_tile_item'

export const caseTilesData: INavigationTileItem[] = [
  // {
  //   icon: 'file-alt',
  //   title: 'Mijn bezittingen',
  //   href: 'dossier/bezittingen'
  // },
  {
    icon: 'fragile',
    title: 'Mijn schades',
    href: 'dossier/mijn-schades'
  },
  {
    icon: 'euro-sign',
    title: 'Mijn betalingsoverzicht',
    href: 'dossier/betalingsoverzicht'
  }, // TODO: - Hide verzekeringsbewijzen for certain users
  {
    icon: 'address-card',
    title: 'Verzekeringsbewijzen',
    href: 'dossier/verzekeringsbewijzen'
  }, // TODO: - Hide waardemeters for certain users
  {
    icon: 'tachometer',
    title: 'Waardemeters',
    href: 'dossier/waardemeters'
  }
]
