import { getTips as getTipsAPI } from '../api/tips'
import { setTips } from '../state/slices/tips_slice'
import { useAppDispatch } from './state'
import * as Sentry from '@sentry/react'
export const useTips = () => {
  const dispatch = useAppDispatch()

  const getTips = async (): Promise<boolean> => {
    const result = await getTipsAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }
    if (result.data) {
      dispatch(setTips(result.data))
    }

    return result.success
  }

  return {
    getTips
  }
}

export default useTips
