import { Images } from '@verzekeringapp-mono/shared/src/assets'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const NotActivatedOld = ({
  setCheckEmailStatus
}: {
  setCheckEmailStatus: (status: string) => void
}) => {
  const { watch } = useFormContext()
  const email = watch('email')

  return (
    <>
      <img
        src={Images.Logo}
        width={180}
        height={70}
      />

      <p className='title mt-8'>Gevonden!</p>

      <p className='description mt-4'>
        We zien dat u al een account heeft maar dat deze nog niet geactiveerd is
        voor de nieuwe omgeving.
      </p>

      <p className='description mt-4'>
        Vanwege verbeterde beveiligingsmaatregelen moet u uw account opnieuw
        activeren, we hebben hiervoor een e-mail gestuurd naar:
        <br />

        <span className='font-bold'> {email} </span>
      </p>

      <p className='description mt-4'>
        Controleer ook uw spambox en ongewenste e-mail.
      </p>

      <button
        className='secondary-btn w-full mt-4'
        onClick={() => {
          setCheckEmailStatus('')
        }}
      >
        Terug
      </button>
    </>
  )
}

export default NotActivatedOld
