import React from 'react'
import MemberForm from './components/member_form'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getRelationUser } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import useRelation from '@verzekeringapp-mono/shared/src/hooks/use_relation'

const EditMemberScreen = () => {
  const { id } = useParams<{ id: string }>()

  const user = useAppSelector(getRelationUser(id!))
  const { updateRelationUser } = useRelation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  return (
    <ScreenWrapper>
      <MemberForm
        isLoading={isLoading}
        error={error}
        onSubmit={async data => {
          setIsLoading(true)
          await updateRelationUser(id!, data)
            .then(result => {
              if (result.message) {
                setError(result.message)
              } else {
                navigate('/mijn-profiel/gebruikers-beheren')
              }
            })
            .catch(e => {
              setError(e.message)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }}
        formType='edit'
        email={user?.email || ''}
        firstName={user?.firstName || ''}
        initials={user?.initials || ''}
        lastName={user?.lastName || ''}
        dateOfBirth={user?.dateOfBirth || ''}
      />
    </ScreenWrapper>
  )
}

export default EditMemberScreen
