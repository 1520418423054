import React from 'react'
import ScreenWrapper from '../../../../components/screen_wrappers/screen_wrapper'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getTipsForCategory } from '@verzekeringapp-mono/shared/src/state/selectors/tips'
import { useParams } from 'react-router-dom'

const PreventionTipScreen: React.FC = () => {
  const { id } = useParams()

  const tips = useAppSelector(getTipsForCategory(parseInt(id ?? '0')))

  return (
    <ScreenWrapper
      breadCrumbsOverride={[
        { label: 'Meer', path: '/meer' },
        { label: 'Preventietips', path: '/meer/preventietips' },
        { label: 'Preventietip', path: `/meer/preventietips/${id}` }
      ]}
    >
      <div className='bg-white rounded p-8'>
        {tips.map(tip => (
          <div
            key={tip.id}
            className='html-content'
            dangerouslySetInnerHTML={{ __html: tip.content }}
          />
        ))}
      </div>
    </ScreenWrapper>
  )
}

export default PreventionTipScreen
