import React, { useEffect } from 'react'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getPolicyById as getPolicySelector } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import { useNavigate, useParams } from 'react-router-dom'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import usePolicies from '@verzekeringapp-mono/shared/src/hooks/use_policies'
import PolicyDetailHeader from './components/policy_detail_header'
import GreenCard from './components/green_card'
import PolicyDetails from './components/policy_details'
import PolicyConditions from './components/policy_conditions'
import showValueMeter from '@verzekeringapp-mono/shared/src/utils/show_value_meter'
import ValueMeterLink from '../../../components/ValueMeterLink'
import Tabs from '../../../components/Tabs'
import ClaimDamageButton from '../../../components/ClaimDamageButton'
import { Helmet } from 'react-helmet'

const PolicyDetailScreen: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { getPolicy } = usePolicies()

  const policy = useAppSelector(getPolicySelector(id!))
  const currentRelation = useAppSelector(getCurrentRelation)

  useEffect(() => {
    if (policy === undefined) navigate('/verzekeringen')

    getPolicy(policy!.id)
  }, [])

  if (policy === undefined) return null

  return (
    <ScreenWrapper hasPadding={false}>
      <Helmet>
        <title>{policy.productName}</title>
      </Helmet>

      <div className='bg-white rounded p-8 flex flex-col '>
        <PolicyDetailHeader policy={policy} />

        <div className='hidden md:block'>
          <GreenCard
            withDivider
            policy={policy}
          />

          <div className='border-b border-lightGray my-8' />

          <PolicyDetails policy={policy} />

          <div className='w-1/4 hidden '>
            <ClaimDamageButton policyNumber={policy.policyNumber} />
          </div>

          <div className='border-b border-lightGray my-8' />

          <p className='subtitle mb-4'>Dekkingen</p>

          <button
            className='primary-btn w-[500px]'
            onClick={() =>
              window.open(currentRelation?.policyStatement, '_blank')
            }
          >
            <p className='max-w-[300px] text-white'>
              Raadpleeg het polisblad voor uw gekozen dekkingen.
            </p>
          </button>

          {!!policy.clauses?.length && policy.clauses.length > 0 && (
            <div>
              <div className='border-b border-lightGray my-8' />

              <p className='subtitle'>Clausules</p>
            </div>
          )}

          {policy.clauses?.map(clause => (
            <div key={clause.id}>
              <p>{clause.name}</p>

              <p>{clause.description}</p>
            </div>
          ))}

          <div className='border-b border-lightGray my-8' />

          {showValueMeter(policy) && (
            <div className='flex gap-4 align-start flex-wrap'>
              <ValueMeterLink policy={policy} />
            </div>
          )}

          <PolicyConditions policy={policy} />
        </div>
      </div>

      <div className='md:hidden'>
        <Tabs
          tabs={[
            {
              title: 'Details',
              content: (
                <div className='p-8 bg-white'>
                  <PolicyDetails policy={policy} />

                  <ClaimDamageButton policyNumber={policy.policyNumber} />
                </div>
              ),
              slug: 'details'
            },
            {
              title: 'Dekkingen',
              content: (
                <div className='p-8 bg-white'>
                  <button
                    className='primary-btn '
                    onClick={() =>
                      window.open(currentRelation?.policyStatement, '_blank')
                    }
                  >
                    <p className='max-w-[300px] text-white'>
                      Raadpleeg het polisblad voor uw gekozen dekkingen.
                    </p>
                  </button>
                </div>
              ),
              slug: 'dekkingen'
            },
            {
              title: 'Meer',
              content: (
                <div className='p-8 bg-white flex gap-4 flex-col'>
                  <GreenCard policy={policy} />

                  <PolicyConditions policy={policy} />
                </div>
              ),
              slug: 'meer'
            }
          ]}
        />
      </div>
    </ScreenWrapper>
  )
}

export default PolicyDetailScreen
