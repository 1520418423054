import { INavigationTileItem } from '../../models/navigation_tile_item'

export const moreTilesData: (
  showMemberBenefits?: boolean,
) => INavigationTileItem[] = showMemberBenefits =>
  showMemberBenefits
    ? [
      {
        icon: 'nh',
        title: 'Nh1816 Verzekeringen',
        href: '/meer/nh1816-verzekeringen'
      },
      {
        icon: 'info',
        title: 'Over de applicatie',
        href: '/meer/over-de-app'
      },
      {
        icon: 'comments',
        title: 'Veelgestelde vragen',
        href: '/meer/vragen'
      },
      {
        icon: 'lightbulb',
        title: 'Preventietips',
        href: '/meer/preventietips'
      },
      {
        icon: 'file-check',
        title: 'Gebruiksvoorwaarden',
        href: '/meer/gebruiksvoorwaarden'
      },
      {
        icon: 'tag',
        title: 'Ledenvoordelen',
        href: '/meer/ledenvoordelen'
      },
      {
        icon: 'seedling',
        title: 'Duurzaamheid',
        href: 'https://www.nh1816.nl/duurzaam',
        openInNewTab: true
      },
      {
        icon: 'address-card',
        title: 'Verzekeringsbewijzen',
        href: '/verzekeringsbewijzen'
      }
    ]
    : [
      {
        icon: 'nh',
        title: 'Nh1816 Verzekeringen',
        href: '/meer/nh1816-verzekeringen'
      },
      {
        icon: 'info',
        title: 'Over de applicatie',
        href: '/meer/over-de-app'
      },
      {
        icon: 'comments',
        title: 'Veelgestelde vragen',
        href: '/meer/vragen'
      },
      {
        icon: 'lightbulb',
        title: 'Preventietips',
        href: '/meer/preventietips'
      },
      {
        icon: 'file-check',
        title: 'Gebruiksvoorwaarden',
        href: '/meer/gebruiksvoorwaarden'
      },

      {
        icon: 'seedling',
        title: 'Duurzaamheid',
        href: 'https://www.nh1816.nl/duurzaam',
        openInNewTab: true
      },
      {
        icon: 'address-card',
        title: 'Verzekeringsbewijzen',
        href: '/verzekeringsbewijzen'
      }
    ]
