import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'

const _getState = (state: RootState) => state.relations

export const getRelations = createSelector(
  _getState,
  (state) => state.relations
)

export const getCurrentRelation = createSelector(
  _getState,
  (state) => state.currentRelation
)

export const getAgencyId = createSelector(
  _getState,
  (state) => state.currentRelation?.agencyId
)

export const getRelationUsersSelector = createSelector(
  _getState,
  (state) => state.relationUsers
)

export const getRelationUser = (userId: string) =>
  createSelector(_getState, (state) =>
    state.relationUsers.find((user) => user.id === userId)
  )
