import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import useSession from '@verzekeringapp-mono/shared/src/hooks/use_session'
import {
  useAppDispatch,
  useAppSelector
} from '@verzekeringapp-mono/shared/src/hooks/state'
import { getRelations } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import CombilPolicyListItem from '../profile/select_combi_policy_screen/components/combi_policy_list_item'
import CheckboxInput from '../../components/CheckboxInput'
import PolicySvg from '../../components/illustrations/Policy'
import { IRelation } from '@verzekeringapp-mono/shared/src/models/relation'
import SuccessSvg from '../../components/illustrations/Succes'
import { useNavigate } from 'react-router-dom'
import { getUser } from '@verzekeringapp-mono/shared/src/state/selectors/user'
import { setCurrentRelation } from '@verzekeringapp-mono/shared/src/state/slices/relations_slice'

const Setup = () => {
  const [currentStep, setCurrentStep] = React.useState(0)

  const currentRelation = useAppSelector(
    state => state.relations.currentRelation
  )
  const [relation, setRelation] = React.useState<IRelation | null | undefined>(
    currentRelation
  )
  const user = useAppSelector(getUser)

  const form = useForm({
    defaultValues: {
      firstname: user?.firstName ?? '',
      termsRead: false
    }
  })

  const { postOnboarding } = useSession()
  const navigate = useNavigate()

  return (
    <div className='bg-background min-h-screen h-full py-8 flex'>
      <div className='flex flex-col items-center justify-center lg:max-w-[1500px] mx-auto md:max-w-5-xl w-full bg-white flex-1 rounded-lg py-8'>
        <FormProvider {...form}>
          <form
            className='w-full'
            onSubmit={form.handleSubmit(data => {
              if (relation) {
                postOnboarding({
                  firstName: data.firstname,
                  termsRead: data.termsRead,
                  relationId: relation.id,
                  relationNumber: relation.relationNumber
                }).then(() => {
                  navigate('/')
                })
              }
            })}
          >
            {currentStep === 0 && (
              <FirstnameStep onNextStep={() => setCurrentStep(1)} />
            )}

            {currentStep === 1 && (
              <TermsStep
                onPreviousStep={() => setCurrentStep(0)}
                onNextStep={() => setCurrentStep(2)}
              />
            )}

            {currentStep === 2 && (
              <RelationsStep
                setRelation={setRelation}
                onNextStep={() => setCurrentStep(3)}
                currentRelation={relation}
              />
            )}

            {currentStep === 3 && <SuccessStep />}
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

const FirstnameStep = ({ onNextStep }: { onNextStep: () => void }) => {
  const { control, getFieldState } = useFormContext()
  const fieldState = getFieldState('firstname')

  return (
    <div className='flex flex-col items-center md:w-1/2 w-full mx-auto p-4'>
      <FontAwesomeIcon
        color={Colors.lightBrown}
        icon='handshake'
        className='h-[150px] w-[150px] mb-4'
      />

      <div className='mb-4'>
        <h1 className='text-3xl font-medium text-gray mb-4'>Uw voornaam</h1>

        <p>Laten we even kennismaken.</p>
      </div>

      <Controller
        control={control}
        name='firstname'
        rules={{
          required: true
        }}
        render={({ field }) => (
          <TextInput
            shouldValidate={false}
            showValidationIcon={false}
            icon='user'
            {...field}
          />
        )}
      />

      <Button
        onClick={onNextStep}
        type='button'
        className='w-full'
        disabled={fieldState.invalid}
      >
        Voornaam bevestigen
      </Button>
    </div>
  )
}

const TermsStep = ({
  onNextStep,
  onPreviousStep
}: {
  onNextStep: () => void
  onPreviousStep: () => void
}) => {
  const terms = useAppSelector(state => state.terms.terms?.termsOfUse)
  const { control, watch } = useFormContext()

  if (!terms) return null

  return (
    <div className=' md:w-2/3 mx-auto w-full p-4'>
      <h1 className='text-2xl font-medium text-gray mb-4'>
        Gebruikersvoorwaarden
      </h1>

      <div
        className='html-content my-4 mx-auto'
        dangerouslySetInnerHTML={{ __html: terms }}
      />

      <div className='border-b border-lightGray my-8' />

      <Controller
        name='termsRead'
        control={control}
        rules={{
          required: true
        }}
        render={({ field }) => (
          <CheckboxInput
            {...field}
            label='Ik ga akkoord met de gebruiksvoorwaarden'
            checked={!!field.value}
            className='mb-8'
            value={field.name}
          />
        )}
      />

      <div className='flex justify-between'>
        <Button
          onClick={() => {
            onPreviousStep()
          }}
          variant='outline'
          replaceChildrenWhileLoading={true}
        >
          <FontAwesomeIcon
            icon={['fal', 'chevron-left']}
            className='mr-2'
          />
          Vorige
        </Button>

        <Button
          onClick={onNextStep}
          type='button'
          disabled={!watch('termsRead')}
        >
          Volgende
          <FontAwesomeIcon
            icon={['fal', 'chevron-right']}
            className='ml-2'
          />
        </Button>
      </div>
    </div>
  )
}

const RelationsStep = ({
  onNextStep,
  setRelation,
  currentRelation
}: {
  onNextStep: () => void
  setRelation: (relation: IRelation) => void
  currentRelation?: IRelation | null
}) => {
  const relations = useAppSelector(getRelations)

  const dispatch = useAppDispatch()

  return (
    <div className='flex flex-col items-center px-4 md:w-1/2 mx-auto w-full'>
      <PolicySvg />

      <p className='title mb-4 text-center'>Welke combipolis?</p>

      <p className='description mb-4 text-center'>
        Geen zorgen! U kunt altijd weer een andere combipolis selecteren.
      </p>

      {relations.map(relation => (
        <CombilPolicyListItem
          key={relation.id}
          relation={relation}
          current={!!currentRelation && currentRelation.id === relation.id}
          onPressSelect={relation => {
            setRelation(relation)
            dispatch(setCurrentRelation(relation))
          }}
        />
      ))}

      <Button
        onClick={onNextStep}
        disabled={!currentRelation}
        className='w-full mt-8'
        type='button'
      >
        Bevestigen
      </Button>
    </div>
  )
}

const SuccessStep = () => (
  <div className='flex flex-col items-center md:w-1/2 mx-auto w-full p-4'>
    <SuccessSvg />

    <p className='title text-center'>Succesvol ingesteld!</p>

    <p className='text-center'>U kunt nu gebruik maken van de Mijn Polissen.</p>

    <Button
      className={'primary-btn w-full mt-8 mb-3'}
      type='submit'
    >
      Doorgaan
    </Button>
  </div>
)

export default Setup
