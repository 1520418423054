import React, { ReactNode, useState } from 'react'
import { Images } from '@verzekeringapp-mono/shared/src/assets'
import HelpModal from '../../screens/login/components/help_modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'

interface IProps {
  children: ReactNode
  showHelpButton?: boolean
  helpButtonText?: string
}

const LoginScreenWrapper: React.FC<IProps> = props => {
  const [isHelpModalOpen, setHelpModalOpen] = useState(false)

  return (
    <div className='login-body-container'>
      <div className='login-left-container'>
        <img
          src={Images.Background}
          className='w-full h-full object-cover'
        />
      </div>

      <div className='login-right-container px-8'>
        <div className='w-2/3'>
          <>
            <button
              className='absolute top-8 right-8'
              onClick={() => setHelpModalOpen(true)}
            >
              <div className='flex items-center'>
                <p className='light mr-2'>Help</p>

                <FontAwesomeIcon
                  icon='question-circle'
                  size='xl'
                  color={Colors.lightBrown}
                />
              </div>
            </button>

            {props.children}

            {props.showHelpButton
              ? (
                <button
                  className='light mt-4'
                  onClick={() => setHelpModalOpen(true)}
                >
                  {props?.helpButtonText ?? ''}
                </button>
              )
              : null}
          </>
        </div>
      </div>

      {isHelpModalOpen && <HelpModal onClose={() => setHelpModalOpen(false)} />}
    </div>
  )
}

export default LoginScreenWrapper
