import React from 'react'
import { IMemberBenefit } from '@verzekeringapp-mono/shared/src/models/member_benefit'
import { format } from 'date-fns'

interface IProps {
  memberBenefit: IMemberBenefit
}

const MemberBenefitContent: React.FC<IProps> = ({ memberBenefit }) => {
  const openExternalLink = () => {
    if (memberBenefit?.externalLink) {
      window.open(memberBenefit.externalLink, '_blank', 'noreferrer')
    }
  }

  return (
    <div>
      {memberBenefit?.externalLink && (
        <button
          className='flex items-center justify-center w-full bg-white border rounded-lg mb-4'
          onClick={openExternalLink}
        >
          <p className='py-4'>Ga naar actiepagina</p>
        </button>
      )}

      {memberBenefit?.campaignContent && (
        <div
          className='html-content'
          dangerouslySetInnerHTML={{ __html: memberBenefit?.campaignContent }}
        />
      )}

      {memberBenefit?.requirementsContent && (
        <div
          dangerouslySetInnerHTML={{
            __html: memberBenefit?.requirementsContent
          }}
        />
      )}

      {memberBenefit?.validFrom && memberBenefit?.validTill && (
        <p className='paragraph-light mt-4'>{`Geldig van ${format(
          memberBenefit.validFrom,
          'dd-MM-yyyy'
        )} tot ${format(memberBenefit.validTill, 'dd-MM-yyyy')}`}</p>
      )}
    </div>
  )
}

export default MemberBenefitContent
