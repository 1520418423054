import React, { useEffect } from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import useSupport from '@verzekeringapp-mono/shared/src/hooks/use_support'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getTermsApp as getTermsAppSelector } from '@verzekeringapp-mono/shared/src/state/selectors/support'

const TermsOfUseScreen: React.FC = () => {
  const { getTermsApp } = useSupport()

  const termsApp = useAppSelector(getTermsAppSelector)

  useEffect(() => {
    getTermsApp()
  }, [])

  return (
    <ScreenWrapper>
      <div className='bg-white w-full rounded p-8'>
        {termsApp
          ? (
            <div
              className='html-content'
              dangerouslySetInnerHTML={{ __html: termsApp?.termsOfUse }}
            />
          )
          : (
            <p>Laden...</p>
          )}
      </div>
    </ScreenWrapper>
  )
}

export default TermsOfUseScreen
