import React, { useEffect } from 'react'
import { usePayments } from '@verzekeringapp-mono/shared/src/hooks/use_payments'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getPaymentsByYear as getPaymentsByYearSelector } from '@verzekeringapp-mono/shared/src/state/selectors/payments'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PaymentsScreen: React.FC = () => {
  const { getPayments } = usePayments()

  useEffect(() => {
    getPayments()
  }, [])

  const paymentsByYear = useAppSelector(getPaymentsByYearSelector)

  return (
    <ScreenWrapper>
      <div>
        {Object.keys(paymentsByYear)
          .sort((a, b) => +b - +a)
          .map((year, index) => (
            <div key={index}>
              <p className='py-4'>{year}</p>

              {paymentsByYear[year].map((payment, index) => (
                <div
                  key={index}
                  className='bg-white rounded p-4 mb-4'
                >
                  <div className='flex justify-between'>
                    <div className='flex flex-col gap-2'>
                      {payment.invoiceType === 'Prolongatie'
                        ? 'Betaling'
                        : payment.invoiceType}

                      <div className='flex gap-2 items-center'>
                        <FontAwesomeIcon
                          color={Colors.textGray}
                          icon={['fal', 'calendar']}
                        />

                        <p className='text-base text-textGray'>
                          {payment.bookDate}
                        </p>
                      </div>
                    </div>

                    <div className='flex gap-4'>
                      <p
                        style={{
                          color: payment.outstanding
                            ? Colors.waiting
                            : Colors.success
                        }}
                      >
                        {payment.outstanding ? 'Open' : 'Betaald'}
                      </p>

                      {payment.collectionAmount && (
                        <p>
                          {Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(Number(payment.collectionAmount))}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </ScreenWrapper>
  )
}

export default PaymentsScreen
