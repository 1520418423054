import { useAppDispatch } from './state'
import {
  getProducts as getProductsAPI,
  getProduct as getProductApi
} from '../api/products'
import {
  setIsLoading,
  setProduct,
  setProducts
} from '../state/slices/products_slice'
import * as Sentry from '@sentry/react'

export const useProducts = () => {
  const dispatch = useAppDispatch()

  const getProducts = async (): Promise<boolean> => {
    dispatch(setIsLoading(true))
    const result = await getProductsAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }
    if (result.data) {
      dispatch(setProducts(result.data))
    }
    dispatch(setIsLoading(false))

    return result.success
  }

  const getProduct = async (id: string) => {
    dispatch(setIsLoading(true))
    const result = await getProductApi(id)

    dispatch(setProduct(result.data))

    if (!result.success) {
      Sentry.captureException(result.error)
    }

    dispatch(setIsLoading(false))

    return result.data
  }

  return {
    getProducts,
    getProduct
  }
}

export default useProducts
