import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../configure_store'
import { INavigationTileItem } from '../../models/navigation_tile_item'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import he from 'he'

const _getState = (state: RootState) => state.tips

export const getTipCategories = createSelector(
  _getState,
  (state) => (
    state.categories.map((category) => {
      const navigationTile: INavigationTileItem = {
        title: category.title,
        icon: category.icon as IconProp,
        href: `/meer/preventietips/${category.id}`
      }

      return navigationTile
    })
  )
)

export const getTipsForCategory = (categoryId: number) => createSelector(
  _getState,
  (state) => (
    state.tips.filter((tip) => tip.categoryId === categoryId).map((tip) => ({
      ...tip,
      content: he.decode(tip.content)
    }))
  )
)
