import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IMemberBenefit } from '../../models/member_benefit'
import { logout, selectRelation } from '../actions/constants'

export interface IMemberBenefitState {
    memberBenefits: IMemberBenefit[]
}

const initialState: IMemberBenefitState = {
  memberBenefits: []
}

export const memberBenefitSlice = createSlice({
  name: 'memberBenefits',
  initialState,
  reducers: {
    setMemberBenefits: (state, action: PayloadAction<IMemberBenefit[]>) => {
      state.memberBenefits = action.payload.sort((a, b) => a?.sortNumber - b?.sortNumber)
    },
    setMemberBenefit: (state, action: PayloadAction<IMemberBenefit>) => {
      const index = state.memberBenefits.findIndex((memberBenefit) => memberBenefit.id === action.payload.id)

      if (index !== -1) {
        state.memberBenefits[index] = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, () => initialState)
  }
})

export const {
  setMemberBenefits,
  setMemberBenefit
} = memberBenefitSlice.actions

export default memberBenefitSlice.reducer
