import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { Link } from 'react-router-dom'
import UserCard from './components/member_card'
import useRelation from '@verzekeringapp-mono/shared/src/hooks/use_relation'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import {
  getCurrentRelation,
  getRelationUsersSelector
} from '@verzekeringapp-mono/shared/src/state/selectors/relation'

const ManageMembersScreen = () => {
  const currentRelation = useAppSelector(getCurrentRelation)
  const relationUsers = useAppSelector(getRelationUsersSelector)

  const { getRelationUsers } = useRelation()

  React.useEffect(() => {
    getRelationUsers(currentRelation!.id)
  }, [])

  return (
    <ScreenWrapper>
      <div className='flex flex-col  md:w-1/3 w-full'>
        <button className={'rounded-md bg-brand text-white w-full'}>
          <Link
            to='/mijn-profiel/gebruikers-beheren/uitnodigen'
            className='flex items-center justify-center'
          >
            <p className='py-4 text-white'>Gebruiker uitnodigen</p>
          </Link>
        </button>

        {relationUsers.map(user => (
          <UserCard
            {...user}
            key={user.id}
          />
        ))}
      </div>
    </ScreenWrapper>
  )
}

export default ManageMembersScreen
