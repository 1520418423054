import React, { useEffect } from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import useSupport from '@verzekeringapp-mono/shared/src/hooks/use_support'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getAboutNH as getAboutNHSelector } from '@verzekeringapp-mono/shared/src/state/selectors/support'

const AboutNH1816Screen: React.FC = () => {
  const { getAboutNH } = useSupport()

  const aboutNH = useAppSelector(getAboutNHSelector)

  useEffect(() => {
    getAboutNH()
  }, [])

  return (
    <ScreenWrapper
      breadCrumbsOverride={[
        {
          label: 'Meer',
          path: '/meer'
        },
        {
          label: 'Nh1816 verzekeringen',
          path: '/meer/nh1816-verzekeringen'
        }
      ]}
    >
      <div className='bg-white w-full rounded p-8'>
        <div
          className='html-content'
          dangerouslySetInnerHTML={{ __html: aboutNH }}
        />
      </div>
    </ScreenWrapper>
  )
}

export default AboutNH1816Screen
