export interface IPolicy {
  id: string
  productName: string
  policyNumber: string
  productId: number
  status: string
  pendingMutations: boolean
  greenCard: string
  greenCards: IGreenCard[]
  updatedAt: string
  premium: string
  cancellationDate: string | null
  details: IPolicyDetail[]
  fetchedAt?: string
  isAllowedToReportClaim: boolean
  minimumDateOfLoss: string
  maximumDateOfLoss: string | null
  branche: string
  commenceDate: string
  clauses?: IClause[]
  conditions?: ICondition[]
  insuranceCards?: IInsuranceCard[]
}

export interface IGreenCard {
  policyIdPullingVehicle: string
  policyIdTowedVehicle: string
  policyNumberPullingVehicle: string
  policyNumberTowedVehicle: string
  pullingVehicleLicensePlate: string
  relationNumber: string
  towedVehicleLicensePlate: string
  url: string
}

export interface IClause {
  description: string
  id: string
  name: string
}

export interface ICondition {
  description: string
  id: string
  url: string
}

export interface IInsuranceCard {
  description: string
  id: string
  url: string
}

export interface IPolicyDetail {
  code: string
  followNumber: number
  id: string
  important: boolean
  isCoverage: boolean
  label: string
  name: string
  type: PolicyDetailType
  value: string | boolean
  showApp: boolean
}

export enum PolicyDetailType {
  Attribute = 'attribute',
  AcceptanceQuestion = 'acceptanceQuestion',
  Coverage = 'coverage'
}
