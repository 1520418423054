import React, { useEffect } from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import LocationFilterModal from './components/LocationFilterModal'
import TabNavigation from '../../components/TabNavigation'
import useCompanies from '@verzekeringapp-mono/shared/src/hooks/use_companies'
import ListView from './components/ListView'
import MapView from './components/MapView'

const DamageRepairVehicleScreen = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(true)
  const { getCategories } = useCompanies()
  const [ref, setRef] = React.useState<google.maps.Map | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)

  useEffect(() => {
    setIsLoading(true)
    getCategories().finally(() => {
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      <ScreenWrapper
        hasPadding={false}
        breadCrumbsOverride={[]}
      >
        <TabNavigation
          tabs={[
            {
              name: 'Kaart',
              value: 'map',
              component: (
                <MapView
                  setMapRef={setRef}
                  setIsFilterModalOpen={setIsModalOpen}
                />
              )
            },
            {
              name: 'Lijst',
              value: 'list',
              component: <ListView setIsFilterModalOpen={setIsModalOpen} />
            }
          ]}
        />
      </ScreenWrapper>

      {isModalOpen && !isLoading && (
        <LocationFilterModal
          mapRef={ref}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

export default DamageRepairVehicleScreen
