import { INavigationTileItem } from '../../models/navigation_tile_item'

export const profileTiles = (isMainUser: boolean): INavigationTileItem[] => {
  const mainUserTiles: INavigationTileItem[] = [
    {
      icon: 'user-lock',
      title: 'Inloggegevens wijzigen',
      href: '/mijn-profiel/inloggegevens-wijzigen'
    },
    {
      icon: 'address-card',
      title: 'Gegevens wijzigen',
      href: '/mijn-profiel/gegevens-wijzigen'
    },
    {
      icon: 'cog',
      title: 'Voorkeuren',
      href: '/mijn-profiel/voorkeuren'
    },
    {
      icon: 'user-plus',
      title: 'Gebruikers beheren',
      href: '/mijn-profiel/gebruikers-beheren'
    },

    {
      icon: 'file-alt',
      title: 'Selecteer combipolis',
      href: '/mijn-profiel/combipolis-selecteren'
    }
  ]

  const subUserTiles: INavigationTileItem[] = [
    {
      icon: 'user-lock',
      title: 'Inloggegevens wijzigen',
      href: '/mijn-profiel/inloggegevens-wijzigen'
    },
    {
      icon: 'address-card',
      title: 'Gegevens wijzigen',
      href: '/mijn-profiel/gegevens-wijzigen'
    },
    {
      icon: 'file-alt',
      title: 'Selecteer combipolis',
      href: '/mijn-profiel/combipolis-selecteren'
    }
  ]

  return isMainUser ? mainUserTiles : subUserTiles
}
