import { useAppDispatch } from './state'
import { getAgency as getAgencyAPI } from '../api/agency'
import { setAgency } from '../state/slices/agency_slice'
import * as Sentry from '@sentry/react'

export const useAgency = () => {
  const dispatch = useAppDispatch()

  const getAgency = async (id: string): Promise<boolean> => {
    const result = await getAgencyAPI(id)

    if (!result.success) {
      console.warn(result.error)

      Sentry.captureException(result.error)
    }

    if (result.data) {
      dispatch(setAgency(result.data))
    }

    return result.success
  }

  return {
    getAgency
  }
}

export default useAgency
