import { Images } from '@verzekeringapp-mono/shared/src/assets'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

const NotActivated = ({
  setCheckEmailStatus
}: {
  setCheckEmailStatus: (status: string) => void
}) => {
  const navigate = useNavigate()
  const { watch } = useFormContext()
  const email = watch('email')

  return (
    <>
      <img
        src={Images.Logo}
        width={180}
        height={70}
      />

      <p className='title mt-8'>Gevonden!</p>

      <p className='description mt-4'>
        We hebben uw gegevens gevonden en een e-mail gestuurd naar:
        <br />

        <span className='font-bold'> {email} </span>
      </p>

      <p className='description mt-4'>
        In deze e-mail vindt u alle informatie om in te loggen.
      </p>

      <p className='description mt-4'>
        Controleer ook uw spambox en ongewenste e-mail.
      </p>

      <button
        className='secondary-btn w-full mt-4'
        onClick={() => {
          setCheckEmailStatus('')
          navigate('/home')
        }}
      >
        Terug
      </button>
    </>
  )
}

export default NotActivated
