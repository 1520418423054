import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getRepairersSelector } from '@verzekeringapp-mono/shared/src/state/selectors/companies'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import LocationCard from './LocationCard'

interface IListViewProps {
  setIsFilterModalOpen: (isOpen: boolean) => void
}

const ListView = ({ setIsFilterModalOpen }: IListViewProps) => {
  const locations = useAppSelector(getRepairersSelector)

  return (
    <div className='px-4'>
      <div className='flex flex-row justify-between items-center my-8'>
        <p>{locations.length} resultaten</p>

        <button
          onClick={() => setIsFilterModalOpen(true)}
          className='p-4 rounded-md bg-brand text-white z-10'
        >
          <FontAwesomeIcon
            icon='sliders'
            className='mr-2'
            color={Colors.white}
          />
          Filter
        </button>
      </div>

      {locations.map((location) => (
        <LocationCard
          key={location.name}
          location={location}
        />
      ))}
    </div>
  )
}

export default ListView
