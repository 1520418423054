import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'

const _getState = (state: RootState) => state.memberBenefits

export const getMemeberBenefits = createSelector(
  _getState,
  (state) => (
    state.memberBenefits
  )
)

export const getMemeberBenefit = (id: string) => createSelector(
  _getState,
  (state) => (
    state.memberBenefits.find(memberBenefit => `${memberBenefit.id}` === id)
  )
)
