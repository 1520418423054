import React, { useCallback, useEffect } from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { useClaims } from '@verzekeringapp-mono/shared/src/hooks/use_claims'
import {
  useAppDispatch,
  useAppSelector
} from '@verzekeringapp-mono/shared/src/hooks/state'
import { getClaim as getClaimSelector } from '@verzekeringapp-mono/shared/src/state/selectors/claims'
import { useNavigate, useParams } from 'react-router-dom'
import { getUser } from '@verzekeringapp-mono/shared/src/state/selectors/user'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  setClaimDamageForm,
  setConceptClaim
} from '@verzekeringapp-mono/shared/src/state/slices/claims_slice'
import { setSelectedPolicyNumber } from '@verzekeringapp-mono/shared/src/state/slices/policies_slice'
import { format } from 'date-fns'
import { useThemeColorContext } from '../../../providers/ThemeColorProvider'

const ClaimDetailScreen: React.FC = () => {
  const { id } = useParams()
  const { getClaim, deleteClaim } = useClaims()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const claim = useAppSelector(getClaimSelector(id ?? ''))
  const user = useAppSelector(getUser)
  const currentRelation = useAppSelector(getCurrentRelation)
  const { brandColors } = useThemeColorContext()

  useEffect(() => {
    if (claim && claim?.history === false) {
      getClaim(claim.hash || '')
    }
  }, [])

  const onPressRemove = useCallback(async () => {
    const confirmed = window.confirm(
      'Weet u zeker dat u de concept schademelding wilt verwijderen?'
    )

    if (confirmed) {
      const success = await deleteClaim(claim?.temporaryClaimReference || '')

      if (success) {
        navigate('/dossier/mijn-schades')
      }
    }
  }, [claim, deleteClaim])

  if (!claim) {
    return null
  }

  return (
    <ScreenWrapper
      breadCrumbsOverride={[
        { label: 'Dossier', path: '/dossier' },
        { label: 'Mijn schades', path: '/dossier/mijn-schades' },
        { label: 'Claim', path: '/claims' }
      ]}
      hasPadding={false}
    >
      <div className='bg-white p-8 rounded h-full flex flex-col gap-4'>
        <div className='flex justify-between'>
          <div>
            <p className='text-lg'>Polis</p>

            <p className='text-textGray'>{claim?.productName}</p>
          </div>

          <div className='flex gap-2'>
            <div
              className='flex items-center justify-center px-4 rounded-full'
              style={{ backgroundColor: claim?.statusColor }}
            >
              <p className='paragraph-light text-white'>{claim?.status}</p>
            </div>

            {claim?.status === 'Concept' && (
              <button onClick={onPressRemove}>
                <FontAwesomeIcon
                  icon='trash'
                  color={brandColors.brand}
                />
              </button>
            )}
          </div>
        </div>

        <p>Datum waarop de schade is ontstaan</p>

        <p className='text-sm text-textGray'>
          {claim?.dateOfLoss
            ? format(new Date(claim.dateOfLoss), 'dd-MM-yyyy')
            : '-'}
        </p>

        <p>Uw omschrijving van de schade</p>

        <p className='paragraph-light'>{claim?.text}</p>

        <p>Uw telefoonnummer</p>

        <p className='paragraph-light'>{claim.phoneNumber ?? '-'}</p>

        <p>Bijlage(n)</p>

        {claim?.files?.map(file => (
          <a
            key={file.url}
            href={`${file.url}?token=${user?.token}&user_id=${user?.id}&relation_id=${currentRelation?.id}&relation_number=${currentRelation?.relationNumber}`}
            target='_blank'
            rel='noreferrer'
          >
            {`- ${file.title}`}
          </a>
        ))}

        {claim?.status === 'Concept' && (
          <button
            onClick={() => {
              dispatch(setConceptClaim(claim))
              dispatch(setSelectedPolicyNumber(claim.policyNumber))
              dispatch(
                setClaimDamageForm({
                  policyId: claim.policyNumber,
                  date: claim.dateOfLoss,
                  description: claim.text,
                  phoneNumber: claim.phoneNumber,
                  attachments: claim.files?.map(file => ({
                    title: file.title,
                    url: file.url,
                    size: file.size,
                    extension: file.extension
                  }))
                })
              )

              navigate('/schade-melden/schadeformulier')
            }}
            className='primary-btn w-full md:w-1/2'
          >
            Schademelding afronden
          </button>
        )}
      </div>
    </ScreenWrapper>
  )
}

export default ClaimDetailScreen
