import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IITXResponse } from '../../api/types/idin'
import { logout, selectRelation } from '../actions/constants'

export interface IIdinState {
  entranceCode: string | null
  debtorReference: string | null
  requestType: string | null
  transactionID: string | null
  transactionURL: string | null
  shortTransactionURL: string | null
  idinResultStatus?: 'SUCCESS' | 'ERROR'
}

const initialState: IIdinState = {
  entranceCode: null,
  debtorReference: null,
  requestType: null,
  transactionID: null,
  transactionURL: null,
  shortTransactionURL: null,
  idinResultStatus: undefined
}

export const idinSlice = createSlice({
  name: 'idin',
  initialState,
  reducers: {
    setIdin: (state, action: PayloadAction<IITXResponse>) => {
      state.entranceCode = action.payload.entranceCode
      state.debtorReference = action.payload.debtorReference
      state.requestType = action.payload.requestType
      state.transactionID = action.payload.transactionID
      state.transactionURL = action.payload.transactionURL
      state.shortTransactionURL = action.payload.shortTransactionURL
    },
    setIdinResultStatus: (
      state,
      action: PayloadAction<'SUCCESS' | 'ERROR' | undefined>
    ) => {
      state.idinResultStatus = action.payload
    },
    resetIdin: (state) => {
      state.entranceCode = initialState.entranceCode
      state.debtorReference = initialState.debtorReference
      state.requestType = initialState.requestType
      state.transactionID = initialState.transactionID
      state.transactionURL = initialState.transactionURL
      state.shortTransactionURL = initialState.shortTransactionURL
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, () => initialState)
  }
})

export const { setIdin, resetIdin, setIdinResultStatus } = idinSlice.actions

export default idinSlice.reducer
