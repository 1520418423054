import {
  useAppDispatch,
  useAppSelector
} from '@verzekeringapp-mono/shared/src/hooks/state'
import useClaims from '@verzekeringapp-mono/shared/src/hooks/use_claims'
import {
  getClaimDamageForm,
  getConceptClaim
} from '@verzekeringapp-mono/shared/src/state/selectors/claims'
import { getSelectedPolicy } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import { setCurrentStep } from '@verzekeringapp-mono/shared/src/state/slices/claims_slice'
import React, { useCallback, useState } from 'react'
import formatPhoneNumber from '@verzekeringapp-mono/shared/src/utils/formatPhoneNumber'
import Button from '../../../components/Button'
import formatToApiDate from '../../../utils/formatToApiDate'
import CheckboxInput from '../../../components/CheckboxInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ClaimDamageOverview = () => {
  const selectedPolicy = useAppSelector(getSelectedPolicy)

  const claimDamageForm = useAppSelector(getClaimDamageForm)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const dispatch = useAppDispatch()
  const { storeClaim, createClaimConcept } = useClaims()
  const currentRelation = useAppSelector(getCurrentRelation)
  const conceptClaim = useAppSelector(getConceptClaim)
  const [isLoading, setIsLoading] = useState(false)
  const onSendClaim = useCallback(async () => {
    if (conceptClaim && conceptClaim.temporaryClaimReference) {
      setIsLoading(true)
      await storeClaim({
        id: conceptClaim.temporaryClaimReference,
        text: claimDamageForm.description || '',
        phoneNumber: claimDamageForm.phoneNumber
          ? formatPhoneNumber(claimDamageForm.phoneNumber)
          : '',
        dateOfLoss: formatToApiDate(claimDamageForm.date),
        policyNumber: claimDamageForm.policyId
      }).finally(() => {
        setIsLoading(false)
      })
    } else {
      setIsLoading(true)
      await createClaimConcept({
        policyNumber: selectedPolicy!.policyNumber,
        agent_number: currentRelation!.agentNumber,
        relation_number: Number(currentRelation!.relationNumber),
        branche: selectedPolicy!.branche
      }).then(async claim => {
        storeClaim({
          id: claim.id,
          text: claimDamageForm.description || '',
          phoneNumber: claimDamageForm.phoneNumber
            ? formatPhoneNumber(claimDamageForm.phoneNumber)
            : '',
          dateOfLoss: formatToApiDate(claimDamageForm.date),
          policyNumber: claimDamageForm.policyId
        }).finally(() => {
          setIsLoading(false)
        })
      })
    }
  }, [conceptClaim, claimDamageForm, selectedPolicy, currentRelation])

  return (
    <div className='mx-auto p-8 bg-white rounded-lg lg:w-5/6 w-full'>
      <h2 className='text-3xl font-medium text-gray mb-4'>Overzicht</h2>

      <p className='text-gray-600 mb-6'>
        Nog even een laatste check, controleer of alle velden nauwkeurig zijn
        ingevuld. Dit helpt uw adviseur bij het goed kunnen afwikkelen van de
        schade.
      </p>

      <div className='mb-4'>
        <p className='text-gray font-medium'>Polis</p>

        <p className='text-gray-700'>{claimDamageForm.policyId}</p>
      </div>

      <div className='mb-4'>
        <p className='text-gray font-medium'>Datum waarop schade is ontstaan</p>

        <p className='text-gray-700'>{claimDamageForm.date}</p>
      </div>

      <div className='mb-4'>
        <p className='text-gray font-medium'>
          Geef een gedetailleerde omschrijving van de schade
        </p>

        <p className='text-gray-700'>{claimDamageForm.description}</p>
      </div>

      <div className='mb-4'>
        <p className='text-gray font-medium'>Uw telefoonnummer</p>

        {claimDamageForm.phoneNumber && (
          <p className='text-gray-700'>
            {formatPhoneNumber(claimDamageForm.phoneNumber)}
          </p>
        )}
      </div>

      <div className='mb-4'>
        <p className='text-gray font-medium'>
          {claimDamageForm?.attachments &&
          claimDamageForm.attachments.length > 0
            ? 'Bijlage(n)'
            : 'Geen bijlagen'}
        </p>

        {claimDamageForm.attachments?.map((attachment, index) => (
          <p
            key={index}
            className='text-gray-700'
          >
            {attachment.title}
          </p>
        ))}
      </div>

      <div className='flex items-center justify-center mb-6'>
        <CheckboxInput
          checked={isConfirmed}
          onChange={e => setIsConfirmed(e.target.checked)}
          label='Ik bevestig dat mijn ingevulde gegevens correct en naar waarheid zijn
          ingevuld. Bij doorzetting door mijn adviseur naar Nh1816 Verzekeringen
          wordt deze schademelding geregistreerd bij stichting CIS.'
        />
      </div>

      <div className='flex justify-between'>
        <Button
          variant='outline'
          onClick={() => {
            dispatch(setCurrentStep(1))
          }}
        >
          <FontAwesomeIcon
            icon={['fal', 'chevron-left']}
            className='mr-2'
          />
          Vorige
        </Button>

        <Button
          onClick={onSendClaim}
          disabled={!isConfirmed}
          isLoading={isLoading}
        >
          <FontAwesomeIcon
            icon={['fal', 'paper-plane']}
            className='mr-2'
          />
          Opsturen
        </Button>
      </div>
    </div>
  )
}

export default ClaimDamageOverview
