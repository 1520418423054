import React from 'react'
import NavigationTile from './navigation_tile'
import { INavigationTileItem } from '@verzekeringapp-mono/shared/src/models/navigation_tile_item'

interface IProps {
  tiles: INavigationTileItem[]
}

const NavigationTileGrid: React.FC<IProps> = props => (
  <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 grid-cols-1'>
    {props.tiles.map((tile, index) => (
      <NavigationTile
        key={`tile-${index}`}
        tile={tile}
      />
    ))}
  </div>
)

export default NavigationTileGrid
