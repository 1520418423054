import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'
import { getProducts } from './products'
import { decode } from 'html-entities'
import { IPolicy } from '../../models/policy'
import { getLicensePlate } from '../../utils/policy_utils'
const _getState = (state: RootState) => state.policies

export const getPolicies = createSelector(_getState, state => state.policies)
export const PULLING_VEHICLE_BRANCHES = ['02101', '02150', '02700', '02900']
export const PULLABLE_VEHICLE_BRANCHES = ['02200', '03200']

export const getPolicyById = (id: string) =>
  createSelector(_getState, state =>
    state.policies.find(policy => policy.id === id)
  )
export const getSelectedPolicy = createSelector(_getState, state =>
  state.policies.find(policy => policy.policyNumber === state.selectedPolicyId)
)

export const getGreencardPolicies = createSelector(_getState, state =>
  state.policies.filter(policy => !!policy.greenCard)
)

export const getProductClaimType = createSelector(
  getSelectedPolicy,
  getProducts,
  (policy, products) =>
    products.find(product => product.id === policy?.productId.toString())
)

export const getClaimMethod = createSelector(
  getSelectedPolicy,
  getProductClaimType,
  (policy, product) => {
    const claimMethod = product?.branches?.find(
      x => String(x.brancheCode) === String(policy?.branche)
    )

    return {
      ...claimMethod,
      description: decode(claimMethod?.description ?? '')
    }
  }
)

export const getPoliciesUnexpelled = createSelector(getPolicies, policies =>
  (policies || []).filter(({ status }) => status !== 'Geroyeerd')
)

export const getLastThreeUnexpelledPolicies = createSelector(
  getPoliciesUnexpelled,
  policies => policies.slice(0, 3)
)

export const getIsPolicyPullable = (policyId: string) =>
  createSelector(
    getPolicyById(policyId),
    policy =>
      policy &&
      PULLABLE_VEHICLE_BRANCHES.includes(policy.branche) &&
      policy?.status === 'Lopend' &&
      getLicensePlate(policy?.details)
  )

export const getPoliciesActivePullingVehicles = createSelector(
  getPolicies,
  policies =>
    (policies || []).filter(
      ({ branche, status }) =>
        PULLING_VEHICLE_BRANCHES.includes(branche) && status === 'Lopend'
    )
)

export const getIsPullablePolicyGeneratable = (policyId: string) =>
  createSelector(
    getPolicyById(policyId),
    getPoliciesActivePullingVehicles,
    (policy, policiesActivePullingVehicles) =>
      !!(
        getIsPolicyPullable(policyId) &&
        policy?.greenCards?.length &&
        policy.greenCards.length < policiesActivePullingVehicles.length
      )
  )

export const getPoliciesExpelled = createSelector(getPolicies, policies =>
  (policies || []).filter(({ status }) => status === 'Geroyeerd')
)
