import React from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'

const PolicyAdviceRequested = () => {
  const navigate = useNavigate()

  const goToOverview = () => navigate('/verzekeringen')

  return (
    <ScreenWrapper hasPadding={false}>
      <div className='flex flex-col items-center my-8 px-8 lg:w-2/3 w-full lg:mx-auto'>
        <p className='title mb-8'>Advies aangevraagd</p>

        <p className='text-center'>
          Uw verzekeringsadviseur zal uw aanvraag in behandeling nemen en voor
          meer informatie contact opnemen. Heeft u tussentijds vragen? Neem
          contact op met uw adviseur.
        </p>

        <Button
          className='mt-8 w-full'
          onClick={goToOverview}
          variant='outline-brand'
        >
          Terug naar overzicht
        </Button>
      </div>
    </ScreenWrapper>
  )
}

export default PolicyAdviceRequested
