export const LOGOUT = 'LOGOUT'

export const SELECT_RELATION = 'SELECT_RELATION'

export type CompanyType = 'car' | 'car-window' | 'recreational-vehicle'

export const COMPANY_TYPE_MAPPINGS: { [key in CompanyType]?: string[] } = {
  car: ['Motor', 'Bestelauto', 'Vracht(Bestel)auto', 'Personenauto'],
  'recreational-vehicle': ['Camper', 'Caravan / Vouwwagen']
}

export const NH_LAT_LNG = { lat: 52.1326, lng: 5.2913 }
