import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { useForm } from 'react-hook-form'
import useRelation from '@verzekeringapp-mono/shared/src/hooks/use_relation'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AddCombiPolicyScreen: React.FC = () => {
  const { addRelationInvite } = useRelation()
  const navigate = useNavigate()

  const [openInfo, setOpenInfo] = React.useState<string[]>([])

  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      combiPolicyNumber: '',
      email: ''
    },
    mode: 'onChange'
  })

  const onSubmit = async (combiPolicyNumber: string, email: string) => {
    const result = await addRelationInvite(combiPolicyNumber, email)

    if (result) {
      window.alert(
        'Er wordt een bevestigingsmail verstuurd naar het opgegeven e-mailadres (check ook spam). Na “akkoord” gegeven in deze e-mail, zal de combipolis na één dag zichtbaar zijn in uw account.'
      )
      navigate('/mijn-profiel')
    }
  }

  return (
    <ScreenWrapper>
      <div className='bg-white h-full w-full rounded flex flex-col p-8'>
        <p className='subtitle mb-4'>Combipolis toevoegen</p>

        <form
          className='flex flex-col gap-8 w-1/2'
          onSubmit={handleSubmit(data =>
            onSubmit(data.combiPolicyNumber || '', data.email || '')
          )}
        >
          <div>
            <label
              htmlFor='combiPolicyNumber'
              className='label mt-4 cursor-pointer flex justify-center flex-col w-fit'
              onClick={() =>
                setOpenInfo(prev =>
                  prev.includes('combiPolicyNumber')
                    ? prev.filter(item => item !== 'combiPolicyNumber')
                    : [...prev, 'combiPolicyNumber']
                )
              }
            >
              <span>
                Combipolisnummer &nbsp;
                <FontAwesomeIcon
                  icon={'info-circle'}
                  className='text-textGray'
                />
              </span>

              {openInfo.includes('combiPolicyNumber') && (
                <div className='p-4 bg-[#EDEDE9] rounded-lg my-2 text-gray font-light'>
                  Het combipolisnummer vindt u bovenaan uw polisblad of kunt u
                  opvragen bij uw verzekeringsadviseur.
                </div>
              )}
            </label>

            <input
              className='textfield mt-2'
              type='text'
              id='combiPolicyNumber'
              {...register('combiPolicyNumber', { required: true })}
            />
          </div>

          <div>
            <label
              htmlFor='email'
              className='label mt-4 cursor-pointer flex justify-center flex-col w-fit'
              onClick={() =>
                setOpenInfo(prev =>
                  prev.includes('email')
                    ? prev.filter(item => item !== 'email')
                    : [...prev, 'email']
                )
              }
            >
              <span>
                E-mailadres &nbsp;
                <FontAwesomeIcon
                  icon={'info-circle'}
                  className='text-textGray'
                />
              </span>

              {openInfo.includes('email') && (
                <div className='p-4 bg-[#EDEDE9] rounded-lg my-2 text-gray font-light'>
                  Het e-mailadres dat is gekoppeld aan het combipolisnummer. Op
                  dit e-mailadres ontvangt u een verificatiemail.
                </div>
              )}
            </label>

            <input
              className='textfield mt-2'
              type='email'
              id='email'
              {...register('email', { required: true })}
            />
          </div>

          <button
            type='submit'
            disabled={!isValid}
            className={`rounded-md bg-brand text-white w-full ${
              isValid ? '' : 'opacity-50 cursor-not-allowed'
            } hover:bg-opacity-80`}
          >
            <p className='py-4 text-white'>Verifiëren</p>
          </button>
        </form>
      </div>
    </ScreenWrapper>
  )
}

export default AddCombiPolicyScreen
