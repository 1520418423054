import React, { useState } from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import EmailForm from './components/email_form'
import PasswordForm from './components/password_form'

const ManageLoginScreen: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('email')

  return (
    <ScreenWrapper>
      <div className='bg-white h-full w-full rounded flex flex-col'>
        <div className='flex flex-row w-1/2 bg-lightestGray'>
          <button
            className={`flex flex-1 p-4 justify-center ${
              selectedTab === 'email' ? 'border-b-2' : ''
            } border-brand`}
            onClick={() => setSelectedTab('email')}
          >
            Email
          </button>

          <button
            className={`flex flex-1 p-4 justify-center ${
              selectedTab === 'password' ? 'border-b-2' : ''
            } border-brand`}
            onClick={() => setSelectedTab('password')}
          >
            Wachtwoord
          </button>
        </div>

        <div className='p-8'>

          {selectedTab === 'email' ? <EmailForm /> : <PasswordForm />}
        </div>

      </div>
    </ScreenWrapper>
  )
}

export default ManageLoginScreen
