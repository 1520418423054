import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import packageJson from '../../../../package.json'
import Bowser from 'bowser'

const AboutAppScreen: React.FC = () => {

  const browser = Bowser.getParser(window.navigator.userAgent)
  const osInfo = browser.getOS()

  return (
    <ScreenWrapper>
      <div className='bg-white h-full w-full rounded p-8'>
        <div className='flex flex-col gap-4'>
          <p>Applicatieversie: {packageJson.version}</p>

          <p>{`Besturingssysteem: ${osInfo.name} ${osInfo.version}`}</p>

          <p>Deze app is eigendom van Nh1816. Nh1816 is een Coöperatieve verzekeraar, gevestigd in Oudkarspel.</p>
        </div>
      </div>
    </ScreenWrapper>
  )
}

export default AboutAppScreen
