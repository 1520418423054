import React from 'react'

const getGreeting = () => {
  const today = new Date()
  const curHr = today.getHours()

  if (curHr < 12) {
    return 'Goedemorgen'
  } else if (curHr < 18) {
    return 'Goedemiddag'
  } else {
    return 'Goedenavond'
  }
}

const UserInfo: React.FC<{ firstName: string }> = ({ firstName }) => (
  <div className='col-span-2'>
    <p className='text-2xl font-light text-textGray'>{getGreeting()},</p>

    <p className='text-3xl font-semibold'>{firstName}</p>

    <p className='mt-4 leading-loose text-gray md:block hidden'>
      Welkom in uw omgeving. Hierin vindt u een overzicht van uw
      polis/verzekeringen, kunt u gemakkelijk en snel schade melden, een
      schadehersteller vinden, contact opnemen met de adviseur en zijn er ook
      nog leuke ledenvoordelen.
    </p>
  </div>
)

export default UserInfo
