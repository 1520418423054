import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useThemeColorContext } from '../../../providers/ThemeColorProvider'

const NavigationButton: React.FC<{
  text: string
  icon: IconProp
  href?: string
}> = ({ text, icon, href }) => {
  const { brandColors } = useThemeColorContext()

  return (
    <button className='w-full'>
      <a
        href={href}
        className='flex items-center h-[62px] w-full border rounded-md border-borderColor pl-4 mb-4'
      >
        <div className='w-[34px] h-[34px] bg-lightBrand rounded-[17px] flex justify-center items-center mr-4'>
          <FontAwesomeIcon
            icon={icon}
            color={brandColors.brand}
          />
        </div>

        <p>{text}</p>
      </a>
    </button>
  )
}

export default NavigationButton
