import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { logout, selectRelation } from '../actions/constants'
import { IProduct } from '../../models/product'

export interface IProductsState {
  products: IProduct[]
  isLoading: boolean
}

const initialState: IProductsState = {
  products: [],
  isLoading: false
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<IProduct[]>) => {
      state.products = action.payload
    },
    setProduct: (state, action: PayloadAction<IProduct>) => {
      const index = state.products.findIndex(
        product => product.id === action.payload.id
      )

      if (index !== -1) {
        state.products[index] = action.payload
      } else {
        state.products.push(action.payload)
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, () => initialState)
  }
})

export const { setProducts, setProduct, setIsLoading } = productsSlice.actions

export default productsSlice.reducer
