import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICompany } from '@verzekeringapp-mono/shared/src/api/types/company'
import React from 'react'

interface IProps {
  location: ICompany
}

const LocationCard = ({ location }: IProps) => (
  <div className='bg-white flex items-center mt-4 p-4 rounded-md justify-between'>
    <div className='flex gap-4 items-center'>
      <div className='flex justify-center items-center h-10 w-10 bg-lightBrand rounded-full p-8'>
        <span className='text-center text-brand'>
          {location.distance &&
            Intl.NumberFormat('nl-NL', {
              style: 'unit',
              unit: 'kilometer',
              maximumFractionDigits: 2,
              unitDisplay: 'short'
            }).format(location.distance)}
        </span>
      </div>

      <div>
        <h2 className='text-base font-bold'>{location.name}</h2>

        <p className='text-sm text-textGray'>{location.street}</p>

        <p className='text-sm text-textGray'>
          {location.zipCode}
          &nbsp;
          {location.city}
        </p>
      </div>
    </div>

    <div className='flex flex-col gap-4 '>
      <div className='bg-brand rounded-full p-2 flex items-center justify-center'>
        <a
          href={`tel:${location.phoneNumber}`}
          className='text-white h-4 w-4 flex items-center justify-center'
        >
          <FontAwesomeIcon icon='phone' />
        </a>
      </div>

      <div className='bg-brand rounded-full p-2 flex items-center justify-center'>
        <a
          href={`mailto:${location.email}`}
          className='text-white h-4 w-4 flex items-center justify-center'
        >
          <FontAwesomeIcon icon='envelope' />
        </a>
      </div>

      <div className='bg-brand rounded-full p-2 flex items-center justify-center'>
        <a
          href={
            location.website?.startsWith('http')
              ? location.website
              : `http://${location.website}`
          }
          className='text-white h-4 w-4 flex items-center justify-center'
          target='_blank'
          rel='noreferrer'
        >
          <FontAwesomeIcon icon='globe' />
        </a>
      </div>
    </div>
  </div>
)

export default LocationCard
