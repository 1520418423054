import * as Sentry from '@sentry/react'
import React from 'react'
import Navigator from './navigation/navigator'

import { initializeIcons } from '@verzekeringapp-mono/shared/src/utils/initialize_icons'
import EmergencyModalProvider from './providers/EmergencyModalProvider'
import ThemeColorProvider from './providers/ThemeColorProvider'
import { initialize } from '@verzekeringapp-mono/shared/src/utils/firebase'

initializeIcons()

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [],
  tracesSampleRate: 0.2,
  environment: process.env.REACT_APP_APP_ENV
})

initialize()

function App () {
  return (
    <EmergencyModalProvider>
      <ThemeColorProvider>
        <Navigator />
      </ThemeColorProvider>
    </EmergencyModalProvider>
  )
}

export default App
