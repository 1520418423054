import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IHelpQuestion } from '@verzekeringapp-mono/shared/src/models/help_question'
import React, { useState } from 'react'
import { useThemeColorContext } from '../providers/ThemeColorProvider'

interface IProps {
  question: IHelpQuestion
}

const HelpQuestionToggle: React.FC<IProps> = props => {
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const { question } = props
  const { brandColors } = useThemeColorContext()

  return (
    <div>
      <div
        key={props.question.id}
        onClick={() => setShowMoreInfo(!showMoreInfo)}
        className='flex flex-row mt-8 justify-between items-start font-medium cursor-pointer'
      >
        <p className='font-medium'>{question.text}</p>

        <button className='ml-8 w-[40px] h-[40px] aspect-square flex justify-center items-center bg-lightBrand rounded-full'>
          <FontAwesomeIcon
            icon='plus'
            size='lg'
            color={brandColors.brand}
          />
        </button>
      </div>

      {showMoreInfo && <div className='mt-2 font-light'>{question.answer}</div>}
    </div>
  )
}

export default HelpQuestionToggle
