import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import IBAN from 'iban'
import useRelation from '@verzekeringapp-mono/shared/src/hooks/use_relation'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import Button from '../../../components/Button'
import TextInput from '../../../components/TextInput'

interface FormData {
  phoneNumber: string
  iban: string
  dateOfBirth: string
  city: string
  postalCode: string
  street: string
  houseNumber: string
  houseNumberAddition: string
}

const ManageRelationScreen: React.FC = () => {
  const relation = useAppSelector(getCurrentRelation)
  const relationIsLoading = useAppSelector(state => state.relations.isLoading)
  const hasPendingMutations = relation?.hasPendingMutations
  const { updateRelation } = useRelation()
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      phoneNumber: relation?.phoneNumber,
      iban: relation?.accountNumber,
      dateOfBirth: relation?.dateOfBirth ?? '',
      city: relation?.city ?? '',
      postalCode: relation?.zipCode ?? '',
      street: relation?.street ?? '',
      houseNumber: relation?.houseNumber ?? '',
      houseNumberAddition: relation?.houseNumberAddition
    }
  })

  if (!relation) {
    return null
  }

  const formatZipCode = (zipCode: string): string => {
    if (zipCode.length === 6) {
      return `${zipCode.slice(0, 4)} ${zipCode.slice(4, 6)}`
    }

    return zipCode
  }

  const formatPhoneNumber = (phoneNumber: string): string =>
    phoneNumber.replace(/\D/g, '')

  return (
    <ScreenWrapper>
      <div className='bg-white w-full rounded flex flex-col p-8'>
        {hasPendingMutations && (
          <p>
            De vorige wijzigingen moeten nog worden geaccepteerd door uw
            adviseur
          </p>
        )}

        <form
          onSubmit={handleSubmit(async data => {
            const updates = {
              accountNumber: data.iban,
              city: data.city,
              dateOfBirth: data.dateOfBirth,
              houseNumber: data.houseNumber,
              houseNumberAddition: data.houseNumberAddition,
              street: data.street,
              phoneNumber: formatPhoneNumber(data.phoneNumber ?? ''),
              zipCode: formatZipCode(data.postalCode!)
            }

            const result = await updateRelation(relation.id, relation, updates)

            if (result.data) {
              window.alert(
                'Uw wijziging is ingediend' +
                  '\n\n' +
                  'De wijziging wordt zo spoedig mogelijk door uw adviseur in behandeling genomen. Zodra de wijziging is verwerkt worden de gewijzigde gegevens zichtbaar in de app.'
              )
              navigate('/mijn-profiel')
            } else {
              window.alert(
                result.message ?? 'De gegevens konden niet worden gewijzigd'
              )
            }
          })}
          className='w-2/6'
        >
          <Controller
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                isValid={!fieldState.invalid}
                type='phone'
                placeholder='Telefoonnummer'
                label='Telefoonnummer'
              />
            )}
            disabled={hasPendingMutations}
            name='phoneNumber'
            control={control}
          />

          <Controller
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                isValid={!fieldState.invalid}
                type='text'
                placeholder='IBAN'
                label='IBAN'
              />
            )}
            name='iban'
            control={control}
            rules={{
              validate: value => (value ? IBAN.isValid(value) : true)
            }}
            disabled={hasPendingMutations}
          />

          <Controller
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                isValid={!fieldState.invalid}
                type='date'
                placeholder='Geboortedatum'
                label='Geboortedatum'
              />
            )}
            name='dateOfBirth'
            control={control}
            rules={{ required: true }}
            disabled={hasPendingMutations}
          />

          <Controller
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                isValid={!fieldState.invalid}
                type='text'
                placeholder='Plaats'
                label='Plaats'
              />
            )}
            name='city'
            control={control}
            rules={{ required: true }}
            disabled={hasPendingMutations}
          />

          <Controller
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                isValid={!fieldState.invalid}
                type='text'
                placeholder='Postcode'
                label='Postcode'
              />
            )}
            name='postalCode'
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^\d{4}\s?[a-zA-Z]{2}$/,
                message: 'Ongeldige postcode'
              }
            }}
            disabled={hasPendingMutations}
          />

          <Controller
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                isValid={!fieldState.invalid}
                type='text'
                placeholder='Straat'
                label='Straat'
              />
            )}
            name='street'
            control={control}
            rules={{ required: true }}
            disabled={hasPendingMutations}
          />

          <Controller
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                isValid={!fieldState.invalid}
                type='text'
                placeholder='Huisnummer'
                label='Huisnummer'
              />
            )}
            name='houseNumber'
            control={control}
            rules={{ required: true }}
            disabled={hasPendingMutations}
          />

          <Controller
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                isValid={!fieldState.invalid}
                type='text'
                placeholder='Huisnummer toevoeging'
                label='Huisnummer toevoeging'
              />
            )}
            name='houseNumberAddition'
            control={control}
            disabled={hasPendingMutations}
          />

          <p className='mt-8 mb-4 text-textGray text-sm'>
            Opmerking: uw gegevens worden niet direct gewijzigd. Deze worden
            eerst gecontroleerd door uw adviseur.
          </p>

          <Button
            className={'primary-btn w-full mb-3'}
            type='submit'
            isLoading={relationIsLoading}
            disabled={!isValid}
          >
            Wijziging doorgeven
          </Button>
        </form>
      </div>
    </ScreenWrapper>
  )
}

export default ManageRelationScreen
