import React from 'react'
import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { IUser } from '@verzekeringapp-mono/shared/src/models/user'
import getUserStatus from '@verzekeringapp-mono/shared/src/utils/get_user_status'
import useRelation from '@verzekeringapp-mono/shared/src/hooks/use_relation'

const style = (user: IUser) => {
  if (user.mainUser) return 'bg-white'
  if (getUserStatus(user) === 'active') return 'bg-lightGreen'
  if (getUserStatus(user) === 'pending') return 'bg-lightWaiting'

  return 'bg-lightError'
}
const UserCard = (user: IUser) => {
  const { id, mainUser, email } = user
  const { deleteRelationUser } = useRelation()

  return (
    <Link
      to={`/mijn-profiel/gebruikers-beheren/${id}/wijzigen`}
      key={id}
      className={`flex flex-wrap items-center mt-4 p-4  hover:shadow-md cursor-pointer rounded-md justify-between ${style(
        user
      )}`}
    >
      <div>
        <p className='font-semibold'>
          {mainUser ? 'Hoofdgebruiker' : 'Subgebruiker'}
        </p>

        <p>{email}</p>
      </div>

      <div>
        {mainUser
          ? (
            <div className=' hover:text-waiting text-lightGray'>
              <FontAwesomeIcon
                icon={faPencil}
                size='lg'
              />
            </div>
          )
          : (
            <div className='flex gap-4'>
              <div className=' hover:text-waiting text-lightGray'>
                <FontAwesomeIcon
                  icon={faPencil}
                  size='lg'
                />
              </div>

              <div
                className=' hover:text-red text-lightGray'
                onClick={e => {
                  e.preventDefault()
                  const isConfirmed = confirm(
                    'Weet je zeker dat je deze gebruiker wilt verwijderen?'
                  )

                  if (isConfirmed) {
                    deleteRelationUser(id)
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  size='lg'
                />
              </div>
            </div>
          )}
      </div>
    </Link>
  )
}

export default UserCard
