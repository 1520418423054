import { IMemberBenefit } from '../models/member_benefit'
import Api from './index'

export const getMemberBenefits = () => Api<IMemberBenefit[]>('GET', 'member_benefits')

export const getMemberBenefit = (id: string) => Api<IMemberBenefit>('GET', `member_benefits/${id}`)

export const joinCampaign = (id: string) => Api<IMemberBenefit>('PUT', `member_benefits/${id}`, { memberBenefitId: id })

export const downloadVoucher = (id: string) => Api<IMemberBenefit>('PUT', `member_benefits/${id}`, undefined)
