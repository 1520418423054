import { getUser as getUserApi, updateUser as updateUserApi } from '../api/user'
import { setUser } from '../state/slices/user_slice'
import { IRelation } from '../models/relation'
import { IUser } from '../models/user'
import { useAppDispatch } from './state'

export const useUser = () => {
  const dispatch = useAppDispatch()

  const getUser = async (relationId: IRelation['id'], userId: string) => {
    const result = await getUserApi(relationId, userId)

    if (result.success) {
      dispatch(setUser(result.data))
    }

    return result.success
  }

  const updateUser = async (
    relationId: IRelation['id'],
    userId: string,
    user: IUser,
    updates: Partial<IUser>
  ) => {
    const result = await updateUserApi(relationId, userId, user, updates)

    if (result.success) {
      dispatch(setUser(result.data))
    }

    return result.success
  }

  return {
    getUser,
    updateUser
  }
}

export default useUser
