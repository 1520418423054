import React, { useCallback, useEffect } from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import useRelation from '@verzekeringapp-mono/shared/src/hooks/use_relation'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import useSupport from '@verzekeringapp-mono/shared/src/hooks/use_support'
import useUser from '@verzekeringapp-mono/shared/src/hooks/use_user'
import { getAboutMembership as getAboutMembershipSelector } from '@verzekeringapp-mono/shared/src/state/selectors/support'
import { getUser as getUserSelector } from '@verzekeringapp-mono/shared/src/state/selectors/user'
import { useForm } from 'react-hook-form'
import { updateRelationPreferences } from '@verzekeringapp-mono/shared/src/api/relation'
import { useNavigate } from 'react-router-dom'

const PreferencesScreen: React.FC = () => {
  const currentRelation = useAppSelector(getCurrentRelation)
  const aboutMembership = useAppSelector(getAboutMembershipSelector)
  const user = useAppSelector(getUserSelector)
  const navigate = useNavigate()
  const { getRelation } = useRelation()
  const { getUser } = useUser()
  const { getAboutMembership } = useSupport()
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      cooperationMembership: currentRelation?.cooperationMembership || false,
      receiveMagazine: currentRelation?.zestMagazine || false
    }
  })

  useEffect(() => {
    if (!user?.mainUser) {
      navigate('/mijn-profiel')
    }
  }, [user])

  useEffect(() => {
    if (currentRelation?.id && user?.id) {
      getRelation(currentRelation?.id)
      getAboutMembership()
      getUser(currentRelation.id, user.id)
    }
  }, [currentRelation?.id, user?.id])

  const showCooperationConfirmAlert = (value: boolean) => {
    if (value === false) {
      const confirm = window.confirm(
        'Weet u het zeker? Zonder lidmaatschap ontvangt u geen Zest Magazine en kunt u niet gebruikmaken van leuke ledenvoordelen. Uw verzekeringspakket blijft ongewijzigd'
      )

      if (!confirm) {
        return
      }
      setValue('receiveMagazine', false)
      setValue('cooperationMembership', false)
      onSubmit()
    } else {
      const confirm = window.confirm(
        'Weet u het zeker? U kunt dan gebruikmaken van leuke ledenvoordelen'
      )

      if (!confirm) {
        return
      }
      setValue('cooperationMembership', true)
      onSubmit()
    }
  }

  const showMagazineConfirmAlert = (value: boolean) => {
    if (value === false) {
      const confirm = window.confirm(
        'Weet u het zeker? U ontvangt dan geen Zest Magazine meer'
      )

      if (!confirm) {
        return
      }
      setValue('receiveMagazine', false)
      onSubmit()
    } else {
      setValue('receiveMagazine', true)
    }
  }

  const handleInputChange = useCallback(
    (name: 'cooperationMembership' | 'receiveMagazine', value: boolean) => {
      if (name === 'cooperationMembership') {
        showCooperationConfirmAlert(value)
      }
      if (name === 'receiveMagazine') {
        showMagazineConfirmAlert(value)
      }
    },
    [currentRelation, user]
  )

  const cooperationMembership = watch('cooperationMembership')

  const onSubmit = async () => {
    const cooperationMembershipWatch = watch('cooperationMembership')
    const receiveMagazineWatch = watch('receiveMagazine')
    const data = {
      cooperationMembership: cooperationMembershipWatch,
      zestMagazine: receiveMagazineWatch
    }

    await updateRelationPreferences(currentRelation!.id, data)

    await getRelation(currentRelation!.id)
  }

  return (
    <ScreenWrapper>
      <form
        onSubmit={handleSubmit(data => {
          updateRelationPreferences(currentRelation!.id, data)
        })}
        className='bg-white h-full w-full rounded flex flex-col p-8'
      >
        <div className='flex flex-row'>
          <div className='lg:w-2/6'>
            <p className='text-base font-bold mb-4'>Overig</p>

            <div
              className='text-base text-gray html-content'
              dangerouslySetInnerHTML={{ __html: aboutMembership }}
            />

            <div className='flex flex-row justify-between mt-8'>
              <p>Lidmaatschap</p>

              <label className='inline-flex items-center cursor-pointer'>
                <input
                  {...register('cooperationMembership')}
                  type='checkbox'
                  name='cooperationMembership'
                  className='sr-only peer'
                  checked={cooperationMembership}
                  onChange={e => {
                    handleInputChange('cooperationMembership', e.target.checked)
                  }}
                />

                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-success bg-lightGray"></div>
              </label>
            </div>

            <div className='flex flex-row justify-between mt-8'>
              <p className={`${!cooperationMembership ? 'opacity-50' : ''}`}>
                Zest Magazine ontvangen
              </p>

              <label className='inline-flex items-center cursor-pointer'>
                <input
                  {...register('receiveMagazine')}
                  type='checkbox'
                  name='receiveMagazine'
                  checked={watch('receiveMagazine')}
                  onChange={e => {
                    handleInputChange('receiveMagazine', e.target.checked)
                  }}
                  className={'sr-only peer'}
                  disabled={!cooperationMembership}
                />

                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-success bg-lightGray"></div>
              </label>
            </div>
          </div>
        </div>
      </form>
    </ScreenWrapper>
  )
}

export default PreferencesScreen
