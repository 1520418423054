import {
  useAppDispatch,
  useAppSelector
} from '@verzekeringapp-mono/shared/src/hooks/state'
import {
  getPolicies,
  getSelectedPolicy
} from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import { setSelectedPolicyNumber } from '@verzekeringapp-mono/shared/src/state/slices/policies_slice'
import { determinePolicyDistinctionLabel } from '@verzekeringapp-mono/shared/src/utils/policy_utils'
import React from 'react'
import { useForm } from 'react-hook-form'

const PolicySelector = () => {
  const dispatch = useAppDispatch()
  const policies = useAppSelector(getPolicies)
  const selectedPolicy = useAppSelector(getSelectedPolicy)
  const {
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      policy: selectedPolicy?.policyNumber
    }
  })

  return (
    <div className='mb-6 flex flex-col flex-1'>
      <label
        htmlFor='policy'
        className='w-full text-gray font-medium mb-2'
      >
        Op welke polis wilt u schade melden?
      </label>

      <div className='w-full flex-1'>
        <select
          id='policy'
          {...register('policy', { required: true })}
          onChange={e => {
            dispatch(setSelectedPolicyNumber(e.target.value))
          }}
          className={`w-full border ${
            errors.policy ? 'border-red' : 'border-lightGray'
          } rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-4 text-gray h-full`}
        >
          {policies.map(policy => (
            <option
              key={policy.id}
              selected={selectedPolicy?.policyNumber === policy.policyNumber}
              value={policy.policyNumber}
            >
              {policy.productName}

              &nbsp; ({determinePolicyDistinctionLabel(policy)})
            </option>
          ))}
        </select>

        {errors.policy && (
          <span className='text-red text-sm mt-1 block'>
            Dit veld is verplicht.
          </span>
        )}
      </div>
    </div>
  )
}

export default PolicySelector
