import { useAppDispatch } from './state'
import {
  getMemberBenefits as getMemberBenefitsAPI,
  getMemberBenefit as getMemberBenefitAPI,
  downloadVoucher as downloadVoucherAPI,
  joinCampaign as joinCampaignAPI
} from '../api/member_benefits'
import {
  setMemberBenefits,
  setMemberBenefit
} from '../state/slices/member_benefit_slice'
import { useEffect } from 'react'
import * as Sentry from '@sentry/react'

export const useMemberBenefits = (id?: string) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id) {
      getMemberBenefit(id)
    }
  }, [id])

  const getMemberBenefits = async (): Promise<boolean> => {
    const result = await getMemberBenefitsAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }

    if (result.data) {
      dispatch(setMemberBenefits(result.data))
    }

    return result.success
  }

  const getMemberBenefit = async (id: string): Promise<boolean> => {
    const result = await getMemberBenefitAPI(id)

    if (!result.success) {
      Sentry.captureException(result.error)
    }

    if (result.data) {
      dispatch(setMemberBenefit(result.data))
    }

    return result.success
  }

  const downloadVoucher = async (id: string): Promise<boolean> => {
    const result = await downloadVoucherAPI(id)

    if (!result.success) {
      Sentry.captureException(result.error)
    }

    if (result.data) {
      dispatch(setMemberBenefit(result.data))
    }

    return result.success
  }

  const joinCampaign = async (id: string): Promise<boolean> => {
    const result = await joinCampaignAPI(id)

    if (!result.success) {
      Sentry.captureException(result.error)
    }

    if (result.data) {
      dispatch(setMemberBenefit(result.data))
    }

    return result.success
  }

  return {
    getMemberBenefits,
    getMemberBenefit,
    downloadVoucher,
    joinCampaign
  }
}

export default useMemberBenefits
