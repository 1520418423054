import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import { getPolicies as getPoliciesSelector } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import usePolicies from '@verzekeringapp-mono/shared/src/hooks/use_policies'
import PolicyCard from './components/PolicyCard'
import useProducts from '@verzekeringapp-mono/shared/src/hooks/use_product'

const ClaimDamage = () => {
  const policies = useSelector(getPoliciesSelector)
  const { getPolicies } = usePolicies()
  const { getProducts } = useProducts()

  useEffect(() => {
    getPolicies()
    getProducts()
  }, [])

  return (
    <ScreenWrapper hasPadding={false}>
      <div className='bg-white rounded p-8 sm:w-9/12 md:10/12 m-auto'>
        <div className='flex flex-col items-center'>
          <div className='text-6xl text-brown-500 mb-4'>
            <FontAwesomeIcon
              icon='fragile'
              size='xl'
              color={Colors.brown}
            />
          </div>

          <h1 className='text-3xl text-gray mb-2'>Schade melden</h1>

          <p className='text-center text-gray mb-6'>
            Oei, wat vervelend! U heeft schade opgelopen. We willen u hier graag
            bij helpen. Om u te helpen moeten we wel eerst even weten op welke
            polis u de schade wilt melden.
          </p>
        </div>

        {policies
          .filter(policy => policy.isAllowedToReportClaim)
          .map(policy => (
            <PolicyCard
              key={policy.id}
              {...policy}
            />
          ))}
      </div>
    </ScreenWrapper>
  )
}

export default ClaimDamage
