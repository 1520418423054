import React from 'react'
import { IMemberBenefit } from '@verzekeringapp-mono/shared/src/models/member_benefit'
import useMemberBenefits from '@verzekeringapp-mono/shared/src/hooks/use_member_benefits'

interface IProps {
  memberBenefit: IMemberBenefit
}

const MemberBenefitCampaign: React.FC<IProps> = ({ memberBenefit }) => {
  const { joinCampaign } = useMemberBenefits()

  const joinCampaignButtonPressed = () => {
    joinCampaign(`${memberBenefit.id}`)
  }

  return (
    <div>
      {memberBenefit?.isCampaignParticipant && !memberBenefit.isWinner && (
        <button
          className={'rounded-md bg-brand text-white w-full mb-6'}
          onClick={joinCampaignButtonPressed}
          disabled={memberBenefit?.participant ?? memberBenefit?.isWinner}
        >
          <p className='py-4 text-white'>
            {memberBenefit?.participant ?? memberBenefit?.isWinner
              ? 'Mijn deelname is geregistreerd'
              : 'Ja, ik doe graag mee!'}
          </p>
        </button>
      )}

      {memberBenefit?.isWinner && memberBenefit.isWinnerDate != null && (
        <div>
          <p>{`Je hebt meegedaan aan de winactie voor '${memberBenefit.title}'. En je hebt gewonnen!`}</p>

          <p>{`Om jouw tickets/stoelen te garanderen vragen wij je jouw aanwezigheid te bevestigen voor ${memberBenefit?.isWinnerDate}. Dat doe je via de winnaarsmail die vandaag naar je wordt gemaild.`}</p>

          <p>{`Check hiervoor jouw mail/inbox en bevestig jouw aanwezigheid in de mail die je van ons ontvangt. Bij geen bevestiging voor ${memberBenefit.isWinnerDate} zullen we nieuwe winnaars blij maken met de tickets.`}</p>

          <p>Alvast veel plezier toegewenst!</p>
        </div>
      )}
    </div>
  )
}

export default MemberBenefitCampaign
