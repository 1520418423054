import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const productIcons: { [key: string]: IconProp } = {
  12: 'motorcycle', // Bromfiets / Overig
  15: 'car', // Oldtimer
  18: 'couch', // Inboedel
  21: 'house', // Woonhuis
  27: 'address-card', // Aansprakelijkheid
  3: 'car', // Personenauto
  36: 'island-tropical', // Doorlopende reis
  39: 'caravan', // Caravan / Vouwwagen
  42: 'caravan', // Stacaravan
  51: 'exclamation-triangle', // Ongevallen
  58: 'gavel', // Rechtsbijstand
  64: 'rv', // Camper
  67: 'shuttle-van', // Bestelauto
  68: 'trailer', // Aanhangwagen
  71: 'tractor', // Hobbytractor
  74: 'motorcycle', // Quad / Trike
  75: 'house', // Recreatiewoning
  77: 'island-tropical', // Kortlopende reis
  78: 'cross', // Uitvaart
  81: 'gem', // Kostbaarheden
  84: 'gavel', // Rechtsbijstand - ProRechtpolis
  85: 'ship', // Pleziervaartuig
  90: 'ship', // Boot
  86: 'truck', // Vracht(Bestel)auto
  87: 'chair-office', // Inventaris
  9: 'motorcycle', // Motor
  92: 'road', // Verkeersdeelnemers
  93: 'bicycle' // Fiets
}
