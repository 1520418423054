import { useAppDispatch } from './state'
import {
  getPolicies as getPoliciesAPI,
  getPolicy as getPolicyAPI,
  IPostRebuildValueMeterData,
  requestPolicyAdvise as requestPolicyAdviseAPI,
  postRebuildValueMeter as postRebuildValueMeterAPI,
  postContentsInsuranceValue as postContentsInsuranceValueAPI,
  IPostContentsInsuranceValueData,
  generateInsuranceCard as generateInsuranceCardAPI
} from '../api/policies'
import {
  setIsLoadingInsuranceCard,
  setPolicies,
  setValueMeterIsLoading,
  updatePolicy
} from '../state/slices/policies_slice'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

export const usePolicies = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const getPolicies = async (): Promise<boolean> => {
    const result = await getPoliciesAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }

    if (result.data) {
      dispatch(setPolicies(result.data ?? []))
    }

    return result.success
  }

  const getPolicy = async (id: string) => {
    const result = await getPolicyAPI(id)

    if (result.success) {
      dispatch(updatePolicy(result.data))
    }

    return result.success
  }

  const postRebuildValueMeter = async (
    id: string,
    data: IPostRebuildValueMeterData
  ) => {
    dispatch(setValueMeterIsLoading(true))
    try {
      await postRebuildValueMeterAPI(id, data)
      dispatch(setValueMeterIsLoading(false))
      navigate('/dossier/waardemeters')
    } catch {
      dispatch(setValueMeterIsLoading(false))
    }
  }
  const postContentsInsuranceValue = async (
    id: string,
    data: IPostContentsInsuranceValueData[]
  ) => {
    dispatch(setValueMeterIsLoading(true))
    try {
      await postContentsInsuranceValueAPI(id, data)
      dispatch(setValueMeterIsLoading(false))
      navigate('/dossier/waardemeters')
    } catch {
      dispatch(setValueMeterIsLoading(false))
    }
  }
  const requestPolicyAdvise = async (
    relationId: string,
    agencyId: string,
    adviseExplanation: string,
    products: string[]
  ): Promise<boolean> => {
    const result = await requestPolicyAdviseAPI(
      relationId,
      agencyId,
      adviseExplanation,
      products
    )

    if (!result.success) {
      Sentry.captureException(result.error)
    }

    return result.success
  }

  const generateInsuranceCard = async (policyId: string, vehicleId: string) => {
    dispatch(setIsLoadingInsuranceCard(true))
    try {
      const result = await generateInsuranceCardAPI(policyId, vehicleId)

      if (!result.success) {
        Sentry.captureException(result.error)
      }
      navigate(`/verzekeringen/polis/${policyId}`)

      return result.success
    } finally {
      dispatch(setIsLoadingInsuranceCard(false))
    }
  }

  return {
    getPolicies,
    getPolicy,
    requestPolicyAdvise,
    postRebuildValueMeter,
    postContentsInsuranceValue,
    generateInsuranceCard
  }
}

export default usePolicies
