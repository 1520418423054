import { useAppDispatch } from './state'
import { getQuestions as getQuestionsAPI } from '../api/questions'
import { setQuestions } from '../state/slices/questions_slice'
import * as Sentry from '@sentry/react'

export const useQuestions = () => {
  const dispatch = useAppDispatch()

  const getQuestions = async (): Promise<boolean> => {
    const result = await getQuestionsAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }
    if (result.data) {
      dispatch(setQuestions(result.data))
    }

    return result.success
  }

  return {
    getQuestions
  }
}

export default useQuestions
