import { format, parse, isValid } from 'date-fns'

const formatToApiDate = (date: string) => {
  const parsedDate = parse(date, 'dd-MM-yyyy', new Date())

  if (!isValid(parsedDate)) {
    return date
  }

  return format(parsedDate, 'yyyy-MM-dd')
}

export default formatToApiDate
