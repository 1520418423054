import React from 'react'
import Modal from '@verzekeringapp-mono/shared/src/components/Modal'
import LocationCard from './LocationCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICompany } from '@verzekeringapp-mono/shared/src/api/types/company'

interface IProps {
  location: ICompany
  onClose: () => void
}

const LocationCardModal = ({ location, onClose }: IProps) => (
  <Modal onClose={onClose}>
    <button
      className='absolute top-4 right-4'
      onClick={onClose}
    >
      <FontAwesomeIcon icon={['fal', 'times']} />
    </button>

    <LocationCard location={location} />
  </Modal>
)

export default LocationCardModal
