import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '@verzekeringapp-mono/shared/src/components/Modal'
import { Controller, useForm } from 'react-hook-form'
import {
  useAppDispatch,
  useAppSelector
} from '@verzekeringapp-mono/shared/src/hooks/state'
import useGeocode from '@verzekeringapp-mono/shared/src/hooks/use_geocode'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import { updateFilters } from '@verzekeringapp-mono/shared/src/state/slices/companies_slice'
import useCompanies from '@verzekeringapp-mono/shared/src/hooks/use_companies'
import TextInput from '../../../components/TextInput'
import Button from '../../../components/Button'

interface IProps {
  onClose: () => void
  mapRef: google.maps.Map | null
}

const LocationFilterModal = ({ onClose, mapRef }: IProps) => {
  const filters = useAppSelector(state => state.companies.filters)
  const categoryOptions = useAppSelector(state => state.companies.categories)
  const radiusOptions = useAppSelector(state => state.companies.radius)
  const [citySelect, setCitySelect] = React.useState<
    {
      value: string
      text: string
    }[]
  >([])

  const { register, handleSubmit, watch, setValue, control, formState } =
    useForm({
      defaultValues: {
        location: filters.location || '',
        radius: filters.radius || 10,
        manufacturerType: filters.companyType || categoryOptions?.[0]?.id,
        brand: filters.brand
      }
    })

  const { searchRepairers } = useCompanies()

  const { reverseGeocodeAsyncGoogleMaps, geocodeAsyncGoogleMaps } = useGeocode()

  const dispatch = useAppDispatch()

  const getUserLocation = useCallback(async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        const location = await reverseGeocodeAsyncGoogleMaps({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })

        setValue('location', location?.postalCode || '', {
          shouldValidate: true
        })
      })
    }
  }, [reverseGeocodeAsyncGoogleMaps, setValue, navigator.geolocation])

  const selectedCategory = categoryOptions.find(
    category => watch('manufacturerType').toString() === category.id.toString()
  )

  const handleSearchRepairers = useCallback(
    (data: {
      location: string
      radius: number
      manufacturerType: number
      brand: number | null
    }) => {
      searchRepairers({
        q: data.location,
        radius: Number(data.radius),
        category_id: Number(data.manufacturerType),
        brand_id: Number(data.brand)
      }).then(async res => {
        if (res.data?.citySelect?.length > 0) {
          setCitySelect(res.data.citySelect)
        } else {
          const latLng = await geocodeAsyncGoogleMaps(data.location)

          if (mapRef && latLng) {
            mapRef?.panTo({
              lat: latLng.latitude,
              lng: latLng.longitude
            })
          }
          dispatch(
            updateFilters({
              location: data.location,
              radius: Number(data.radius),
              companyType: data.manufacturerType,
              brand: data.brand
            })
          )
          onClose()
        }
      })
    },
    [searchRepairers, dispatch, updateFilters, mapRef]
  )

  return (
    <Modal onClose={onClose}>
      <button
        className='absolute top-4 right-4'
        onClick={onClose}
      >
        <FontAwesomeIcon icon={['fal', 'times']} />
      </button>

      <form
        onSubmit={handleSubmit(async data => {
          handleSearchRepairers(data)
        })}
      >
        <div className='mb-4'>
          <label
            htmlFor='location'
            className='block text-gray-700 font-semibold mb-2'
          >
            Locatie
          </label>

          <div
            className='relative flex items-center'
            style={{ height: '3.5rem' }}
          >
            <Controller
              control={control}
              name='location'
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  showValidationIcon={false}
                  shouldValidate={false}
                  id='location'
                  placeholder='Bijv. Oudkarspel of 1724 NT'
                />
              )}
            />

            <FontAwesomeIcon
              color={Colors.gray}
              icon={'location'}
              className='absolute right-4 bg-white cursor-pointer'
              onClick={() => {
                getUserLocation()
              }}
            />
          </div>

          {citySelect.length > 0 && (
            <div className='mt-2'>
              <label
                htmlFor='citySelect'
                className='block text-gray-700 font-semibold mb-2'
              >
                Specificeer de locatie
              </label>

              <select
                id='citySelect'
                className='w-full p-3 rounded-md focus:outline-none focus:ring focus:border-gray outline-none border'
              >
                {citySelect.map(city => (
                  <option
                    key={city.value}
                    value={city.value}
                  >
                    {city.text}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <div className='mb-4'>
          <label
            htmlFor='radius'
            className='block text-gray-700 font-semibold mb-2'
          >
            Radius
          </label>

          <select
            id='radius'
            className='w-full p-3 rounded-md focus:outline-none focus:ring focus:border-gray outline-none border border-lightGray'
            {...register('radius')}
          >
            {radiusOptions.map(radius => (
              <option
                key={radius}
                value={radius}
              >
                {radius} KM
              </option>
            ))}
          </select>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='manufacturerType'
            className='block text-gray-700 font-semibold mb-2'
          >
            Type hersteller
          </label>

          <select
            id='manufacturerType'
            className='w-full p-3 rounded-md focus:outline-none focus:ring focus:border-gray outline-none border border-lightGray'
            {...register('manufacturerType')}
          >
            {categoryOptions.map(category => (
              <option
                key={category.id}
                value={category.id}
              >
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {selectedCategory?.brands && selectedCategory.brands.length > 0 && (
          <div className='mb-4'>
            <label
              htmlFor='brand'
              className='block text-gray-700 font-semibold mb-2'
            >
              Merk
            </label>

            <select
              className='w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500'
              id='brand'
              {...register('brand')}
            >
              <option
                value=''
                disabled
                selected
                hidden
              >
                <span className='text-gray-400'>Selecteer merk</span>
              </option>

              {selectedCategory.brands.map(brand => (
                <option
                  key={brand.id}
                  value={brand.id}
                >
                  {brand.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <Button
          type='submit'
          className='w-full py-3 bg-brand text-white font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
          disabled={!formState.isValid}
        >
          Pas filters toe
        </Button>
      </form>
    </Modal>
  )
}

export default LocationFilterModal
