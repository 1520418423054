import {
  checkEmailAddress as checkEmail,
  login as loginUser,
  forgotPassword as userForgotPassword,
  resetPassword as resetPasswordAPI,
  updatePassword as updatePasswordAPI,
  registerWithToken as registerWithTokenApi,
  getTerms as getTermsAPI,
  postOnboarding as postOnboardingAPI
} from '../api/sessions'
import {
  IForgotPasswordResponse,
  ILoginFailedResponse,
  ILoginSuccessResponse
} from '../api/types/sessions'

import { getEmail, getUser } from '../state/selectors/user'
import { setIdinResultStatus } from '../state/slices/idin_slice'
import { setRelations } from '../state/slices/relations_slice'
import { setTerms } from '../state/slices/terms_slice'
import { setShowFirstUse, setToken, setUser } from '../state/slices/user_slice'
import { setUserId, logEvent } from '../utils/firebase'
import { useAppDispatch, useAppSelector } from './state'
import useAgency from './use_agency'
import usePolicies from './use_policies'
import * as Sentry from '@sentry/react'
import useUser from './use_user'

export const useSession = () => {
  const dispatch = useAppDispatch()
  const { getPolicies } = usePolicies()
  const { getAgency } = useAgency()
  const { updateUser } = useUser()
  const user = useAppSelector(getUser)

  const email = useAppSelector(getEmail)

  const checkEmailAddress = async (email: string): Promise<string> => {
    const result = await checkEmail(email)

    dispatch(setIdinResultStatus(undefined))
    if (!result.success) {
      Sentry.captureException(result.error)
    }
    dispatch(setShowFirstUse(false))

    return result.data.status
  }

  const forgotPassword = async (email: string, resetPasswordUrl: string) => {
    const { success, error, data } = await userForgotPassword(
      email,
      resetPasswordUrl
    )

    if (!success) {
      console.warn(error)
    }

    // TODO: - Ask Paskal to make this endpoint return the same thing for a success as error case
    if (data as IForgotPasswordResponse) {
      return (data as IForgotPasswordResponse).result
    } else if (data as boolean) {
      return data
    } else {
      return false
    }
  }

  const login = async (email: string, password: string) => {
    const { success, data, message } = await loginUser(email, password).catch(
      e => {
        Sentry.captureException(e)

        return {
          success: false,
          data: null,
          message: 'Er is iets misgegaan. Probeer het later opnieuw.',
          attemptsLeft: null
        }
      }
    )

    if (success) {
      const loginResponseSuccess = data as ILoginSuccessResponse

      dispatch(setUser(loginResponseSuccess.user))
      setUserId(loginResponseSuccess.user.id)
      logEvent('login')

      dispatch(setTerms(loginResponseSuccess.terms))
      dispatch(setRelations(loginResponseSuccess.relations))
      if (loginResponseSuccess.relations.length > 0) {
        await getAgency(loginResponseSuccess.relations[0].agencyId)
      }
      getPolicies()

      return {
        success: success,
        attemptLeft: null
      }
    } else {
      const loginResponseFailed = data as ILoginFailedResponse

      Sentry.captureException(message)

      return {
        success: success,
        attemptLeft: loginResponseFailed?.attemptsLeft || 0,
        message
      }
    }
  }

  const resetPassword = async (
    password: string,
    passwordRepeat: string,
    token: string
  ) => {
    try {
      const { success, data } = await resetPasswordAPI(
        password,
        passwordRepeat,
        token
      )

      if (!success) {
        console.warn(data)
      }

      return success
    } catch (e) {
      throw e
    }
  }

  const updatePassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    const result = await updatePasswordAPI(
      email!,
      currentPassword,
      newPassword,
      newPassword
    )

    if (!result.success) {
      throw new Error(result.message)
    } else {
      dispatch(setToken(result.data.token))
    }

    return result
  }

  const registerWithToken = async (
    email: string,
    password: string,
    passwordRepeat: string,
    registerToken: string
  ) => {
    const result = await registerWithTokenApi(
      email,
      password,
      passwordRepeat,
      registerToken
    )

    if (result.success) {
      const loginResult = await login(email, password)

      if (loginResult.success) {
        return result
      } else {
        return loginResult
      }
    }

    return result
  }

  const getTerms = async () => {
    const { data, success, error } = await getTermsAPI()

    if (!success) {
      Sentry.captureException(error)
    }

    return data
  }

  const postOnboarding = async ({
    firstName,
    termsRead,
    relationId,
    relationNumber
  }: {
    firstName: string
    termsRead: boolean
    relationId: string
    relationNumber: string
  }) => {
    const { data, success, error } = await postOnboardingAPI({
      firstName,
      termsRead,
      relationId,
      relationNumber
    })

    dispatch(
      setUser({
        ...user!,
        firstName,
        termsRead
      })
    )

    if (!success) {
      Sentry.captureException(error)
    }

    return data
  }

  return {
    checkEmailAddress,
    login,
    forgotPassword,
    resetPassword,
    updatePassword,
    registerWithToken,
    getTerms,
    postOnboarding
  }
}

export default useSession
