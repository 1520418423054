import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'

const _getState = (state: RootState) => state.products

export const getProducts = createSelector(_getState, state => state.products)

export const getProduct = (id: string) =>
  createSelector(_getState, state =>
    state.products.find(product => product.id === id)
  )

export const getProductIsLoading = () =>
  createSelector(_getState, state => state.isLoading)
