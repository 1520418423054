import React from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getPoliciesActivePullingVehicles } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import { getLicensePlate } from '@verzekeringapp-mono/shared/src/utils/policy_utils'
import Button from '../../components/Button'
import usePolicies from '@verzekeringapp-mono/shared/src/hooks/use_policies'
import { useParams } from 'react-router-dom'

const GenerateGreenCard = () => {
  const { id: policyId } = useParams<{ id: string }>()
  const policiesActivePullingVehicles = useAppSelector(
    getPoliciesActivePullingVehicles
  )

  const isLoading = useAppSelector(
    (state) => state.policies.isLoadingInsuranceCard
  )
  const { generateInsuranceCard } = usePolicies()

  if (!policyId) {
    return <>Er is iets fout gegaan</>
  }

  return (
    <ScreenWrapper>
      <div className='bg-white rounded p-8 max-w-[50%]'>
        <div className='flex flex-col gap-4'>
          <h1 className='text-2xl'>Verzekeringsbewijs genereren</h1>

          <div className='flex flex-col gap-4'>
            {policiesActivePullingVehicles.map((vehicle) => (
              <Button
                key={vehicle.id}
                isLoading={isLoading}
                onClick={() => {
                  generateInsuranceCard(policyId, vehicle.id)
                }}
              >
                {getLicensePlate(vehicle.details)}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </ScreenWrapper>
  )
}

export default GenerateGreenCard
