import React, { useEffect, useState } from 'react'
import { Images } from '@verzekeringapp-mono/shared/src/assets'
import LoginScreenWrapper from '../../components/screen_wrappers/login_screen_wrapper'
import useSession from '@verzekeringapp-mono/shared/src/hooks/use_session'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'

const RegisterWithToken: React.FC = () => {
  const [searchParams] = useSearchParams()
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  const {
    control,
    watch,
    formState: { isValid },
    handleSubmit,
    getValues
  } = useForm({
    defaultValues: {
      email: searchParams.get('email') ?? '',
      newPassword: '',
      repeatNewPassword: ''
    }
  })

  const registerToken = searchParams.get('registerToken') ?? ''

  const { registerWithToken } = useSession()
  const navigate = useNavigate()

  useEffect(() => {
    if (registerToken === '') {
      navigate('/home')
    }
  }, [registerToken])

  const newPassword = watch('newPassword')
  const repeatNewPassword = watch('repeatNewPassword')

  if (error) {
    return (
      <LoginScreenWrapper>
        <div className='my-4 bg-darkRed p-4 rounded '>
          <span className='text-white'>{error}</span>
        </div>

        <Button
          className='w-full my-2'
          onClick={() =>
            navigate('/register', {
              state: { email: getValues('email') }
            })
          }
          type='button'
        >
          Opnieuw registreren
        </Button>
      </LoginScreenWrapper>
    )
  }

  return (
    <LoginScreenWrapper>
      <form
        onSubmit={handleSubmit(
          async ({ email, newPassword, repeatNewPassword }) => {
            setIsLoading(true)
            const result = await registerWithToken(
              email,
              newPassword,
              repeatNewPassword,
              registerToken
            ).finally(() => setIsLoading(false))

            if (!result.success) {
              setError(result.message)
            } else {
              navigate('/overzicht')
            }
          }
        )}
      >
        <img
          src={Images.Logo}
          width={180}
          height={70}
        />

        <p className='title  mt-8'>Inlog aanmaken</p>

        <p className='description mt-4 mb-8'>
          Hieronder kunt u uw account aanmaken:
        </p>

        <Controller
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              label='E-mailadres'
              placeholder='Uw e-mailadres'
              showValidationIcon={false}
              isValid={!fieldState.invalid}
              icon={'envelope'}
            />
          )}
          name='email'
          control={control}
          rules={{
            required: 'Dit veld is verplicht',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Dit is geen geldig e-mailadres'
            }
          }}
        />

        <p className='mt-4 mb-2'>
          Bedenk een veilig wachtwoord dat voldoet aan de volgende eisen:
        </p>

        <p className={newPassword.length >= 8 ? 'labelSuccess' : 'label'}>
          • Minimaal 8 karakters
        </p>

        <p className={/[A-Z]/.test(newPassword) ? 'labelSuccess' : 'label'}>
          • Minimaal 1 hoofdletter
        </p>

        <p className={/[a-z]/.test(newPassword) ? 'labelSuccess' : 'label'}>
          • Minimaal 1 kleine letter
        </p>

        <p className={/\d/.test(newPassword) ? 'labelSuccess' : 'label'}>
          • Minimaal 1 cijfer
        </p>

        <p
          className={
            /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(newPassword)
              ? 'labelSuccess'
              : 'label'
          }
        >
          • Minimaal 1 speciaal teken
        </p>

        <p
          className={
            newPassword.length > 0 &&
            repeatNewPassword.length > 0 &&
            newPassword === repeatNewPassword
              ? 'labelSuccess'
              : 'label'
          }
        >
          • Nieuwe wachtwoorden zijn hetzelfde
        </p>

        <div className='mt-4'>
          <Controller
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                type='password'
                placeholder='Wachtwoord'
                label='Wachtwoord'
                icon='key'
                isValid={!fieldState.invalid}
              />
            )}
            name='newPassword'
            control={control}
            rules={{
              required: true,
              minLength: 8,
              pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/
            }}
          />

          <Controller
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                type='password'
                placeholder='Herhaal uw wachtwoord'
                label='Herhaal wachtwoord'
                icon='key'
                isValid={!fieldState.invalid}
              />
            )}
            name='repeatNewPassword'
            control={control}
            rules={{
              required: true,
              validate: value => value === newPassword
            }}
          />
        </div>

        <Button
          className='w-full my-2'
          disabled={!isValid}
          isLoading={isLoading}
        >
          Aanmaken
        </Button>

        <Button
          className='w-full my-2'
          onClick={() => navigate('/home')}
          variant='outline'
          type='button'
        >
          Terug
        </Button>
      </form>
    </LoginScreenWrapper>
  )
}

export default RegisterWithToken
