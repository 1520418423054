import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface IProps {
  href?: string
  icon: IconProp
  title: string
}

const NavBarItem: React.FC<IProps> = (props) => (
  <a
    href={props.href}
    className='flex w-full h-[60px] items-center px-8 border-b-[0.5px] border-background'
  >
    <div className='flex w-[24px] h-[24px] items-center justify-center'>
      <FontAwesomeIcon
        icon={props.icon}
        size='lg'
        color='lightgray'
      />
    </div>

    <p className='ml-4'>{props.title}</p>
  </a>
)

export default NavBarItem
