import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import {
  useAppDispatch,
  useAppSelector
} from '@verzekeringapp-mono/shared/src/hooks/state'
import { getUser } from '@verzekeringapp-mono/shared/src/state/selectors/user'
import ProfileNavigationButton from './components/profile_navigation_button'
import { profileTiles } from '@verzekeringapp-mono/shared/src/utils/tiles_data/profile_tiles.data'
import { logout } from '@verzekeringapp-mono/shared/src/state/actions/constants'
import { useNavigate } from 'react-router-dom'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'

const ProfileScreen: React.FC = () => {
  const user = useAppSelector(getUser)!
  const dispatch = useAppDispatch()
  const currentRelation = useAppSelector(getCurrentRelation)

  const navigate = useNavigate()

  return (
    <ScreenWrapper>
      <div className='flex flex-col flex-1 items-center mb-32'>
        <p className='text-brand text-[20px]'>{user.firstName}</p>

        <p className='text-[14px] text-gray'>
          Combinummer: {currentRelation?.combiNumber}
        </p>

        <p className='mb-8 text-[14px] text-gray'>
          {user.mainUser ? 'Hoofdgebruiker' : 'Subgebruiker'}
        </p>

        <div className='md:w-2/6 w-full flex flex-col'>
          {profileTiles(user.mainUser).map((tile, index) => (
            <ProfileNavigationButton
              key={index}
              data={tile}
            />
          ))}

          <ProfileNavigationButton
            onPress={() => {
              dispatch(logout())
              navigate('/')
            }}
            data={{
              icon: 'user-times',
              title: 'Uitloggen'
            }}
          />
        </div>
      </div>
    </ScreenWrapper>
  )
}

export default ProfileScreen
