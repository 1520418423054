import React from 'react'
import { Images } from '@verzekeringapp-mono/shared/src/assets'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getFullName } from '@verzekeringapp-mono/shared/src/state/selectors/user'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import { Link } from 'react-router-dom'
import { getButtons } from './nav_bar/nav_bar'
import NavBarItem from './nav_bar/nav_bar_item'
import { getAgency } from '@verzekeringapp-mono/shared/src/state/selectors/agency'

const ScreenHeader = () => {
  const currentRelation = useAppSelector(getCurrentRelation)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const agency = useAppSelector(getAgency)

  const fullName = useAppSelector(getFullName)

  return (
    <div className='flex justify-between items-center h-[86px] bg-white px-8 border-b-[0.5px] border-background'>
      <Link
        to='/'
        className='md:block hidden'
      >
        <img
          src={agency?.logo ?? Images.Logo}
          width={127}
          height={55}
        />
      </Link>

      <button
        className='md:hidden flex items-center gap-2 w-full p-4 bg-white '
        id='menu-button'
        aria-expanded='false'
        onClick={() => {
          setIsMenuOpen(!isMenuOpen)
        }}
      >
        <div className='flex flex-col space-y-1 focus:outline-none'>
          <span className='block w-6 h-0.5 bg-brand'></span>

          <span className='block w-6 h-0.5 bg-brand'></span>

          <span className='block w-6 h-0.5 bg-brand'></span>
        </div>
        Menu
      </button>

      <div className='flex flex-row justify-end items-center gap-4'>
        <a href='/notificaties'>
          <FontAwesomeIcon
            size='xl'
            icon='bell'
            color={Colors.textGray}
          />
        </a>

        <a href='/mijn-profiel'>
          <div className='flex flex-row gap-[8px] items-center'>
            <FontAwesomeIcon
              icon='user'
              color={Colors.textGray}
              className='hidden md:block'
            />

            <FontAwesomeIcon
              icon='user'
              color={Colors.textGray}
              size='xl'
              className='block md:hidden'
            />

            <p className='text-[14px] text-gray hidden md:block'>{fullName}</p>
          </div>

          <p className='text-[14px] text-textGray hidden md:block'>{`Combinummer: ${currentRelation?.combiNumber}`}</p>
        </a>

        <div
          className={`${
            isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          } fixed top-0 left-0 h-full w-64 bg-white z-50 transform transition-transform duration-300 ease-in-out`}
        >
          <button
            className='flex w-full h-[60px] items-center px-8 border-b-[0.5px] border-background gap-4'
            id='menu-button'
            aria-expanded='false'
            onClick={() => {
              setIsMenuOpen(!isMenuOpen)
            }}
          >
            <div className='flex flex-col space-y-1 focus:outline-none'>
              <span className='block w-6 h-0.5 bg-brand'></span>

              <span className='block w-6 h-0.5 bg-brand'></span>

              <span className='block w-6 h-0.5 bg-brand'></span>
            </div>
            Menu
          </button>

          {getButtons(currentRelation!.cooperationMembership).map(
            (item, index) => (
              <NavBarItem
                key={index}
                href={item.href}
                icon={item.icon}
                title={item.title}
              />
            )
          )}
        </div>
      </div>

      {/* Screen overlay */}
      {isMenuOpen && (
        <div
          className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 z-40'
          onClick={() => {
            setIsMenuOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default ScreenHeader
