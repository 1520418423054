import React, { useEffect } from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { caseTilesData } from '@verzekeringapp-mono/shared/src/utils/tiles_data/case_tiles_data'
import NavigationTileGrid from '../../components/navigation_tile/navigation_tile_grid'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getValueMeterPolicies } from '@verzekeringapp-mono/shared/src/state/selectors/valuemeters'
import usePolicies from '@verzekeringapp-mono/shared/src/hooks/use_policies'

const CaseScreen: React.FC = () => {
  const valueMeterPolicies = useAppSelector(getValueMeterPolicies)

  const { getPolicies } = usePolicies()

  useEffect(() => {
    getPolicies()
  }, [])

  return (
    <ScreenWrapper>
      <NavigationTileGrid
        tiles={
          valueMeterPolicies.length > 0
            ? caseTilesData
            : caseTilesData.filter(tile => tile.title !== 'Waardemeters')
        }
      />
    </ScreenWrapper>
  )
}

export default CaseScreen
