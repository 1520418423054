import { Images } from '@verzekeringapp-mono/shared/src/assets'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <>
      <img
        src={Images.Logo}
        width={180}
        height={70}
      />

      <p className='title  mt-8'>Niet gevonden</p>

      <p className='description mt-4'>Dat kan 3 dingen betekenen.</p>

      <p className='description mt-4'>
        1. U bent te snel, de nieuwe polis is nog niet bevestigd.
      </p>

      <p className='description mt-4'>2. U heeft geen combipolis bij Nh1816</p>

      <p className='description mt-4'>
        3. U heeft een combipolis op de naam van iemand anders.
      </p>

      <p className='description mt-4'>
        Contacteer uw tussenpersoon. Hij of zij helpt u graag verder!
      </p>

      <button
        className='secondary-btn w-full mt-4'
        onClick={() => navigate('/home')}
      >
        Terug
      </button>
    </>
  )
}

export default NotFound
