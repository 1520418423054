import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import HouseHoldEffectsForm from './HouseHoldEffectsForm'

const HouseHoldEffectsScreen = () => (
  <ScreenWrapper>
    <HouseHoldEffectsForm />
  </ScreenWrapper>
)

export default HouseHoldEffectsScreen
