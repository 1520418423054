import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import {
  getCurrentRelation,
  getRelations
} from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import CombilPolicyListItem from './components/combi_policy_list_item'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IRelation } from '@verzekeringapp-mono/shared/src/models/relation'
import useRelation from '@verzekeringapp-mono/shared/src/hooks/use_relation'
import { Link, useNavigate } from 'react-router-dom'
import Modal from '@verzekeringapp-mono/shared/src/components/Modal'
import Button from '../../../components/Button'
import LoadingIndicator from '../../../components/LoadingIndicator'
import SuccessSvg from '../../../components/illustrations/Succes'
import PolicySvg from '../../../components/illustrations/Policy'
import { useThemeColorContext } from '../../../providers/ThemeColorProvider'

const SelectCombiPolicyScreen: React.FC = () => {
  const relations = useAppSelector(getRelations)
  const currentRelation = useAppSelector(getCurrentRelation)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const { switchRelation, deleteRelation } = useRelation()
  const { brandColors } = useThemeColorContext()
  const navigate = useNavigate()

  const onPressDelete = (relation: IRelation) => {
    if (window.confirm('Weet u zeker dat u de combipolis wilt verwijderen?')) {
      deleteRelation(relation)
    }
  }

  const newCombiPolisButton = (
    <Link
      to='/mijn-profiel/combipolis-toevoegen'
      className={'p-4 rounded-lg bg-brand flex flex-row items-center gap-4'}
    >
      <div
        className='h-[34px] w-[34px] rounded-full flex items-center justify-center mr-2'
        style={{ backgroundColor: 'white' }}
      >
        <FontAwesomeIcon
          icon='user'
          size='sm'
          color={brandColors.brand}
        />
      </div>

      <p className='text-white'>Combipolis toevoegen</p>
    </Link>
  )

  return (
    <ScreenWrapper>
      <div className='bg-white  w-full rounded flex flex-col items-center  md:p-8 p-4'>
        <PolicySvg />

        <p className='title mb-4 text-center'>Welke combipolis?</p>

        <p className='description mb-4 text-center'>
          Geen zorgen! U kunt altijd weer een andere combipolis selecteren.
        </p>

        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  gap-4 p-4 w-full'>
          {relations.map(relation => (
            <CombilPolicyListItem
              key={relation.id}
              relation={relation}
              current={relation.id === currentRelation?.id}
              onPressSelect={relation => {
                setIsOpen(true)
                setIsLoading(true)
                switchRelation(relation).then(() => {
                  setIsLoading(false)
                })
              }}
              onPressDelete={onPressDelete}
            />
          ))}

          {newCombiPolisButton}
        </div>

        {isOpen && (
          <Modal onClose={() => {}}>
            <div className='p-8 flex flex-col justify-center items-center gap-4 md:h-2/4 h-full'>
              {isLoading
                ? (
                  <LoadingIndicator />
                )
                : (
                  <>
                    <SuccessSvg />

                    <p className='title text-center'>Succesvol ingesteld!</p>

                    <p className='text-center'>
                    U bent succesvol gewisseld van combipolis
                    </p>

                    <Button
                      className={'primary-btn w-full mt-8 mb-3'}
                      onClick={() => navigate('/')}
                    >
                    Doorgaan
                    </Button>
                  </>
                )}
            </div>
          </Modal>
        )}
      </div>
    </ScreenWrapper>
  )
}

export default SelectCombiPolicyScreen
