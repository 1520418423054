import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { INavigationTileItem } from '@verzekeringapp-mono/shared/src/models/navigation_tile_item'
import LogoSVG from '@verzekeringapp-mono/shared/src/assets/icons/Logo'
import { useThemeColorContext } from '../../providers/ThemeColorProvider'

interface IProps {
  tile: INavigationTileItem
}

const NavigationTile: React.FC<IProps> = ({ tile }) => {
  const { brandColors } = useThemeColorContext()

  return (
    <a
      className='bg-white flex flex-col justify-center items-center min-h-[150px] gap-4 rounded-md'
      href={tile.href ? tile.href : undefined}
      target={tile.openInNewTab ? '_blank' : undefined}
      rel='noreferrer'
    >
      <div className='w-[50px] h-[50px] bg-lightBrand rounded-[25px] flex justify-center items-center mr-4'>
        {tile.icon === 'nh'
          ? (
            <LogoSVG
              color={brandColors.brand}
              width={30}
              height={30}
            />
          )
          : (
            <FontAwesomeIcon
              icon={tile.icon}
              size='lg'
              color={brandColors.brand}
            />
          )}
      </div>

      {tile.title}
    </a>
  )
}

export default NavigationTile
