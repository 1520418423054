import React, { useEffect } from 'react'
import ScreenWrapper from '../../../../components/screen_wrappers/screen_wrapper'
import { useClaims } from '@verzekeringapp-mono/shared/src/hooks/use_claims'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getClaims as getClaimsSelector } from '@verzekeringapp-mono/shared/src/state/selectors/claims'
import ClaimCard from './components/claim_card'
import ClaimDamageButton from '../../../../components/ClaimDamageButton'
import IllustrationCases from '../../../../components/illustrations/IllustrationCases'

const ClaimOverviewScreen: React.FC = () => {
  const { getClaims } = useClaims()

  useEffect(() => {
    getClaims()
  }, [])

  const claims = useAppSelector(getClaimsSelector)

  return (
    <ScreenWrapper>
      <div>
        <div className='grid md:grid-cols-3 grid-cols-1 gap-8'>
          <div className='col-span-2 md:py-8'>
            <div className='md:flex flex-col hidden'>
              <p className='paragraph-light'>Overzicht</p>

              <p className='title'>Mijn schades</p>

              <p className='description py-8'>
                Hier vindt u een overzicht van al uw gemelde schades. U kunt
                vanaf hier de details inzien of een nieuwe melding aanmaken.
                Vragen? Aarzel niet en neem contact op.
              </p>
            </div>

            <div className='my-8 md:w-1/2'>
              <ClaimDamageButton />
            </div>
          </div>

          <div className='items-center text-brand justify-center hidden md:flex'>
            <IllustrationCases />
          </div>
        </div>

        {claims?.map((claim, index) => (
          <ClaimCard
            key={claim.temporaryClaimReference || claim.hash || index}
            claim={claim}
          />
        ))}
      </div>
    </ScreenWrapper>
  )
}

export default ClaimOverviewScreen
