import {
  getAboutNh as getAboutNhAPI,
  getTermsApp as getTermsAppAPI,
  getWhatToDo,
  getFlashNews as getFlashNewsAPI,
  getAboutMembership as getAboutMembershipAPI
} from '../api/support'
import {
  setAboutMembership,
  setAboutNH,
  setFlashNews,
  setTermsApp,
  setWhatToDos
} from '../state/slices/support_slice'
import { useAppDispatch } from './state'
import * as Sentry from '@sentry/react'

export const useSupport = () => {
  const dispatch = useAppDispatch()

  const getAboutMembership = async (): Promise<boolean> => {
    const result = await getAboutMembershipAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }

    if (result.data) {
      dispatch(setAboutMembership(result.data))
    }

    return result.success
  }

  const getAboutNH = async (): Promise<boolean> => {
    const result = await getAboutNhAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }
    if (result.data) {
      dispatch(setAboutNH(result.data))
    }

    return result.success
  }

  const getTermsApp = async (): Promise<boolean> => {
    const result = await getTermsAppAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }
    if (result.data) {
      dispatch(setTermsApp(result.data))
    }

    return result.success
  }

  const getWhatToDos = async (): Promise<boolean> => {
    const result = await getWhatToDo()

    if (!result.success) {
      Sentry.captureException(result.error)
    }
    if (result.data) {
      dispatch(setWhatToDos(result.data))
    }

    return result.success
  }

  const getFlashNews = async (): Promise<boolean> => {
    const result = await getFlashNewsAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }
    if (result.data && result.data.flashMessages.length > 0) {
      dispatch(setFlashNews(result.data.flashMessages[0]))
    } else {
      dispatch(setFlashNews(undefined))
    }

    return result.success
  }

  return {
    getAboutMembership,
    getAboutNH,
    getTermsApp,
    getWhatToDos,
    getFlashNews
  }
}

export default useSupport
