import React from 'react'
import { IClaimMethod } from '@verzekeringapp-mono/shared/src/models/product'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import PolicySelector from './PolicySelector'
import SETTINGS from '@verzekeringapp-mono/shared/src/constants/settings'
import ContactInfoButton from '../../advisor/components/contact_info_button'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import {
  getAgency,
  getMainLocation
} from '@verzekeringapp-mono/shared/src/state/selectors/agency'
import { useEmergencyModalContext } from '../../../providers/EmergencyModalProvider'
import { useThemeColorContext } from '../../../providers/ThemeColorProvider'

interface IProps {
  claimMethod: IClaimMethod
}
const ClaimDamageExternal = ({ claimMethod }: IProps) => {
  const agency = useAppSelector(getAgency)
  const mainLocation = useAppSelector(getMainLocation)
  const { open } = useEmergencyModalContext()
  const { brandColors } = useThemeColorContext()

  return (
    <div className=' mx-auto p-8 bg-white rounded-lg lg:w-5/6 w-full flex justify-between flex-wrap'>
      <div>
        <h1 className='text-3xl font-medium mb-6 gap-8 flex items-center'>
          <span className='flex rounded-full p-9 bg-lightBrand h-[50px] w-[50px] items-center justify-center'>
            <FontAwesomeIcon
              icon='fragile'
              color={brandColors.brand}
              size='xl'
            />
          </span>
          Schade melden
        </h1>

        <PolicySelector />

        <div>
          <p
            className='text-gray'
            dangerouslySetInnerHTML={{ __html: claimMethod.description }}
          />
        </div>

        <div>
          <button
            className='bg-brand text-white font-medium py-4 px-6 rounded-lg my-4'
            rel='noreferrer'
          >
            <a
              target='_blank'
              href={claimMethod.externLink}
              rel='noreferrer'
            >
              Schade melden*
            </a>
          </button>
        </div>

        <div>
          <p>
            <small>
              *U wordt doorgestuurd naar een externe website om de schade te
              melden.
            </small>
          </p>
        </div>
      </div>

      {agency && (
        <div>
          <div className='md:block flex justify-between md:justify-start'>
            <p className='mb-2 hidden md:block font-medium'>Direct contact?</p>

            <ContactInfoButton
              circleColor={brandColors.brand}
              description={mainLocation?.phoneNumber}
              icon='phone'
              title={'Bellen'}
              href={`tel:${mainLocation?.phoneNumber}`}
            />

            <ContactInfoButton
              circleColor={brandColors.brand}
              description={mainLocation?.email}
              icon='envelope'
              title={'Bericht'}
              href={`mailto:${mainLocation?.email}`}
            />

            <ContactInfoButton
              circleColor={Colors.whatsapp}
              description={mainLocation?.whatsapp}
              icon={['fab', 'whatsapp']}
              title={'WhatsApp'}
              href={`https://wa.me/${mainLocation?.whatsapp}`}
            />

            <ContactInfoButton
              circleColor={brandColors.brand}
              icon={['fas', 'globe']}
              title={'Website'}
              description={agency.website}
              href={
                agency.website.startsWith('http')
                  ? agency.website
                  : `https://${agency.website}`
              }
            />

            <ContactInfoButton
              circleColor={Colors.darkRed}
              description={SETTINGS.emergencyPhoneNumber}
              descriptionColor={Colors.darkRed}
              icon='phone-plus'
              title={'Nood'}
              onClick={open}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ClaimDamageExternal
