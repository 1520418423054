import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore, PERSIST } from 'redux-persist'
import user from './slices/user_slice'
import relations from './slices/relations_slice'
import terms from './slices/terms_slice'
import idin from './slices/idin_slice'
import notifications from './slices/notifications_slice'
import payments from './slices/payments_slice'
import policies from './slices/policies_slice'
import claims from './slices/claims_slice'
import memberBenefits from './slices/member_benefit_slice'
import support from './slices/support_slice'
import questions from './slices/questions_slice'
import tips from './slices/tips_slice'
import agency from './slices/agency_slice'
import products from './slices/products_slice'
import companies from './slices/companies_slice'

const rootReducer = combineReducers({
  user,
  relations,
  terms,
  idin,
  notifications,
  payments,
  policies,
  claims,
  memberBenefits,
  support,
  questions,
  tips,
  agency,
  products,
  companies
})

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST]
      }
    })
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
