import React, { useEffect } from 'react'
import ScreenWrapper from '../../components/screen_wrappers/screen_wrapper'
import { useNotification } from '@verzekeringapp-mono/shared/src/hooks/use_notification'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getNotifications as getNotificationsFromSelector } from '@verzekeringapp-mono/shared/src/state/selectors/notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'
import { useThemeColorContext } from '../../providers/ThemeColorProvider'

const NotificationsScreen: React.FC = () => {
  const notifications = useAppSelector(getNotificationsFromSelector)
  const { getNotifications, deleteNotification } = useNotification()
  const { brandColors } = useThemeColorContext()

  useEffect(() => {
    getNotifications()
  }, [])

  const onPressDelete = (notificationId: number) => {
    if (confirm('Weet u zeker dat u het bericht wil verwijderen?')) {
      deleteNotification(notificationId)
    }
  }

  return (
    <ScreenWrapper>
      {notifications.length === 0
        ? (
          <p className='paragraph-light'>U heeft nog geen notificaties.</p>
        )
        : (
          notifications.map((notification, index) => (
            <a
              href={`/notificaties/${notification.messageId}`}
              key={index}
              className='flex flex-row items-center w-full bg-white mb-4 gap-4 rounded-md justify-between p-4'
            >
              <div>
                <p className='span'>{notification.title}</p>

                <p className='paragraph-light'>{notification.createdAt}</p>
              </div>

              <div className='flex gap-4'>
                <button
                  onClick={e => {
                    e.preventDefault()
                    onPressDelete(notification.messageId)
                  }}
                >
                  <FontAwesomeIcon
                    color={brandColors.brand}
                    icon='trash'
                  />
                </button>

                <FontAwesomeIcon
                  color={Colors.lightGray}
                  icon={['fal', 'chevron-right']}
                />
              </div>
            </a>
          ))
        )}
    </ScreenWrapper>
  )
}

export default NotificationsScreen
