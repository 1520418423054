import React from 'react'
import { IPolicy } from '@verzekeringapp-mono/shared/src/models/policy'
import { Link } from 'react-router-dom'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getIsPolicyPullable } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IProps {
  policy: IPolicy
  withDivider?: boolean
}

const GreenCard: React.FC<IProps> = ({ policy, withDivider }) => {
  const isPullable = useAppSelector(getIsPolicyPullable(policy.id))

  if (!policy.greenCard && policy.greenCards.length === 0 && !isPullable)
    return null

  return (
    <div>
      {withDivider && <div className='border-b border-lightGray my-8' />}

      <p className='subtitle mb-4'>Verzekeringsbewijs</p>

      {isPullable && (
        <Link
          to={`/verzekeringsbewijzen/genereren/${policy.id}`}
          className='text-primary underline btn-primary'
        >
          Verzekeringsbewijs genereren
        </Link>
      )}

      {policy.greenCard
        ? (
          <>
            <a
              className='text-brand  text-sm hidden md:block'
              href={policy.greenCard}
              target='_blank'
              rel='noreferrer'
            >
              <FontAwesomeIcon
                icon={['fal', 'arrow-down']}
                size='sm'
                className='mr-2'
              />
            Open het verzekeringsbewijs
            </a>

            <div className='md:hidden'>
              <button className='primary-btn w-full items-center justify-center'>
                <a
                  href={policy.greenCard}
                  target='_blank'
                  rel='noreferrer'
                >
                Open het verzekeringsbewijs
                </a>
              </button>
            </div>
          </>
        )
        : (
          <div className='flex flex-col gap-4 nax-w-3/6'>
            {policy.greenCards.map((greenCard, index) => (
              <button
                key={index}
                className='primary-btn'
              >
                <a
                  href={greenCard.url}
                  target='_blank'
                  rel='noreferrer'
                  key={index}
                  className='d-flex justify-center items-center h-full'
                >
                Verzekeringsbewijs - {greenCard.pullingVehicleLicensePlate}
                </a>
              </button>
            ))}
          </div>
        )}
    </div>
  )
}

export default GreenCard
