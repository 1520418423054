import React from 'react'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext
} from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../../components/Button'
import usePolicies from '@verzekeringapp-mono/shared/src/hooks/use_policies'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { format } from 'date-fns'
import { getPolicyById } from '@verzekeringapp-mono/shared/src/state/selectors/policies'
import {
  RadioQuestion,
  TextQuestion,
  questions
} from '@verzekeringapp-mono/shared/src/api/policies'
import TextInput from '../../../components/TextInput'

export const RadioInput = ({
  name,
  options,
  hint
}: {
  name: string
  options: RadioQuestion['options']
  hint?: string
}) => {
  const { register, watch } = useFormContext()

  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
        {options.map(option => (
          <label
            key={option.value}
            className={`flex items-center space-x-2 p-4 border rounded-lg cursor-pointer ${
              watch(name) === option.value
                ? 'bg-brand text-white border-brand'
                : 'bg-gray-100 text-gray border-lightGray'
            }`}
          >
            <input
              type='radio'
              className='form-radio hidden'
              {...register(name, { required: true })}
              value={option.value}
            />

            <div
              className={`flex items-center aspect-square justify-center w-6 h-6 border rounded-full bg-white ${
                watch(name) === option.value
                  ? 'border-brand'
                  : 'border-lightGray'
              }`}
            >
              {watch(name) === option.value && (
                <FontAwesomeIcon
                  icon='check'
                  className='text-brand'
                />
              )}
            </div>

            <span
              className={`${
                watch(name) === option.value ? 'text-white' : 'text-gray'
              }`}
            >
              {option.label}
            </span>
          </label>
        ))}
      </div>

      {hint && <p className='my-2'>{hint}</p>}
    </div>
  )
}

const QuestionLabel = ({
  label,
  info
}: {
  label: string
  info?: React.ReactNode
}) => {
  const [openQuestionInfo, setOpenQuestionInfo] = React.useState(false)

  return (
    <label
      className={`block bold mb-2 ${info ? 'cursor-pointer' : ''}`}
      onClick={() => {
        if (info) {
          setOpenQuestionInfo(prev => !prev)
        }
      }}
    >
      {label}
      &nbsp;
      {info && (
        <FontAwesomeIcon
          icon={'info-circle'}
          className='text-textGray'
        />
      )}

      {openQuestionInfo && (
        <div className='p-4 bg-[#EDEDE9] text-sm rounded-lg mt-2 text-textGray'>
          <FontAwesomeIcon
            icon='times'
            className='float-right cursor-pointer'
            onClick={() => setOpenQuestionInfo(false)}
          />

          {info}
        </div>
      )}
    </label>
  )
}

const SubQuestions = ({
  subQuestions,
  parentName
}: {
  subQuestions: TextQuestion['subQuestions']
  parentName: string
}) => {
  const { watch } = useFormContext()

  if (!subQuestions || !parentName) return null

  return Object.values(subQuestions).map(subQuestion => {
    const parentValue = watch(parentName)

    if (subQuestion.condition === parentValue) {
      return (
        <div
          key={subQuestion.name}
          className='mb-4 flex flex-col gap-2'
        >
          {subQuestion.type === 'radio'
            ? (
              <>
                <QuestionLabel
                  label={subQuestion.label}
                  info={subQuestion.info}
                />

                <RadioInput
                  name={subQuestion.name}
                  options={subQuestion.options}
                />
              </>
            )
            : (
              <Controller
                name={subQuestion.name}
                control={useFormContext().control}
                render={({ field }) => (
                  <TextInput
                    label={subQuestion.label}
                    type='text'
                    showValidationIcon={false}
                    shouldValidate={false}
                    icon={'euro'}
                    {...field}
                  />
                )}
              />
            )}

          <SubQuestions
            subQuestions={subQuestion.subQuestions}
            parentName={subQuestion.name}
          />
        </div>
      )
    }

    return null
  })
}

const HouseHoldEffectsForm = () => {
  const form = useForm({
    mode: 'onChange'
  })
  const {
    formState: { isValid }
  } = form

  const { postContentsInsuranceValue } = usePolicies()
  const { id } = useParams()
  const valueMeterIsLoading = useAppSelector(
    state => state.policies.isLoadingValueMeter
  )
  const policy = useAppSelector(getPolicyById(id!))

  const underInsuranceGuaranteeDate =
    policy?.details?.find(
      detail => detail.name === 'underinsurance_guarantee_date'
    )?.code || null

  const onSubmit = (data: Record<string, string>) => {
    postContentsInsuranceValue(
      id!,
      Object.keys(data).map(key => ({ name: key, value: data[key] }))
    )
  }

  return (
    <div className='bg-white p-8 rounded-lg w-full'>
      <div className='w-full lg:w-2/3'>
        <p>
          Uw garantie tegen onderverzekering verloopt op{' '}

          {underInsuranceGuaranteeDate &&
            format(new Date(underInsuranceGuaranteeDate), 'dd-MM-yyyy')}
          .

          <strong>
            {' '}
            Verloopt uw garantie tegen onderverzekering over drie maanden of
            eerder?
          </strong>{' '}
          Vul onderstaande inboedelwaardemeter dan naar waarheid in. Op basis
          van de ingevulde gegevens zal uw verzekeringsadviseur de garantie
          tegen onderverzekering verlengen of contact opnemen.
        </p>

        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className='my-4'>
              {Object.keys(questions).map(questionKey => {
                const question = questions[questionKey]

                return (
                  <div
                    key={question.name}
                    className='mb-6 flex flex-col gap-2'
                  >
                    <QuestionLabel
                      label={question.label}
                      info={question.info}
                    />

                    <RadioInput
                      name={question.name}
                      options={question.options}
                      hint={question.hint}
                    />

                    <SubQuestions
                      subQuestions={question.subQuestions}
                      parentName={question.name}
                    />
                  </div>
                )
              })}

              <Button
                type='submit'
                className='primary-btn w-full'
                disabled={!isValid}
                isLoading={valueMeterIsLoading}
              >
                Waardemeter indienen
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

export default HouseHoldEffectsForm
