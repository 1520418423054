import Api from './index'
import {
  IChangeEmailResponse,
  ICheckEmailAddresResponse,
  IForgotPasswordResponse,
  ILoginFailedResponse,
  ILoginSuccessResponse
} from './types/sessions'

export const checkEmailAddress = (email: string) =>
  Api<ICheckEmailAddresResponse>('POST', 'sessions/check-email-address', {
    email
  })

export const login = (email: string, password: string) =>
  Api<ILoginSuccessResponse | ILoginFailedResponse>('POST', 'sessions/login', {
    email,
    password
  })

export const forgotPassword = (email: string, resetPasswordUrl: string) =>
  Api<IForgotPasswordResponse | boolean>('POST', 'sessions/forgot-password', {
    email,
    resetPasswordUrl
  })

export const register = async (
  email: string,
  password: string,
  passwordRepeat: string,
  registerToken: string
) =>
  Api<IForgotPasswordResponse | boolean>('POST', 'sessions/register', {
    email,
    password,
    passwordRepeat,
    token: registerToken
  })

export const resetPassword = async (
  password: string,
  passwordRepeat: string,
  resetPasswordToken: string
) =>
  Api<IForgotPasswordResponse | boolean>('POST', 'sessions/reset-password', {
    password,
    passwordRepeat,
    resetPasswordToken,
    token: resetPasswordToken
  })

export const updatePassword = async (
  email: string,
  currentPassword: string,
  newPassword: string,
  newPasswordRepeat: string
) =>
  Api<IChangeEmailResponse>('POST', 'sessions/update-password', {
    email,
    currentPassword,
    newPassword,
    newPasswordRepeat
  })

export const registerWithToken = async (
  email: string,
  password: string,
  passwordRepeat: string,
  registerToken: string
) =>
  Api('POST', 'sessions/register', {
    email,
    password,
    passwordRepeat,
    registerToken
  })

export const getTerms = async () =>
  Api<{
    id: string
    type_of_setting: string
    title: string
    version: string
    terms_of_use: string
  }>('GET', 'terms/app')

export const postOnboarding = async ({
  firstName,
  termsRead,
  relationId,
  relationNumber
}: {
  firstName: string
  termsRead: boolean
  relationId: string
  relationNumber: string
}) =>
  Api('POST', 'sessions/onboarding', {
    firstName,
    termsRead,
    relationId,
    relationNumber
  })
