import React from 'react'
/* eslint-disable sonarjs/no-duplicate-string */
import { ReactNode } from 'react'
import { IPolicy } from '../models/policy'
import Api from './index'

export const getPolicies = () => Api<IPolicy[]>('GET', 'policies')

export const generateInsuranceCard = (policyId: string, vehicleId: string) =>
  Api('POST', `policies/${vehicleId}/${policyId}/insurance-card`)

export const getPolicy = (id: string) => Api<IPolicy>('GET', `policies/${id}`)
export interface IPostRebuildValueMeterData {
  policy_id: IPolicy['id']
  renovationCost: string
  renovationCostAbove: 'Ja' | 'Nee'
  rebuilding_value_new: string
}
export type RadioQuestion = {
  label: string
  name: string
  options: {
    label: string
    value: string
  }[]
  info?: ReactNode
  type: 'radio'
  condition: string
  subQuestions?: Record<string, RadioQuestion | TextQuestion>
}

export type TextQuestion = {
  label: string
  name: string
  type: 'text'
  condition: string
  info?: ReactNode
  subQuestions?: Record<string, RadioQuestion | TextQuestion>
}

export type Question = {
  label: string
  name: string
  options: { label: string; value: string }[]
  info?: ReactNode
  subQuestions?: Record<string, RadioQuestion | TextQuestion>
  hint?: string
}

export type QuestionKeys = keyof typeof questions

export const questions: Record<string, Question> = {
  age_main_breadwinner: {
    label: 'Wat is de leeftijd van de hoofdkostwinner?',
    name: 'age_main_breadwinner',
    options: [
      { label: '35 jaar en jonger', value: '0' },
      { label: '36 t/m 50 jaar', value: '36' },
      { label: '51 t/m 70 jaar', value: '51' },
      { label: '71 jaar en ouder', value: '71' }
    ]
  },
  family_situation: {
    label: 'Wat is uw gezinssamenstelling?',
    info: (
      <ul>
        <li>alleenstaande (met/zonder inwonende kinderen)</li>

        <li>gezin (met/zonder inwonende kinderen)</li>
      </ul>
    ),
    name: 'family_situation',
    options: [
      {
        label: 'Alleenstaand',
        value: 'single'
      },
      {
        label: 'Gezin',
        value: 'family'
      }
    ]
  },
  net_monthly_income_main_breadwinner: {
    label: 'Wat is het netto maandinkomen van de hoofdkostwinner?',
    info: (
      <p>
        Het netto maandinkomen van de hoofdkostwinner in uw huishouding (loon,
        salaris, inkomen uit eigen bedrijf, pensioen, ontvangen alimentatie, AOW
        of andere sociale uitkeringen enz.) Rente en inkomsten uit beleggingen
        worden niet meegerekend.
      </p>
    ),
    name: 'net_monthly_income_main_breadwinner',
    options: [
      {
        label: '€ 1.000,- of minder',
        value: '0'
      },
      {
        label: 't/m € 2.000,-',
        value: '1001'
      },
      {
        label: 't/m € 3.000,-',
        value: '2001'
      },
      {
        label: 't/m € 4.850,-',
        value: '3001'
      },
      {
        label: 't/m € 5.850,-',
        value: '4851'
      },
      {
        label: 't/m € 7.000,-',
        value: '5851'
      }
    ]
  },
  house_surface_300: {
    label:
      'Is de oppervlakte van uw woning, inclusief bijgebouw(en), meer dan 300m²?',
    name: 'house_surface_300',
    options: [
      {
        label: 'Ja',
        value: 'true'
      },
      {
        label: 'Nee',
        value: 'false'
      }
    ],
    subQuestions: {
      house_surface: {
        label: 'Wat is de oppervlakte van de woning inclusief bijgebouw(en)?',
        name: 'house_surface',
        options: [
          {
            label: '300m² t/m 350m²',
            value: '300'
          },
          {
            label: '351m² t/m 400m²',
            value: '351'
          },
          {
            label: '401m² t/m 450m²',
            value: '401'
          }
        ],
        condition: 'true',
        type: 'radio'
      },
      rooms: {
        label: 'Aantal kamers in de woning en bijgebouw(en)?',
        info: (
          <p>
            Als kamers worden beschouwd: woon-, eet-, slaap-, studeer-, en
            hobbykamers, serre, garage en schuur. Een zolder die uitsluitend
            bestemd wordt als bergruimte is geen kamer.
          </p>
        ),
        name: 'number_rooms',
        condition: 'false',
        type: 'radio',
        options: [
          {
            label: '1-2',
            value: '1'
          },
          {
            label: '3-5',
            value: '3'
          },
          {
            label: '6-8',
            value: '6'
          },
          {
            label: '9 of meer',
            value: '9'
          }
        ]
      }
    }
  },
  ownership_situation: {
    label: 'Wat voor soort woning heeft u?',
    name: 'ownership_situation',
    options: [
      {
        label: 'Huurwoning',
        value: 'rent'
      },
      {
        label: 'Eigen woning',
        value: 'owned'
      },
      {
        label: 'Appartement',
        value: 'appt'
      }
    ],
    subQuestions: {
      extension_tenants_interest: {
        label: 'Is het huurdersbelang meer dan € 6.000,-',
        info: (
          <p>
            Onder huurdersbelang worden alle veranderingen en verbeteringen aan
            de huurwoning verstaan die door u als bewoner voor eigen rekening
            zijn aangebracht. Hieronder vallen o.a. verbeteringen aan de C.V.,
            keuken en sanitaire installaties, betimmeringen, parket- en
            tegelvloeren, schuurtjes, schuttingen enz.
          </p>
        ),
        name: 'extension_tenants_interest',
        condition: 'rent',
        type: 'radio',
        options: [
          {
            label: 'Ja',
            value: 'true'
          },
          {
            label: 'Nee',
            value: 'false'
          }
        ],
        subQuestions: {
          extension_tenants_interest_amount: {
            label: 'Wat is de waarde van het huurdersbelang?',
            name: 'extension_tenants_interest_amount',
            condition: 'true',
            type: 'text'
          }
        }
      },
      extension_owners_interest: {
        label: 'Is het eigenaarsbelang meer dan € 6.000,-?',
        info: (
          <p>
            Onder eigenaarsbelang worden alle aard- en nagelvaste met de woning
            verbonden verbeteringen en/of voorzieningen verstaan die u als
            appartementseigenaar voor eigen rekening heeft aangebracht en die
            niet gedekt zijn op een verzekering ten name van de Vereniging van
            Eigenaren. Hieronder vallen o.a. verbeteringen aan de C.V., keuken
            en sanitaire installaties, betimmeringen, parket- en tegelvloeren
            e.d.
          </p>
        ),
        name: 'extension_owners_interest',
        condition: 'extension_owners_interest',
        type: 'radio',
        options: [
          {
            label: 'Ja',
            value: 'true'
          },
          {
            label: 'Nee',
            value: 'false'
          }
        ],
        subQuestions: {
          extension_owners_interest_amount: {
            label: 'Wat is de waarde van het eigenaarsbelang?',
            name: 'extension_owners_interest_amount',
            condition: 'true',
            type: 'text'
          }
        }
      }
    }
  },
  extension_audiovisual_and_computer_equipment: {
    label:
      'Heeft u meer dan € 12.000,- aan audiovisuele- en computerapparatuur?',

    info: (
      <p>
        Onder audiovisuele en computerapparatuur vallen:
        <ul>
          <li>alle beeld-, geluids-, ontvangst- en zendapparatuur;</li>

          <li>alle soorten computerapparatuur (inclusief spelcomputers);</li>

          <li>alle bij bovengenoemde apparatuur behorende randapparatuur;</li>

          <li>alle standaardsoftware;</li>

          <li>
            alle geluids-, beeld- en informatiedragers (bijv. CD’s, DVD’s,
            USB-sticks)
          </li>
        </ul>
      </p>
    ),
    name: 'extension_audiovisual_and_computer_equipment',
    options: [
      {
        label: 'Ja',
        value: 'true'
      },
      {
        label: 'Nee',
        value: 'false'
      }
    ],
    subQuestions: {
      extension_audiovisual_and_computer_equipment_amount: {
        label: 'Welk bedrag bovenop € 12.000,-?',
        name: 'extension_audiovisual_and_computer_equipment_amount',
        condition: 'Ja',
        type: 'radio',
        options: [
          {
            label: '€ 2.000,-',
            value: '2000'
          },
          {
            label: '€ 4.000,-',
            value: '4000'
          },
          {
            label: '€ 6.000,-',
            value: '6000'
          },
          {
            label: '€ 8.000,-',
            value: '8000'
          },
          {
            label: '€ 10.000,-',
            value: '10000'
          },
          {
            label: '€ 12.000,-',
            value: '12000'
          },
          {
            label: '€ 14.000,-',
            value: '14000'
          },
          {
            label: '€ 16.000,-',
            value: '16000'
          },
          {
            label: '€ 18.000,-',
            value: '18000'
          },
          {
            label: '€ 20.000,-',
            value: '20000'
          },
          {
            label: '€ 25.000,-',
            value: '25000'
          },
          {
            label: '€ 30.000,-',
            value: '30000'
          },
          {
            label: '€ 40.000,-',
            value: '40000'
          }
        ]
      }
    }
  },
  jewelry: {
    label: 'Heeft u meer dan € 6.000,- aan lijfsieraden?',
    name: 'extension_jewelry',
    info: (
      <p>
        Lijfsieraden zijn sieraden, inclusief horloges, vervaardigd om op of aan
        het lichaam te dragen en die geheel of ten dele bestaan uit edel- of
        ander metaal, gesteente, mineraal, (bloed)koraal of andere dergelijke
        stoffen, alsmede parels
      </p>
    ),
    hint: 'Let op: het diefstalrisico (dus niet de overige risico’s) van lijfsieraden is op Inboedel Optimaal verzekeringen standaard beperkt tot € 6.000,-. Als u het diefstalrisico van lijfsieraden voor een hoger verzekerd bedrag wilt verzekeren, neemt u dan contact met uw verzekeringsadviseur op.',
    options: [
      {
        label: 'Ja',
        value: 'true'
      },
      {
        label: 'Nee',
        value: 'false'
      }
    ],
    subQuestions: {
      extension_jewelry_amount: {
        label: 'Welk bedrag bovenop € 6.000,-?',
        name: 'extension_jewelry_amount',
        condition: 'true',
        type: 'radio',

        options: [
          {
            label: '€ 2.000,-',
            value: '2000'
          },
          {
            label: '€ 4.000,-',
            value: '4000'
          },
          {
            label: '€ 6.000,-',
            value: '6000'
          },
          {
            label: '€ 8.000,-',
            value: '8000'
          },
          {
            label: '€ 10.000,-',
            value: '10000'
          },
          {
            label: '€ 12.000,-',
            value: '12000'
          },
          {
            label: '€ 14.000,-',
            value: '14000'
          },
          {
            label: '€ 16.000,-',
            value: '16000'
          },
          {
            label: '€ 18.000,-',
            value: '18000'
          },
          {
            label: '€ 20.000,-',
            value: '20000'
          },
          {
            label: '€ 25.000,-',
            value: '25000'
          },
          {
            label: '€ 30.000,-',
            value: '30000'
          },
          {
            label: '€ 40.000,-',
            value: '40000'
          }
        ]
      }
    }
  },
  extension_special_possessions: {
    label: 'Heeft u meer dan € 15.000,- aan andere bijzondere bezittingen?',
    name: 'extension_special_possessions',
    info: (
      <p>
        Onder bijzondere bezittingen worden waardevolle eigendommen verstaan,
        zoals o.a. verzamelingen, antiek, kunst, muziekinstrumenten, enz.
      </p>
    ),

    options: [
      {
        label: 'Ja',
        value: 'true'
      },
      {
        label: 'Nee',
        value: 'false'
      }
    ],
    subQuestions: {
      extension_special_possessions_amount: {
        label: 'Welk bedrag bovenop € 15.000,-?',
        name: 'extension_special_possessions_amount',
        condition: 'true',
        type: 'radio',

        options: [
          {
            label: '€ 2.000,-',
            value: '2000'
          },
          {
            label: '€ 4.000,-',
            value: '4000'
          },
          {
            label: '€ 6.000,-',
            value: '6000'
          },
          {
            label: '€ 8.000,-',
            value: '8000'
          },
          {
            label: '€ 10.000,-',
            value: '10000'
          },
          {
            label: '€ 12.000,-',
            value: '12000'
          },
          {
            label: '€ 14.000,-',
            value: '14000'
          },
          {
            label: '€ 16.000,-',
            value: '16000'
          },
          {
            label: '€ 18.000,-',
            value: '18000'
          },
          {
            label: '€ 20.000,-',
            value: '20000'
          },
          {
            label: '€ 25.000,-',
            value: '25000'
          },
          {
            label: '€ 30.000,-',
            value: '30000'
          },
          {
            label: '€ 40.000,-',
            value: '40000'
          }
        ]
      }
    }
  }
}

export interface IPostContentsInsuranceValueData {
  name: QuestionKeys
  value: string
}

export const requestPolicyAdvise = (
  relationId: string,
  agencyId: string,
  adviseExplanation: string,
  products: string[]
) =>
  Api('POST', `relations/advise-request/${relationId}`, {
    adviseExplanation,
    productNames: products?.filter(item => item !== ''),
    agencyId
  })

export const postRebuildValueMeter = async (
  policyId: string,
  data: IPostRebuildValueMeterData
) => Api('POST', `policies/${policyId}/rebuilding-value`, data)

export const postContentsInsuranceValue = async (
  policyId: string,
  data: IPostContentsInsuranceValueData[]
) =>
  Api('POST', `policies/${policyId}/contents-insurance-value`, {
    data
  })
