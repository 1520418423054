import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IAgency } from '../../models/agency'
import { logout, selectRelation } from '../actions/constants'

export interface IAgencySlice {
  agency?: IAgency
}

const initialState: IAgencySlice = {
  agency: undefined
}

export const agencySlice = createSlice({
  name: 'agency',
  initialState,
  reducers: {
    setAgency: (state, action: PayloadAction<IAgency>) => {
      state.agency = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(selectRelation, () => initialState)
  }
})

export const {
  setAgency
} = agencySlice.actions

export default agencySlice.reducer
