import React, { useCallback } from 'react'
import { IMemberBenefit } from '@verzekeringapp-mono/shared/src/models/member_benefit'
import useMemberBenefits from '@verzekeringapp-mono/shared/src/hooks/use_member_benefits'
import copy from 'copy-to-clipboard'
import Modal from '@verzekeringapp-mono/shared/src/components/Modal'
import SuccessSvg from '../../../../components/illustrations/Succes'

interface IProps {
  memberBenefit: IMemberBenefit
}

const MemberBenefitVoucher: React.FC<IProps> = ({ memberBenefit }) => {
  const { downloadVoucher } = useMemberBenefits()

  const [openIsCopiedModal, setOpenIsCopiedModal] = React.useState(false)

  const download = () => {
    downloadVoucher(`${memberBenefit.id}`)
  }

  const copyVoucherCode = () => {
    if (!memberBenefit?.voucherCode) return
    copy(memberBenefit?.voucherCode ?? '')

    setOpenIsCopiedModal(true)
    setTimeout(() => {
      setOpenIsCopiedModal(false)
    }, 1000)
  }

  const openVoucherPdf = () => {
    if (memberBenefit?.voucherPdf) {
      window.open(memberBenefit.voucherPdf, '_blank', 'noreferrer')
    }
  }

  const handleOnPress = useCallback(() => {
    if (memberBenefit?.isVoucherCode) {
      copyVoucherCode()
    } else if (memberBenefit?.isPdfVoucher) {
      openVoucherPdf()
    }
  }, [memberBenefit])

  return (
    <div>
      {memberBenefit?.participant &&
        (memberBenefit?.isPdfVoucher || memberBenefit?.isVoucherCode) && (
        <button
          className={'rounded-md bg-brand text-white w-full mb-6'}
          onClick={handleOnPress}
        >
          <div>
            {memberBenefit.isVoucherCode
              ? (
                <div>
                  <p className='text-white'>Uw kortingscode:</p>

                  <span className='text-white font-medium text-xl'>
                    {memberBenefit.voucherCode}
                  </span>

                  <p className='text-white'>
                    Klik op de knop om de code te kopiëren
                  </p>
                </div>
              )
              : null}

            {memberBenefit.isPdfVoucher
              ? (
                <p className='py-4'>Voucher openen</p>
              )
              : null}
          </div>
        </button>
      )}

      {(memberBenefit?.isPdfVoucher || memberBenefit?.isVoucherCode) &&
        memberBenefit?.participant === false && (
        <button
          className={'primary-btn w-full mb-6'}
          onClick={download}
        >
          {memberBenefit?.isPdfVoucher ? 'Voucher ophalen' : 'Code genereren'}
        </button>
      )}

      {openIsCopiedModal && (
        <Modal onClose={() => setOpenIsCopiedModal(false)}>
          <div className='flex flex-col items-center justify-center'>
            <SuccessSvg />

            <p className='text-center text-2xl'>Gekopieerd naar klembord</p>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default MemberBenefitVoucher
