export const checkPasswords = (newPassword = '', newPasswordRepeat = '') => {
  const validDigit = /\d/g.test(newPassword)
  const validLength = newPassword.length >= 8
  const validLowercase = newPassword.toUpperCase() !== newPassword
  const validSpecialChar = !!newPassword.replace(/[a-zA-Z\d\s]/g, '').length
  const validUppercase = newPassword.toLowerCase() !== newPassword

  const invalidPassword = !validDigit || !validLength || !validLowercase || !validSpecialChar || !validUppercase
  const invalidPasswordRepeat = !newPasswordRepeat || !newPasswordRepeat.length || newPasswordRepeat !== newPassword

  return {
    invalid: invalidPassword || invalidPasswordRepeat,
    invalidPassword,
    invalidPasswordRepeat,
    validDigit,
    validLength,
    validLowercase,
    validSpecialChar,
    validUppercase
  }
}
