import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { InputHTMLAttributes } from 'react'

const CheckboxInput = ({
  label,
  className,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  label: string
}) => (
  <label className={`flex items-center ${className}`}>
    <div className='relative flex mr-4'>
      <input
        type='checkbox'
        className='w-[30px] h-[30px] rounded-md p-2 border-lightGray border-2 text-textGray appearance-none cursor-pointer peer'
        {...props}
      />

      <FontAwesomeIcon
        icon='check'
        size='lg'
        className=' peer-checked:text-success absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 peer-checked:opacity-100  text-success'
      />
    </div>

    {label}
  </label>
)

export default CheckboxInput
