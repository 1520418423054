import React, { useCallback, useEffect } from 'react'
import { Images } from '@verzekeringapp-mono/shared/src/assets'
import LoginScreenWrapper from '../../components/screen_wrappers/login_screen_wrapper'
import useSession from '@verzekeringapp-mono/shared/src/hooks/use_session'
import { checkPasswords } from '@verzekeringapp-mono/shared/src/utils/password_utils'
import { useNavigate } from 'react-router-dom'

interface FormData {
  password: string
  passwordRepeat: string
}

const ResetPasswordScreen: React.FC = () => {
  const [formData, setFormData] = React.useState<FormData>({
    password: '',
    passwordRepeat: ''
  })
  const invalidLabelCSSClass = formData.password !== '' ? 'labelError' : 'label'
  const result = checkPasswords(formData.password, formData.passwordRepeat)
  const resetPasswordToken =
    new URLSearchParams(location.search).get('resetPasswordToken') ?? ''

  const { resetPassword } = useSession()
  const navigate = useNavigate()

  useEffect(() => {
    if (resetPasswordToken === '') {
      navigate('/home')
    }
  }, [resetPasswordToken])

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target

      setFormData(formData => ({ ...formData, [name]: value }))
    },
    [formData]
  )

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const success = await resetPassword(
        formData.password,
        formData.passwordRepeat,
        resetPasswordToken
      )

      window.alert(
        success
          ? 'Uw wachtwoord is aangepast'
          : 'De link om het wachtwoord te resetten is niet meer geldig. Probeer opnieuw je wachtwoord te resetten.'
      )
      navigate('/home')
    },
    [formData, resetPassword, resetPasswordToken]
  )

  return (
    <LoginScreenWrapper>
      <img
        src={Images.Logo}
        width={180}
        height={70}
      />

      <p className='title  mt-8'>Wachtwoord herstellen</p>

      <p className='description mt-4'>
        Hieronder kunt u uw nieuwe wachtwoord invullen:
      </p>

      <p className='mt-4 mb-2'>
        Bedenk een veilig wachtwoord dat voldoet aan de volgende eisen:
      </p>

      <p className={result.validLength ? 'labelSuccess' : invalidLabelCSSClass}>
        • Minimaal 8 karakters
      </p>

      <p
        className={
          result.validUppercase ? 'labelSuccess' : invalidLabelCSSClass
        }
      >
        • Minimaal 1 hoofdletter
      </p>

      <p
        className={
          result.validLowercase ? 'labelSuccess' : invalidLabelCSSClass
        }
      >
        • Minimaal 1 kleine letter
      </p>

      <p className={result.validDigit ? 'labelSuccess' : invalidLabelCSSClass}>
        • Minimaal 1 cijfer
      </p>

      <p
        className={
          result.validSpecialChar ? 'labelSuccess' : invalidLabelCSSClass
        }
      >
        • Minimaal 1 speciaal teken
      </p>

      <p
        className={
          formData.password === formData.passwordRepeat &&
          formData.password !== ''
            ? 'labelSuccess'
            : invalidLabelCSSClass
        }
      >
        • Wachtwoorden zijn hetzelfde
      </p>

      <form
        onSubmit={handleSubmit}
        className='w-full mt-8'
      >
        <label className='label'>Wachtwoord</label>

        <input
          className='textfield mt-2 mb-4'
          type='password'
          placeholder='Nieuw wachtwoord'
          name='password'
          value={formData.password}
          onChange={handleInputChange}
        />

        <label className='label'>Herhaal wachtwoord</label>

        <input
          className='textfield mt-2'
          type='password'
          placeholder='Herhaal nieuw wachtwoord'
          name='passwordRepeat'
          value={formData.passwordRepeat}
          onChange={handleInputChange}
        />

        <button
          className={`primary-btn w-full ${
            !result.invalid ? '' : 'opacity-50'
          } mt-8`}
        >
          Aanpassen
        </button>
      </form>
    </LoginScreenWrapper>
  )
}

export default ResetPasswordScreen
