import { initializeApp } from 'firebase/app'
import * as Analytics from 'firebase/analytics'

let isInitialized = false

export const initialize = () => {
  const hasApiKey = process.env.REACT_APP_FIREBASE_API_KEY

  if (!hasApiKey) {
    return
  }

  isInitialized = true

  return initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    projectId: 'verzekeringapp'
  })
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logEvent = (name: string, params?: Record<string, any>) => {
  if (!isInitialized) {
    return
  }

  return Analytics.logEvent(Analytics.getAnalytics(), name, params)
}

export const setCurrentScreen = (screenName: string) => {
  if (!isInitialized) {
    return
  }

  return Analytics.setCurrentScreen(Analytics.getAnalytics(), screenName)
}

export const setUserId = (userId: string) => {
  if (!isInitialized) {
    return
  }

  return Analytics.setUserId(Analytics.getAnalytics(), userId)
}
