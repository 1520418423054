import React, { useState } from 'react'

interface IProps {
  tabs: {
    name: string
    value: string
    onClick?: () => void
    component: React.ReactNode
  }[]
  initialTab?: string
}

const TabNavigation = ({ tabs, initialTab }: IProps) => {
  const [activeTab, setActiveTab] = useState(
    tabs.find((tab) => tab.value === initialTab) || tabs[0]
  )

  return (
    <>
      <div className='w-full flex  border-lightGray border-b-2'>
        {tabs.map((tab) => (
          <button
            key={tab.value}
            className={`flex flex-1 items-center justify-center px-4 py-2 text-gray-500 ${
              activeTab.value === tab.value
                ? 'text-brand border-b-4 border-brand'
                : 'hover:text-brand'
            }`}
            onClick={() => {
              setActiveTab(tab)
              tab.onClick?.()
            }}
          >
            {tab.name}
          </button>
        ))}
      </div>

      {activeTab.component}
    </>
  )
}

export default TabNavigation
