import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'

const _getState = (state: RootState) => state.idin

export const getIdin = createSelector(_getState, (state) => state)

export const getIdinVerifyStatus = createSelector(
  _getState,
  (state) => state.idinResultStatus
)
