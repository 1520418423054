import React from 'react'
import ScreenWrapper from '../../../components/screen_wrappers/screen_wrapper'
import MemberForm from './components/member_form'
import useRelation from '@verzekeringapp-mono/shared/src/hooks/use_relation'
import { useAppSelector } from '@verzekeringapp-mono/shared/src/hooks/state'
import { getCurrentRelation } from '@verzekeringapp-mono/shared/src/state/selectors/relation'
import { useNavigate } from 'react-router-dom'

const InviteMemberScreen = () => {
  const { addRelationUser } = useRelation()
  const currentRelation = useAppSelector(getCurrentRelation)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  return (
    <ScreenWrapper>
      <MemberForm
        formType='create'
        error={error}
        isLoading={isLoading}
        onSubmit={async ({
          email,
          firstName,
          lastName,
          dateOfBirth,
          initials
        }) => {
          setIsLoading(true)
          await addRelationUser(currentRelation!.id, {
            email,
            firstName,
            lastName,
            dateOfBirth,
            initials
          })
            .then(result => {
              if (!result.success) {
                if (result.message) {
                  setError(result.message)
                }
              } else {
                navigate('/mijn-profiel/gebruikers-beheren')
              }
            })
            .catch(e => {
              setError(e.message)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }}
      />
    </ScreenWrapper>
  )
}

export default InviteMemberScreen
