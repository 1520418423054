import * as React from 'react'
import {SVGProps} from 'react'

const SuccessSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={228}
    height={182}
    {...props}
  >
    <title>{'success'}</title>

    <defs>
      <filter
        id='a'
        width='100%'
        height='100%'
        x='0%'
        y='0%'
        filterUnits='objectBoundingBox'
      >
        <feGaussianBlur in='SourceGraphic' />
      </filter>
    </defs>

    <g
      fill='none'
      fillRule='evenodd'
    >
      <ellipse
        cx={114}
        cy={161}
        fill='#FAFAFA'
        filter='url(#a)'
        rx={114}
        ry={21}
      />

      <circle
        cx={167}
        cy={79}
        r={21}
        fill='#87C104'
        opacity={0.071}
      />

      <circle
        cx={123}
        cy={40}
        r={16}
        fill='#87C104'
        opacity={0.171}
      />

      <circle
        cx={64}
        cy={35}
        r={6}
        fill='#87C104'
        opacity={0.371}
      />

      <circle
        cx={55}
        cy={77}
        r={12}
        fill='#87C104'
        opacity={0.131}
      />

      <circle
        cx={92}
        cy={8}
        r={7}
        fill='#87C104'
        opacity={0.071}
      />

      <circle
        cx={175}
        cy={35}
        r={5}
        fill='#87C104'
        opacity={0.171}
      />

      <circle
        cx={148}
        cy={3}
        r={3}
        fill='#87C104'
        opacity={0.331}
      />

      <circle
        cx={114}
        cy={101}
        r={54.5}
        fill='#87C104'
        stroke='#FFF'
        strokeWidth={3}
      />

      <path
        fill='#FFF'
        fillRule='nonzero'
        d='M141.39 78.54a1.734 1.734 0 0 1 2.452 0l1.635 1.635a1.735 1.735 0 0 1 0 2.453l-43.332 43.332a1.734 1.734 0 0 1-2.453 0l-17.17-17.17a1.735 1.735 0 0 1 0-2.452l1.636-1.635a1.735 1.735 0 0 1 2.453 0l14.307 14.308L141.39 78.54Z'
      />
    </g>
  </svg>
)

export default SuccessSvg
