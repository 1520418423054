import React from 'react'
import { ICompany } from '@verzekeringapp-mono/shared/src/api/types/company'
import { MarkerF } from '@react-google-maps/api'

interface IProps {
  location: ICompany
  openModal: (location: ICompany) => void
}

const LocationMarker = ({ location, openModal }: IProps) => (
  <MarkerF
    onClick={() => {
      openModal(location)
    }}
    position={{
      lat: parseFloat(location.latitude),
      lng: parseFloat(location.longitude)
    }}
  />
)

export default React.memo(LocationMarker)
