import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../configure_store'
import { getPoliciesUnexpelled } from './policies'
import showValueMeter from '../../utils/show_value_meter'
import { IPolicy } from '../../models/policy'

const _getState = (state: RootState) => state.policies

const isWithinThreeMonthsOfExpiry = (
  underinsuranceGuaranteeDate: string
): boolean => {
  const date = new Date(underinsuranceGuaranteeDate)
  const threeMonthsFromNow = new Date()

  threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3)

  return date < threeMonthsFromNow
}
const policyHasActiveValueMeter = (policy: IPolicy) => {
  const underInsuranceGuarantee = policy.details?.find(
    (detail) => detail.name === 'underinsurance_guarantee'
  )
  const blockMutationReason = policy.details?.find(
    (detail) => detail.name === 'block_mutation_reason'
  )

  return (
    process.env.NODE_ENV === 'development' ||
    (underInsuranceGuarantee?.value !== undefined &&
      underInsuranceGuarantee?.value !== false &&
      !policy.pendingMutations &&
      blockMutationReason?.value !== true &&
      isWithinThreeMonthsOfExpiry(underInsuranceGuarantee?.code))
  )
}

export const getValueMeterPolicies = createSelector(
  getPoliciesUnexpelled,
  (policies = []) =>
    policies
      .filter((policy) => showValueMeter(policy))
      .map((item) => ({
        ...item,
        valueMeterEnabled: policyHasActiveValueMeter(item)
      }))
      .filter((policy) => policy?.valueMeterEnabled)
)
