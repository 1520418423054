import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../configure_store'
import { parse } from 'date-fns'
import { IPayment } from '../../models/payment'

const _getState = (state: RootState) => state.payments

export const getPayments = createSelector(_getState, (state) => state.payments)

export const getPaymentsByYear = createSelector(_getState, (state) => {
  const payments = [...state.payments]
  const years = new Set(
    payments.map((payment) =>
      parse(payment.bookDate, 'dd-MM-yyyy', new Date()).getFullYear()
    )
  )

  return Array.from(years).reduce(
    (acc: { [year: string]: IPayment[] }, year) => {
      acc[year] = payments.filter(
        (payment) =>
          parse(payment.bookDate, 'dd-MM-yyyy', new Date()).getFullYear() ===
          year
      )

      return acc
    },
    {}
  )
})
