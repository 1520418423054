const transparentAddition = '08'
const success = '#87C104'

export const Colors = {
  brand: '#E95E40',
  lightBrand: 'rgba(220, 81, 52, 0.03);',
  lightGray: '#D9DFE8',
  lightestGray: '#FBFBFB',
  background: '#F0F3F6',
  brown: '#84726F',
  gray: '#48423E',
  textGray: '#8d8d8d',
  lightBrown: '#B3A49A',
  lightSuccess: success + transparentAddition,
  lighterBrown: '#EDEBE9',
  red: '#CD0404',
  orange: '#DE8710',
  success,
  white: '#FFFFFF',
  waiting: '#FF9C00',
  whatsapp: '#00A100',
  darkRed: '#AF0000',
  borderColor: '#F0F3F6',
  lightGreen: '#EDF9E5',
  lightWaiting: '#FFFAE9',
  lightError: '#FFEEEE',
  green: '#408716'
}
