import { useAppDispatch } from './state'
import { getPayments as getPaymentsAPI } from '../api/payments'
import { setPayments } from '../state/slices/payments_slice'
import * as Sentry from '@sentry/react'

export const usePayments = () => {
  const dispatch = useAppDispatch()

  const getPayments = async (): Promise<boolean> => {
    const result = await getPaymentsAPI()

    if (!result.success) {
      Sentry.captureException(result.error)
    }
    if (result.data) {
      dispatch(setPayments(result.data))
    }

    return result.success
  }

  return {
    getPayments
  }
}

export default usePayments
