import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from '@verzekeringapp-mono/shared/src/utils/colors'

interface IProps {
  circleColor: string
  description?: string
  descriptionColor?: string
  icon: IconProp
  href?: string
  title: string
  onClick?: () => void
}

const ContactInfoButton: React.FC<IProps> = props => {
  if (!props.description) return null
  if (props.href) {
    return (
      <a
        href={props.href}
        className={
          'flex items-center cursor-pointer mb-4 flex-col justify-center gap-2 md:flex-row'
        }
      >
        <div
          className='h-[50px] md:h-[34px] aspect-square w-[50px] md:w-[34px] rounded-full flex items-center justify-center md:mr-2'
          style={{ backgroundColor: props.circleColor }}
        >
          <FontAwesomeIcon
            icon={props.icon}
            size='sm'
            color={Colors.white}
          />
        </div>

        <div className='flex flex-col justify-center items-center flex-1 md:items-start'>
          <p className='text-sm text-center'>{props.title}</p>

          <p
            style={{ color: props.descriptionColor }}
            className='description hidden md:block'
          >
            {props.description}
          </p>
        </div>
      </a>
    )
  }

  if (props.onClick) {
    return (
      <div
        onClick={props.onClick}
        className={
          'flex items-center cursor-pointer mb-4 flex-col justify-center gap-2 md:flex-row'
        }
      >
        <div
          className='h-[50px] md:h-[34px] aspect-square w-[50px] md:w-[34px] rounded-full flex items-center justify-center md:mr-2'
          style={{ backgroundColor: props.circleColor }}
        >
          <FontAwesomeIcon
            icon={props.icon}
            size='sm'
            color={Colors.white}
          />
        </div>

        <div className='flex flex-col justify-center items-center flex-1 md:items-start'>
          <p className='text-sm text-center'>{props.title}</p>

          <p
            style={{ color: props.descriptionColor }}
            className='description hidden md:block'
          >
            {props.description}
          </p>
        </div>
      </div>
    )
  }
}

export default ContactInfoButton
