import React, { PropsWithChildren } from 'react'
import ScreenHeader from '../screen_header'
import NavBar from '../nav_bar/nav_bar'
import Breadcrumbs from '../Breadcrumbs'

interface IProps {
  hasPadding?: boolean
  breadCrumbsOverride?: {
    label: string
    path: string
  }[]
}

const ScreenWrapper: React.FC<PropsWithChildren<IProps>> = ({
  children,
  hasPadding = true,
  breadCrumbsOverride
}) => (
  <div className='bg-background h-screen overflow-hidden'>
    <ScreenHeader />

    <div
      className='flex w-full'
      style={{ height: 'calc(100% - 86px)' }}
    >
      <div className='hidden md:block'>
        <NavBar />
      </div>

      <div
        className={`md:p-8 overflow-y-auto w-full ${hasPadding ? 'p-4' : ''}`}
      >
        <Breadcrumbs breadCrumbsOverride={breadCrumbsOverride} />

        {children}
      </div>
    </div>
  </div>
)

export default ScreenWrapper
