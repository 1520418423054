import React from 'react'
import { useState } from 'react'

type Props = {
  tabs: Array<{ title: string; content: React.ReactNode; slug: string }>
}

const Tabs = ({ tabs }: Props) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].slug)

  return (
    <div>
      <div className='flex flex-row md:w-1/2 bg-lightestGray '>
        {tabs.map(tab => (
          <button
            key={tab.slug}
            className={`flex flex-1 p-4 justify-center
              ${selectedTab === tab.slug ? 'border-b-2' : ''} border-brand
              ${
          selectedTab !== tab.slug
            ? 'text-gray border-lightGray border-b-2'
            : 'text-brand'
          }
              `}
            onClick={() => setSelectedTab(tab.slug)}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {tabs.map(tab => (
        <div
          key={tab.slug}
          className={selectedTab === tab.slug ? '' : 'hidden'}
        >
          {tab.content}
        </div>
      ))}
    </div>
  )
}

export default Tabs
