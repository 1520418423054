import {
  identifyIdin as identifyIdinUser,
  verifyIdin as verifyIdinUser
} from '../api/idin'
import { setIdinResultStatus } from '../state/slices/idin_slice'
import { logEvent } from '../utils/firebase'
import { useAppDispatch } from './state'
import * as Sentry from '@sentry/react'

export const useIdin = () => {
  const dispatch = useAppDispatch()

  const identifyIdin = async (redirectUrl: string, email: string) => {
    const { success, error, data } = await identifyIdinUser(redirectUrl, email)

    dispatch(setIdinResultStatus(undefined))

    if (!success) {
      console.warn(error)
    }

    return data
  }

  const verifyIdin = async (
    entranceCode: string,
    transactionId: string,
    registerUrl: string,
    email: string
  ) => {
    const { success, error, data } = await verifyIdinUser(
      entranceCode,
      transactionId,
      registerUrl,
      email
    )

    if (!success) {
      dispatch(setIdinResultStatus('ERROR'))
      Sentry.captureException(error)
      logEvent('idin_error')
    } else {
      dispatch(setIdinResultStatus('SUCCESS'))
      logEvent('idin_success')
    }

    return data
  }

  return {
    identifyIdin,
    verifyIdin
  }
}

export default useIdin
